<app-glc-page-content
  [pageTitle]="'SETTINGS'"
  [pageDescription]="'SETTINGS_PAGE_DESCRIPTION'"
>
  <div class="tabs-header">
    <ul
      class="nav nav-tabs mt-20 clearfix"
      id="tabControl"
      role="tablist"
    >
      <li 
        *ngFor="let table of tableList; let idx = index"
        class="nav-item"
        [ngClass]="{ 'active': currentSelectedTab === table.tabIndex }"
      >
        <a
          (click)="onTabChanged(table.tabIndex)"
          class="nav-link"
          data-toggle="tab"
          href="#{{ table.tabId }}"
          role="tab"
          attr.aria-controls="{{ table.tabId }}"
          aria-selected="true"
        >
          {{ table.tableName | translate }}
        </a>
      </li>
    </ul>
  </div>
  <div class="tab-content mt-40 clearfix">
    <div
      *ngFor="let table of tableList; let idx = index"
      class="tab-pane fade"
      id="{{ table.tabId }}"
      role="tabpanel"
      attr.aria-labelledby="{{ table.tabId + '-tab' }}"
      [ngClass]="{ 'in active': currentSelectedTab === table.tabIndex }"
    >
      <label class="setting-label">{{ table.tableSettings | translate }}</label>
      <div class="mt-20">
        <div 
          *ngFor="let column of table.columns;"
          class="column-option"
        >
          <app-glc-slide-option
            [(text)]="column.name"
            [(value)]="column.active"
            (modelChangeEvent)="onModelChange($event)"
          >
          </app-glc-slide-option>
        </div>
      </div>
    </div> 
  </div>
</app-glc-page-content>
