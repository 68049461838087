import { Component, OnInit } from '@angular/core';
import { Configuration, TOASTER_TYPE } from 'src/app/constants';
import { Common, Validator, DocumentService } from 'src/app/services';
import { FieldError, Document } from 'src/app/models';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-glc-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit {
  route = Configuration.ROUTE_PATH;
  pageDescription = 'UPDATE_DOCUMENT';
  isAdminOrSuperUser = false;
  deviceID = 0;
  documentID = 0;
  document = new Document();
  documentBK = new Document();
  isChanged: boolean;

  // Declare validation
  errors: any = {
    file_display_name: new FieldError(),
    category: new FieldError(),
  };

  constructor(
    private common: Common,
    private validator: Validator,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
    this.isAdminOrSuperUser = this.common.isAdminOrSuperUser();
    try {
      this.deviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));
      this.documentID = Number(this.activatedRoute.snapshot.paramMap.get('documentID'));
    } catch (e) {
      this.backToDevicePage();
    }
    this.initData();
  }

  initData() {
    try {
      this.documentService.getByID(this.documentID).subscribe((response: any) => {
        this.common.hideLoader();
        this.document = new Document(response);
        this.documentBK = _.cloneDeep(this.document);
      });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  save() {
    if (!this.isAdminOrSuperUser || this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        this.update();
      }
    );
  }

  update() {
    try {
      if (this.document.validate(null, true) === '') {
        const formData = new FormData();
        formData.append('file_display_name', this.document.file_display_name);
        formData.append('category', this.document.category);

        this.documentService.update(this.documentID, formData)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('UPDATE'),
                this.common.translate('UPDATE_SUCCESSFUL')
              );
              this.backToDevicePage();
            });
      }
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  validate(field: string = null, editing = false) {
    this.validator.validate(field, this.document, this.errors, editing);
  }

  public isDisabledBtn() {
    if (!_.isEqual(this.document, this.documentBK)) {
      this.isChanged = true;
    }
    const result = this.document.validate(null, true);
    return !this.isChanged || result !== '';
  }

  backToDevicePage() {
    this.common.navigate(`${this.route.DEVICES}/${this.deviceID}/${this.route.DEVICE_DOCUMENTS_TAB}`);
  }
}
