<label class="tab-label">{{ 'UPLOADED_DOCUMENTS' | translate }}</label>
<div class="mt-10">
  <div class="row">
    <div class="col-md-8">
      <div class="box-body">
        <div class="row" *ngIf="isAdminOrSuperUser">
          <div class="col-lg-5">
            <div class="hide-label">
              <app-glc-file
                (fileSelect)="handleFileInput($event)"
                [isMultiple]="true"
                [maxSize]="10"
                [wrongFormatMess]="'DEVICE_DOCUMENT_INVALID'"
                [oversizeMess]="'DEVICE_MAXIMUM_SIZE_ALLOW'"
                [fileTypes]="fileTypes"
                [(files)]="documents"
              >
              </app-glc-file>
            </div>
          </div>
          <div class="col-lg-5">
            <app-glc-select-searchable
              [isRequired]="true"
              [title]="'SELECT_DOCUMENT_CATEGORY'"
              [textKey]="'name'"
              [placeholder]="'PLEASE_SELECT'"
              [dataService]="privateDocumentCategoryService"
              [(value)]="document.category"
              [(error)]="errors.category"
              (modelChangeEvent)="validate('category')"
              [isServerSide]="false"
              [addTag]="true"
            >
            </app-glc-select-searchable>
          </div>
          <div class="col-lg-2">
            <button
              *ngIf="isAdminOrSuperUser"
              mat-button class="btn btn-primary pull-left btn-upload"
              (click)="save()"
              [disabled]="isDisabledBtn()"
            >
              {{ 'UPLOAD' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="box-body">
        <app-glc-table
          *ngIf="tableData"
          [data]="tableData"
          (page)="pageChange($event)"
          (sort)="sortChange($event)"
          [(showMultipleSelect)]="isShowMultipleSelect"
          [(hasDelete)]="isAdminOrSuperUser"
          (handleDeleteItem)="deleteDocuments($event)"
        >
        </app-glc-table>
      </div>
    </div>
  </div>
</div>
