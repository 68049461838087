import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Configuration, TOASTER_TYPE, CONFIRM_MODAL_OPTIONS } from 'src/app/constants';
import { ExportToCsv } from 'export-to-csv';
import { Common } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CalendarService } from 'src/app/services';
import { Event } from 'src/app/models';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-glc-event-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})

export class EventCalendarComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  pageDescription = 'CALENDAR_DESCRIPTION';
  toasterType = TOASTER_TYPE;
  isAdmin = false;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    title: string;
    action: number;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pen"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.currentAction = Configuration.EVENT_CALENDAR.ACTIONS.EDIT;
        this.handleEvent('Edit Event', Configuration.EVENT_CALENDAR.ACTIONS.EDIT, event);
      }
    },
    {
      label: '<i class="fas fa-fw fa-trash"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  eventsBK: CalendarEvent[] = [];

  activeModal: any;

  currentAction: number;

  appRoute = Configuration.ROUTE_PATH;

  id: number;
  type: string;
  typeID = Configuration.CALENDAR.TYPE.SERVICE;

  constructor(
    private modal: NgbModal,
    private common: Common,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendarService: CalendarService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();

    try {
      this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      this.type = this.activatedRoute.snapshot.paramMap.get('type');
      this.initData();

    } catch (error) {
      this.backToOverview();
    }
  }

  initData() {
    if (this.type === 'control') {
      this.typeID = Configuration.CALENDAR.TYPE.CONTROL;
    }
    this.calendarService.getList({
      type: this.typeID,
      device_id: this.id
    })
      .subscribe(
        (response: any) => {
          if (response) {
            const currentDate = new Date();
            const offset = currentDate.getTimezoneOffset();
            this.events = response.map(item => {
              const eventModel = new Event(item);
              const utc_time_based_on_offset = moment.utc(eventModel.event_time).utcOffset(offset).format(Configuration.FULL_DATE_TIME_FORMAT);
              const date_object = moment.utc(utc_time_based_on_offset, Configuration.FULL_DATE_TIME_FORMAT).toDate();
              const event = {
                title: eventModel.comment,
                start: date_object,
                end: date_object,
                color: Configuration.EVENT_CALENDAR.COLORS.GREENS,
                actions: this.actions,
                allDay: true,
                meta: {
                  event_id: eventModel.id
                }
              };
              return event;
            });
            this.eventsBK = this.common.cloneDeep(this.events);
            this.refresh.next();
          }
          this.common.hideLoader();
        });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (events.length > 0) {
        this.currentAction = Configuration.EVENT_CALENDAR.ACTIONS.EDIT;
        this.handleEvent('Edit Event', Configuration.EVENT_CALENDAR.ACTIONS.EDIT, events[0]);
      } else {
        this.currentAction = Configuration.EVENT_CALENDAR.ACTIONS.ADD;
        this.addEvent(date);
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
  }

  handleEvent(title: string, action: number, event: CalendarEvent): void {
    this.modalData = { title, event, action };
    this.activeModal = this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(date: Date = new Date()): void {
    const newEvent = {
      title: null,
      start: date,
      end: date,
      color: Configuration.EVENT_CALENDAR.COLORS.GREENS,
      actions: this.actions,
      allDay: true
    };
    this.handleEvent('Add New Event', Configuration.EVENT_CALENDAR.ACTIONS.ADD, newEvent);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeModal() {
    this.activeModal.close();
    this.events = this.common.cloneDeep(this.eventsBK);
  }

  save() {
    if (this.isAdmin) {
      if (this.currentAction === Configuration.EVENT_CALENDAR.ACTIONS.ADD) {
        this.calendarService.create({
          comment: this.modalData.event.title,
          event_time: moment(this.modalData.event.start).format(Configuration.DATE_TIME_FORMAT_SERVER),
          event_type: this.typeID,
          device_id: this.id
        }).subscribe(response => {
          this.common.hideLoader();
          this.initData();
          this.common.showToaster(
            TOASTER_TYPE.success,
            this.common.translate('CREATE'),
            this.common.translate('CREATE_SUCCESSFUL')
          );
        });
      } else if (this.currentAction === Configuration.EVENT_CALENDAR.ACTIONS.EDIT) {
        this.calendarService.update({
          id: this.modalData.event.meta.event_id,
          comment: this.modalData.event.title,
        }).subscribe(response => {
          this.common.hideLoader();
          this.initData();
          this.common.showToaster(
            TOASTER_TYPE.success,
            this.common.translate('UPDATE'),
            this.common.translate('UPDATE_SUCCESSFUL')
          );
        });
      }
      this.closeModal();
    }
  }

  delete() {
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_COMMENT'),
      () => {
        this.common.showLoader();
        try {
          this.calendarService.delete({
            id: this.modalData.event.meta.event_id,
            comment: this.modalData.event.title,
          })
            .subscribe(
              (response: any) => {
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.closeModal();
                this.initData();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }

  export() {
    const data = [];
    _.forEach(this.events, (event) => {
      const eventYear = event.start.getFullYear();
      const eventMonth = ('0' + (event.start.getMonth() + 1)).slice(-2);
      const eventDay = ('0' + event.start.getDate()).slice(-2);
      data.push({
        date: `${eventYear}-${eventMonth}-${eventDay}`,
        comment: event.title
      });
    });

    if (data.length === 0) {
      this.common.showToaster(this.toasterType.warning, 'Warning', 'No event to export');
    }

    const dateExport = new Date();
    const year = dateExport.getFullYear();
    const month = ('0' + (dateExport.getMonth() + 1)).slice(-2);
    const day = ('0' + dateExport.getDate()).slice(-2);
    const options = {
      showLabels: true,
      filename: `event-calendar-${year}${month}${day}`,
      headers: ['Date', 'Comment']
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  back() {
    this.router.navigateByUrl(`/${this.appRoute.DEVICES}/${this.id}/${this.type}`);
  }

  backToOverview() {
    this.common.navigate(`${Configuration.ROUTE_PATH.DEVICES}`);
  }
}
