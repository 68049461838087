import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService, LoadingState } from './loading.service';

@Component( {
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: [ './loading.component.scss' ]
} )
export class LoadingComponent implements OnInit, OnDestroy {
    show = false;
    color = 'primary';
    private subscription: Subscription;

    constructor( private loadingService: LoadingService ) {
    }

    ngOnInit() {
        this.subscription = this.loadingService.loaderState
                .subscribe( ( state: LoadingState ) => {
                    this.show = state.show;
                } );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}

