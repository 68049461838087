import { Component, OnInit } from '@angular/core';
import { Configuration, CONFIRM_MODAL_OPTIONS, TABLE_SETTINGS, TOASTER_TYPE } from 'src/app/constants';
import { Common } from 'src/app/services/common.service';
import { DeviceService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-glc-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  isAdmin = false;
  pageDescription = '';
  functionID: number;
  internalID: number;
  externalID: number;
  // Declare table settings
  isShowMultipleSelect = false;
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    status: 'Aktiv',
  }, {
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Süper user',
    status: 'Aktiv',
  }, {
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Administrator',
    status: 'Aktiv',
  }, {
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    status: 'Inaktiv',
  },
  {
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    status: 'Inaktiv',
  },
  {
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    status: 'Inaktiv',
  }];

  constructor(
    private common: Common,
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    try {
      this.functionID = Number(this.activatedRoute.snapshot.paramMap.get('functionID'));
      this.internalID = Number(this.activatedRoute.snapshot.paramMap.get('internalID'));
      this.externalID = Number(this.activatedRoute.snapshot.paramMap.get('externalID'));
    } catch (e) {
    }
    this.isAdmin = this.common.isAdminOrSuperUser();
    if (this.isAdmin) {
      this.pageDescription = 'DEVICES_DESCRIPTION';
    }
    this.initTable();
    this.getDeviceList();
  }

  getDeviceList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    if (this.functionID) {
      // tslint:disable-next-line:no-string-literal
      options['function__id'] = this.functionID;
    }
    if (this.internalID) {
      // tslint:disable-next-line:no-string-literal
      options['internal_team__id'] = this.internalID;
    }
    if (this.externalID) {
      // tslint:disable-next-line:no-string-literal
      options['external_team__id'] = this.externalID;
    }
    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.action = [
        {
          text: 'Details',
          icon: 'pencil-alt',
          url: `/#`
        }
      ];
      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  initTable() {
    const displayedColumns = ['name', 'email', 'role', 'status', 'action'];

    this.tableData = {
      displayedColumns,
      field: [
        {
          name: 'name',
          title: 'Name'
        },
        {
          name: 'email',
          title: 'E-mail'
        },
        {
          name: 'role',
          title: 'Rolle'
        },
        {
          name: 'status',
          title: 'Aktiv/ Inaktiv'
        },
        {
          name: 'action',
          title: 'Optionen',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  applyFilter(_filterValue: string) {
    this.getDeviceList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'building_name':
        this.sortValue.active = 'building__name';
        break;
      case 'function_name':
        this.sortValue.active = 'function__name';
        break;
    }
    this.getDeviceList();
  }

  add() {
    if (this.isAdmin) {
      this.common.navigate(`${this.deviceRoute}/create`);
    }
  }

  deleteDevices(listIds) {
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_DEVICE'),
      () => {
        try {
          this.deviceService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.isShowMultipleSelect = false;
                this.getDeviceList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }
}
