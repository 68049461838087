
const TABLE_NAME = {
  BUILDINGS: 'buildings',
  DEVICES: 'devices',
  ALARMS: 'alarms',
  SERVICE_TEAMS: 'service_teams',
  USERS: 'admins'
};

const DEFAULT_SETTINGS = {
    [TABLE_NAME.BUILDINGS]: [
      {
        name: 'NAME',
        column: 'name',
        active: true,
      },
      {
        name: 'LOCATION',
        column: 'location',
        active: true,
      },
      {
        name: 'STREET',
        column: 'street',
        active: true,
      },
      {
        name: 'ZIPCODE',
        column: 'zipcode',
        active: true,
      },
      {
        name: 'CITY',
        column: 'city',
        active: true,
      },
    ],
    [TABLE_NAME.DEVICES]: [
      {
        name: 'ID',
        column: 'device_hard_id',
        active: true,
      },
      {
        name: 'BUILDING',
        column: 'building_name',
        active: true,
      },
      {
        name: 'FUNCTION',
        column: 'function_name',
        active: true,
      },
      {
        name: 'LOCATION',
        column: 'location',
        active: true,
      }
    ],
    [TABLE_NAME.ALARMS]: [
      {
        name: 'FUNCTION',
        column: 'function_name',
        active: true,
      },
      {
        name: 'DEVICE',
        column: 'device_name',
        active: true,
      },
      {
        name: 'DEACTIVATED_BY',
        column: 'deactivated_by',
        active: true,
      },
      {
        name: 'DATE',
        column: 'date',
        active: true,
      },
      {
        name: 'TIME',
        column: 'time',
        active: true,
      },
      {
        name: 'COMMENT',
        column: 'comment',
        active: true,
      }
    ],
    [TABLE_NAME.SERVICE_TEAMS]: [
      {
        name: 'EMAIL',
        column: 'email',
        active: true,
      },
      {
        name: 'TEL',
        column: 'telephone',
        active: true,
      },
      {
        name: 'INTERNAL_EXTERNAL_TEAM',
        column: 'teamType',
        active: true,
      }
    ],
    [TABLE_NAME.USERS]: [
      {
        name: 'EMAIL',
        column: 'email',
        active: true,
      },
      {
        name: 'ROLE',
        column: 'roleText',
        active: true,
      },
      {
        name: 'ACTIVE_INACTIVE',
        column: 'activeText',
        active: true,
      },
    ]
  };

export const TABLE_SETTINGS = {
  TABLE_NAME,
  DEFAULT_SETTINGS
};
