import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Common } from '../../../services/common.service';
import { FunctionService } from '../../../services';

import { FunctionModel, FieldError } from '../../../models';
import { TOASTER_TYPE, Configuration } from '../../../constants';

@Component({
  selector: 'app-glc-function-detail',
  templateUrl: './function-detail.component.html',
  styleUrls: ['./function-detail.component.scss']
})
export class FunctionDetailComponent implements OnInit {
  isAdmin = false;
  functionRoute = Configuration.ROUTE_PATH.FUNCTIONS;
  pageDescription = '';
  text = '';

  public isChanged = false;
  public functionModel: FunctionModel = new FunctionModel();
  public functionModelBk: FunctionModel = new FunctionModel();
  errors: any = {
    name: new FieldError()
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
    private functionService: FunctionService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    this.activatedRoute.params.subscribe((params: Params) => {
      const state = window.history.state;
      const functionId = parseInt(params.id, 10);
      if (functionId) {
        if (state.id !== functionId) {
          this.backToFunctionPage();
        }
        if (this.isAdmin) {
          this.pageDescription = 'EDIT_FUNCTION';
        }
        this.functionModel = new FunctionModel(state);
        this.functionModelBk = this.common.cloneDeep(this.functionModel);
      } else {
        this.pageDescription = 'CREATE_FUNCTION';
      }
    });
  }

  save() {
    if (!this.isAdmin || this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        if (this.functionModel.id) {
          this.update();
        } else {
          this.create();
        }
      }
    );
  }

  update() {
    try {
      this.functionService.update(this.functionModel)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToFunctionPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  create() {
    try {
      this.functionService.create(this.functionModel)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('CREATE'),
              this.common.translate('CREATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToFunctionPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.functionModelBk, this.functionModel);
    if (!this.isChanged || !this.functionModel.name) {
      return true;
    }

    return this.errors.name.isInvalid;
  }

  backToFunctionPage() {
    this.common.navigate(`/${this.functionRoute}`);
  }

}
