import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from '../constants';
import { Device } from '../models';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get all devices
   * @param options: page, size, key
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.DEVICE, params, showLoader);
  }

  /**
   * Get device info by ID
   * @param id: DEVICE ID
   * @return Observable
   */
  public getByID(id) {
    return this.apiService.restGET(`${this.apiUrl.DEVICE}/${id}`);
  }

  /**
   * Create device
   * @param params: DEVICE
   * @return Observable
   */
  public create(params) {
    return this.apiService.restPOST(this.apiUrl.DEVICE, params);
  }

  /**
   * Update device
   * @param params: DEVICE
   * @return Observable
   */
  public update(params: Device) {
    const paramsClone = _.cloneDeep(params);
    delete paramsClone.id;
    paramsClone.building_id = paramsClone.building_id ? paramsClone.building_id.id : null;
    paramsClone.internal_team_id = paramsClone.internal_team_id ? paramsClone.internal_team_id.id : null;
    paramsClone.external_team_id = paramsClone.external_team_id ? paramsClone.external_team_id.id : null;
    return this.apiService.restPATCH(`${this.apiUrl.DEVICE}/${params.id}`, paramsClone);
  }

  /**
   * Delete
   * @param  params
   * @return  Observable
   */
  public delete(params) {
    return this.apiService.restDELETE(this.apiUrl.DEVICE_DELETE, params);
  }
}
