import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-glc-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss']
})
export class PageContentComponent implements OnInit {
  private displayTitle = '';
  private displayDescription = '';

  // title (2-ways binding)
  @Output() pageTitleChange = new EventEmitter();
  @Input()
  get pageTitle() {
    return this.displayTitle;
  }
  set pageTitle(val) {
    this.displayTitle = val;
    this.pageTitleChange.emit(this.displayTitle);
  }

  // title (2-ways binding)
  @Output() pageDescriptionChange = new EventEmitter();
  @Input()
  get pageDescription() {
    return this.displayDescription;
  }
  set pageDescription(val) {
    this.displayDescription = val;
    this.pageDescriptionChange.emit(this.displayDescription);
  }

  constructor() { }

  ngOnInit() {
  }

}
