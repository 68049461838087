<label class="tab-label">{{ 'SERVICE_DETAILS' | translate }}</label>
<div class="box-body">
  <div class="mt-20 block-info">
    <div class="col-left">
      <div class="text-red mb-10">
        <div class="col-label">{{ 'NEXT_SERVICE' | translate }}:</div>
        <div class="col-value">{{ nextService.service_date }}</div>
      </div>
      <div class="mb-10">
        <div class="col-label">{{ 'INFORM_DAYS_BEFORE' | translate }}:</div>
        <div class="col-value">{{ nextService.inform_days }} {{ 'DAYS' | translate }}</div>
      </div>
    </div>
    <div class="col-right col-btn">
      <div>
        <div class="btn-view-calendar" (click)="viewCalendar()">
          <i class="far fa-calendar-alt"></i>
          {{ 'VIEW_CALENDAR' | translate }}
        </div>
      </div>
      <div>
        <button class="mat-stroked-button btn btn-green" (click)="doService()">
          <i class="fas fa-cog"></i>
          {{ 'DO_SERVICE' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row box-body">
    <app-glc-table *ngIf="tableData" [data]="tableData" (page)="pageChange($event)" (sort)="sortChange($event)">
    </app-glc-table>
  </div>
</div>
