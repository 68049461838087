import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Common } from '../../services/common.service';
import { Configuration, TABLE_SETTINGS, CONFIRM_MODAL_OPTIONS, TOASTER_TYPE } from 'src/app/constants';
import { ServiceTeamService } from 'src/app/services';
import { Router } from '@angular/router';
import { ServiceTeam } from 'src/app/models';
// import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-glc-service-team',
  templateUrl: './service-team.component.html',
  styleUrls: ['./service-team.component.scss']
})
export class ServiceTeamComponent implements OnInit, AfterViewInit {
  tableData: any;

  serviceTeamRoute = Configuration.ROUTE_PATH.SERVICE_TEAMS;
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  isAdmin = false;
  multipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  pageDescription = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private teamService: ServiceTeamService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin() || this.common.isSuperUser();
    this.pageDescription = this.isAdmin ? 'SERVICE_TEAM_DESCRIPTION' : '';
    this.initTableData();
  }

  initTableData() {
    const tableDisplayColumns = this.common.getListColumns(TABLE_SETTINGS.TABLE_NAME.SERVICE_TEAMS);
    let displayedColumns = ['selectedId', 'name'];
    displayedColumns = displayedColumns.concat(tableDisplayColumns);

    const tableData = {
      displayedColumns,
      field: [
        { name: 'selectedId', title: 'ID' },
        { name: 'name', title: 'NAME' },
        { name: 'email', title: 'EMAIL' },
        { name: 'telephone', title: 'TEL' },
        { name: 'in_house', title: 'SERVICE_TEAM_TYPE' },
        { name: 'teamType', title: 'SERVICE_TEAM_TYPE' },
        { name: 'action', title: 'ACTIONS', link: true }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };

    this.tableData = tableData;
  }

  ngAfterViewInit() {
    this.getServiceTeamList();
  }

  getServiceTeamList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.teamService.getList(options)
        .subscribe(
          (response: any) => {
            const serviceTeams = [];
            for (let i = 0; i < response.results.length; i++) {
              const item: any = new ServiceTeam(response.results[i]);
              item.action = [
                {
                  text: 'View',
                  icon: 'eye',
                  url: `/${this.serviceTeamRoute}/${item.id}`
                }
              ];
              if (item.assigned > 0) {
                if (item.in_house === 'I') {
                  item.action.push({
                    text: 'ASSIGNED_DEVICES',
                    icon: 'plug',
                    url: `/${this.deviceRoute}/internal-team/${item.id}`
                  });
                } else {
                  item.action.push({
                    text: 'ASSIGNED_DEVICES',
                    icon: 'plug',
                    url: `/${this.deviceRoute}/external-team/${item.id}`
                  });
                }
              }
              item.selectedId = item.id;
              item.disabled = item.assigned > 0;
              item.teamType = item.in_house.toUpperCase() === 'E' ? 'EXTERNAL_SERVICE' : 'INTERNAL_SERVICE';
              serviceTeams.push(item);
            }

            this.tableData = {
              ...this.tableData,
              pageIndex: this.pageOffset,
              totalRecords: response.count,
              dataSource: serviceTeams
            };

            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  applyFilter(_filterValue: string) {
    this.getServiceTeamList();
  }

  pageChange(event) {
    console.log(event);
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'teamType':
        this.sortValue.active = 'in_house';
        break;
    }
    this.getServiceTeamList();
  }

  addNew() {
    this.router.navigate([`/${this.serviceTeamRoute}/create`]);
  }

  deleteDevices(listIds) {
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('SERVICE_TEAM_CONFIRM_DELETE'),
      () => {
        try {
          this.teamService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                console.log(response);
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.multipleSelect = false;
                this.getServiceTeamList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }

  getExportData() {
    if (this.tableData.dataSource.length > 0) {
      try {
        this.teamService.getExportData(this.searchValue)
          .subscribe(
            (response: any) => {
              console.log(response);
              this.common.hideLoader();
            });
      } catch (error) {
        this.common.hideLoader();
        this.common.showError([JSON.stringify(error)]);
      }
    }
  }
}
