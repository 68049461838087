import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterConfig } from 'angular2-toaster';
import { Subscription } from 'rxjs';

import { Configuration } from './constants/app.constants';
import { Common } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  title = 'prototype-webapp';

  public toasterConfig: ToasterConfig;

  constructor(
    public translate: TranslateService,
    common: Common
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');
    // common.handleAppSession();
  }

  ngOnInit() {
    this.toasterConfig = new ToasterConfig(Configuration.TOASTER_CONFIG);
  }

  ngAfterViewInit() {
    // subscribe the
  }

  ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }
}
