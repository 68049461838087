<div class="slide-option">
  <div class="left-container">
    <label class="left">
      {{ text | translate }}
    </label>
  </div>
  <mat-slide-toggle
    class="pull-right"
    [(ngModel)]="value"
    (ngModelChange)="onModelChangeEvent($event)"
  ></mat-slide-toggle>
</div>
