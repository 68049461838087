import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Common } from '../../services/common.service';

import { Configuration } from '../../constants';
import { FunctionService } from 'src/app/services';

@Component({
  selector: 'app-glc-functions',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss']
})
export class FunctionsComponent implements OnInit, AfterViewInit {
  isAdmin = false;
  functionRoute = Configuration.ROUTE_PATH.FUNCTIONS;
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  pageDescription = 'FUNCTIONS_VIEW_DESCRIPTION';
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private functionService: FunctionService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    if (this.isAdmin) {
      this.pageDescription = 'FUNCTIONS_DESCRIPTION';
    }
    this.initData();
    this.getFunctionsList();
  }

  ngAfterViewInit() {
  }

  initData() {
    this.tableData = {
      displayedColumns: ['name', 'action'],
      field: [
        {
          name: 'name',
          title: 'NAME'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  getFunctionsList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.functionService.getList(options)
        .subscribe(
          (response: any) => {
            console.log(response);
            const results = response.results.map(item => {
              return this.mapFunctionData(item);
            });

            this.tableData = {
              ...this.tableData,
              pageIndex: this.pageOffset,
              totalRecords: response.count,
              dataSource: results
            };

            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  mapFunctionData(data) {
    return {
      id: data.id,
      name: data.name,
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: `/${this.functionRoute}/${data.id}`
        },
        {
          text: 'ASSIGNED_DEVICES',
          icon: 'plug',
          url: `/${this.deviceRoute}/function/${data.id}`
        },
      ],
    };
  }

  applyFilter(_filterValue: string) {
    this.getFunctionsList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    this.getFunctionsList();
  }

  add() {
    if (this.isAdmin) {
      this.common.navigate(`${this.functionRoute}/create`);
    }
  }
}
