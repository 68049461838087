import { Component, OnInit } from '@angular/core';
import { Common, Validator, DeviceServiceService, DeviceService, ServiceTeamService, DocumentCategoryService, CalendarService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { Configuration, TOASTER_TYPE, FILE_TYPE } from 'src/app/constants';
import { Service, FieldError, Document } from 'src/app/models';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-glc-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {
  route = Configuration.ROUTE_PATH;
  pageDescription = 'SERVICE_PROTOCOL_DESCRIPTION';
  isAdminOrSuperUser = false;
  serviceID: number;
  deviceID: number;
  building: string;
  isChanged = false;
  isDisabled = false;
  document = new Document();
  disabledDates = [];

  public service: Service = new Service();
  public serviceBK: Service = new Service();

  // Declare validation
  errors: any = {
    carried_out: new FieldError(),
    finding: new FieldError(),
    description_finding: new FieldError(),
    procedure: new FieldError(),
    service_date: new FieldError(),
    inform_days: new FieldError(),
    document_category: new FieldError(),
  };

  // Declare document variables
  documents = [];
  fileTypes = FILE_TYPE.SERVICE;
  validDocument = true;

  follow_up_options = [{
    id: 0,
    name: 'NOT_COMPLETED'
  }, {
    id: 1,
    name: 'COMPLETED'
  }];

  // Declare services
  privateTeamService: any;
  privateDocumentCategoryService: any;

  constructor(
    private common: Common,
    private validator: Validator,
    private activatedRoute: ActivatedRoute,
    private deviceServiceService: DeviceServiceService,
    private deviceService: DeviceService,
    private teamService: ServiceTeamService,
    private documentCategoryService: DocumentCategoryService,
    private calendarService: CalendarService
  ) { }

  ngOnInit() {
    this.privateDocumentCategoryService = this.documentCategoryService;
    this.privateTeamService = this.teamService;
    this.isAdminOrSuperUser = this.common.isAdminOrSuperUser();
    try {
      this.serviceID = Number(this.activatedRoute.snapshot.paramMap.get('serviceID'));
      this.deviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));
    } catch (e) {
      this.backToDevicePage();
    }
    if (!this.serviceID) {
      const credentials = this.common.getCredentials();
      this.service.checked_by = credentials.name;
    }
    this.initData();
  }

  initData() {
    if (this.serviceID) {
      forkJoin([
        this.deviceServiceService.getByID(this.serviceID),
        this.deviceService.getByID(this.deviceID),
        this.calendarService.getList({
          type: Configuration.CALENDAR.TYPE.SERVICE,
          device_id: this.deviceID
        })
      ]).subscribe((responses: any) => {
        this.common.hideLoader();
        this.service = new Service(responses[0]);
        this.building = responses[1].building ? responses[1].building.name : '';
        this.serviceBK = this.common.cloneDeep(this.service);
        this.isDisabled = this.serviceBK.follow_up === 1;
        this.processEvents(responses[2]);
      });
    } else {
      forkJoin([
        this.deviceService.getByID(this.deviceID),
        this.calendarService.getList({
          type: Configuration.CALENDAR.TYPE.SERVICE,
          device_id: this.deviceID
        })
      ]).subscribe((responses: any) => {
        this.common.hideLoader();
        this.building = responses[0].building ? responses[0].building.name : '';
        this.service.service_team = responses[0].external_team ? responses[0].external_team.name : '';
        this.serviceBK = this.common.cloneDeep(this.service);
        this.isDisabled = this.serviceBK.follow_up === 1;
        this.processEvents(responses[1]);
      });
    }
  }

  processEvents(data) {
    if (data && data.length > 0) {
      _.forEach(data, (item) => {
        const date_object = moment.utc(item.event_time).format(Configuration.DATE_FORMAT);
        this.disabledDates.push(date_object);
      });
    }
  }

  save() {
    if (this.isDisabledBtn() || !this.isChanged || this.serviceBK.follow_up === 1) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        if (this.serviceID) {
          this.update();
        } else {
          this.create();
        }
      }
    );
  }

  create() {
    try {
      if (this.service.validate() === '') {
        const formData = this.prepareFormData();
        this.deviceServiceService.create(formData)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('CREATE'),
                this.common.translate('CREATE_SUCCESSFUL')
              );
              this.backToDevicePage();
            });
      }
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  update() {
    try {
      if (this.service.validate() === '') {
        const formData = this.prepareFormData();
        this.deviceServiceService.update(this.serviceID, formData)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('UPDATE'),
                this.common.translate('UPDATE_SUCCESSFUL')
              );
              this.backToDevicePage();
            });
      }
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  prepareFormData() {
    // Reformat date time before post to server
    const paramsClone = this.common.cloneDeep(this.service);
    paramsClone.service_date = moment(paramsClone.service_date, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);
    paramsClone.end_date = moment(paramsClone.end_date, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);

    const formData = new FormData();
    formData.append('service_date', paramsClone.service_date);
    if (this.service.inform_days) {
      formData.append('inform_days', `${this.service.inform_days}`);
    } else {
      formData.append('inform_days', `0`);
    }
    formData.append('carried_out', this.service.carried_out);
    formData.append('finding', this.service.finding);
    formData.append('description_finding', this.service.description_finding);
    formData.append('service_team', this.service.service_team);
    formData.append('procedure', this.service.procedure);
    formData.append('follow_up', `${this.service.follow_up}`);
    formData.append('end_date', paramsClone.end_date);
    formData.append('checked_by', this.service.checked_by);
    formData.append('device_id', `${this.deviceID}`);

    if (this.documents.length > 0 && this.service.document_category) {
      _.forEach(this.documents, (document) => {
        formData.append('device_document', document);
      });
      // tslint:disable-next-line:no-string-literal
      const category = this.service.document_category['name'] ? this.service.document_category['name'] : this.service.document_category;
      formData.append('device_document_category', category);
    }
    return formData;
  }

  validate(field: string = null) {
    this.validator.validate(field, this.service, this.errors);
  }

  validateDocument() {
    if (this.documents.length > 0 && !this.service.document_category) {
      return false;
    }
    return this.validDocument;
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.service, this.serviceBK);
    const result = this.service.validate(null, true);
    return !this.validateDocument() || !this.isChanged || result !== '';
  }

  backToDevicePage() {
    this.common.navigate(`${this.route.DEVICES}/${this.deviceID}/${this.route.DEVICE_SERVICE_TAB}`);
  }

  handleFileInput(error: boolean) {
    this.validDocument = !error;
  }
}
