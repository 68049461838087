import * as Validator from 'validatorjs';

export class Document {
  /**
   * ID - auto increment
   */
  public id: number;
  /**
   * Device ID
   */
  public belong_to_obj: number;
  /**
   * Category name
   */
  public category: string;
  /**
   * Document type
   * 0: Device
   * 1: Device Service
   */
  public document_type: number;
  /**
   * File URL
   */
  public filename: string;
  /**
   * File display name
   */
  public file_display_name: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.belong_to_obj = data.belong_to_obj || null;
    this.category = data.category || null;
    this.document_type = data.document_type || null;
    this.filename = data.filename || null;
    this.file_display_name = data.file_display_name || null;
  }

  /**
   * Validate model when create or update
   */
  validate(field = null, editing = false): any {
    const rules = {
      category: `required`
    };

    if (editing) {
      // tslint:disable-next-line:no-string-literal
      rules['file_display_name'] = 'required';
    }

    const validation = new Validator(this, rules, {
      'required.file_display_name': 'FILE_NAME_IS_REQUIRED',
      'required.category': 'CATEGORY_IS_REQUIRED',
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
