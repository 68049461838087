import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Common, TaskService } from '../../services';

import { Configuration, TASK_CATEGORY, MESSAGE_EVENT } from '../../constants';
import { Task } from 'src/app/models/task.model';

@Component({
  selector: 'app-glc-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit, AfterViewInit {
  taskRoute = Configuration.ROUTE_PATH.TO_DO_LIST;
  deviceRoute = Configuration.ROUTE_PATH;
  tableData: any = null;
  pageSize = 30;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private taskService: TaskService
  ) { }

  ngOnInit() {
    this.initData();
    this.getTaskList();
  }

  ngAfterViewInit() {
  }

  initData() {
    this.tableData = {
      displayedColumns: ['name', 'displayCategory', 'action'],
      field: [
        {
          name: 'name',
          title: 'TASK_NAME',
          customTranslateValue: true
        },
        {
          name: 'displayCategory',
          title: 'CATEGORY'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  getTaskList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.taskService.getList(options)
        .subscribe(
          (response: any) => {
            console.log(response);
            const results = response.results.map(item => {
              return this.mapTaskData(item);
            });

            this.tableData = {
              ...this.tableData,
              pageIndex: this.pageOffset,
              totalRecords: response.count,
              dataSource: results
            };
            this.common.sendEventMessage( MESSAGE_EVENT.UPDATE_MENU_TASK_COUNT, response.count );
            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  mapTaskData(data) {
    const task = new Task(data);
    let result: any = {
      ...task,
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: task.category === TASK_CATEGORY.CONTROL
            ? `/${this.deviceRoute.DEVICES}/${task.device_id}/${this.deviceRoute.DEVICE_CONTROL_TAB}/${task.control_service_id}`
            : task.category === TASK_CATEGORY.SERVICE ? `/${this.deviceRoute.DEVICES}/${task.device_id}/${this.deviceRoute.DEVICE_SERVICE_TAB}/${task.control_service_id}`
            : `/${this.taskRoute}/${task.id}`
        }
      ],
      selected: false
    };

    if (task.category !== TASK_CATEGORY.GENERAL) {
      result = {
        ...result,
        customTranslateValue: {
          data: {
            value: task.device_id
          },
          text: 'DEVICE_ID_VALUE'
        }
      };

    }
    return result;
  }

  applyFilter(_filterValue: string) {
    this.getTaskList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.getTaskList();
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'displayCategory':
        this.sortValue.active = 'category';
        break;
    }
    this.getTaskList();
  }

  add() {
    this.common.navigate(`${this.taskRoute}/create`);
  }

}
