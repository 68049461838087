import * as moment from 'moment';
import { Configuration } from '../constants';

export class Alarm {
  /**
   * user id
   */
  public id: number;
  /**
   * building name
   */
  public building_name: string;
  /**
   * function name
   */
  public function_name: string;
  /**
   * deactivated_by
   */
  public deactivated_by: string;
  /**
   * device name
   */
  public device_name: string;
  /**
   * date
   */
  public date: string;
  /**
   * time
   */
  public time: string;
  /**
   * comment
   */
  public comment: string;
  /**
   * alarm_type
   */
  public alarm_type: string;
  /**
   * alarm_group
   */
  public alarm_group: string;
  /**
   * alarm_message
   */
  public alarm_message: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.building_name = data.building_name || '';
    this.function_name = data.function_name || '';
    this.deactivated_by = data.deactivated_by || '';
    this.device_name = data.device_name || '';
    this.date = data.date_time ? moment.utc(data.date_time).format(Configuration.DATE_FORMAT) : '';
    this.time = data.date_time ? moment.utc(data.date_time).format(Configuration.TIME_FORMAT_WITHOUT_SECOND) : '';
    this.comment = data.comment || '';
    this.alarm_type = data.alarm_type || '';
    this.alarm_group = data.alarm_group || '';
    this.alarm_message = data.alarm_message || '';
  }
}
