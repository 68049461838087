import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

import { Common, Validator, AuthenticationService } from '../../services';
import { FieldError, Login, Credential, ResetPassword } from '../../models';
import { TOASTER_TYPE } from '../../constants';

@Component({
  selector: 'app-glc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  loginForm = new Login();
  errors: any = {
    email: new FieldError(),
    password: new FieldError()
  };

  codeForm = {
    email: ''
  };
  codeErrors = {
    email: new FieldError()
  };

  resetForm = new ResetPassword();
  resetErrors = {
    code: new FieldError(),
    newPassword: new FieldError(),
    repeatPassword: new FieldError()
  };

  constructor(
    private common: Common,
    private validator: Validator,
    private authenticationService: AuthenticationService
  ) {
  }

  public ngOnInit() {
    const credentials = this.common.getCredentials();
    if (credentials && credentials.remember === true) {
      if (credentials && credentials.hashPassword) {
        this.loginForm.email = credentials.email;
        this.loginForm.password = this.common.decryptMessage(credentials.hashPassword);
        this.loginForm.isRemember = credentials.remember;
      }
    }
  }

  ngAfterViewInit() {
    this.common.resetSession();
  }

  public ngOnDestroy() {
  }

  /* Start: For Login Form */
  /**
   * @function updateCredentials
   * @description the function allows to update the credentials
   * @param {object }data: data for update credentials info
   * @param {string} token: the token
   */
  private updateCredentials(data) {
    const credentials = new Credential({
      email: this.loginForm.email,
      password: '',
      hashPassword: this.common.encryptMessage(this.loginForm.password),
      remember: this.loginForm.isRemember,
      connected: true,
    });
    this.common.storeCredentials(credentials);
  }

  public async login() {
    if (this.isDisabledBtn()) {
      return;
    }
    this.common.showLoader();
    try {
      setTimeout(() => {
        this.updateCredentials({});
        this.common.setToken('fake_token');
        this.common.navigate('/');
        this.common.hideLoader();
      }, 2000);


    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  public isEmailValid(event) {
    const validateObject = new FieldError();

    if (event) {
      const isValid = this.validator.validateEmail(event);
      if (!isValid) {
        validateObject.isInvalid = true;
        validateObject.message = 'EMAIL_INVALID';
      }
    }
    this.errors.email = validateObject;
  }

  public isPasswordValid(event) {
    const validateObject = new FieldError();

    if (event) {
      const isValid = this.validator.validateRequired(event);
      if (!isValid) {
        validateObject.isInvalid = true;
        validateObject.message = 'PASSWORD_IS_REQUIRED';
      }
    }
    this.errors.password = validateObject;
  }

  public isDisabledBtn() {
    if (!this.loginForm.email || !this.loginForm.password) {
      return true;
    }
    const isEmailInvalid = this.errors.email.isInvalid;
    const isPasswordInvalid = this.errors.password.isInvalid;

    return isEmailInvalid || isPasswordInvalid;
  }

  public isDisabledWeiter() {
    if (!this.loginForm.email) {
      return true;
    }
    const isEmailInvalid = this.errors.email.isInvalid;

    return isEmailInvalid;
  }
  /* End: For Login Form */


  /* Start: For get code Form */
  public isEmailCodeValid(event) {
    const validateObject = new FieldError();

    if (event) {
      const isValid = this.validator.validateEmail(event);
      if (!isValid) {
        validateObject.isInvalid = true;
        validateObject.message = 'EMAIL_INVALID';
      }
    }
    this.codeErrors.email = validateObject;
  }

  public isDisabledRequestCodeBtn() {
    if (!this.codeForm.email) {
      return true;
    }

    return this.codeErrors.email.isInvalid;
  }

  public async requestCode() {
    if (this.isDisabledRequestCodeBtn()) {
      return;
    }

    this.common.showLoader();
    try {
      setTimeout(() => {
        this.common.showToaster(
          TOASTER_TYPE.success,
          this.common.translate('SUCCESS'),
          this.common.translate('CHECK_EMAIL_FOR_CODE')
        );
        this.common.hideLoader();
        this.showResetPassword();
      }, 2000);
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }
  /* End: For get code Form */

  /* Start: For reset password Form */
  public isMatchPasswordValid(event) {
    const validateObject = new FieldError();

    if (event) {
      const isValid = this.validator.validateRequired(event);
      if (!isValid) {
        validateObject.isInvalid = true;
        validateObject.message = 'PASSWORD_IS_REQUIRED';
      } else {
        if (this.resetForm.newPassword && this.resetForm.newPassword !== event) {
          validateObject.isInvalid = true;
          validateObject.message = 'PASSWORD_NOT_MATCH';
        }
      }
    }
    this.resetErrors.repeatPassword = validateObject;
  }

  public isNewPasswordValid(event) {
    this.resetErrors.newPassword = this.validator.validatePassword(event);
    this.isMatchPasswordValid(this.resetForm.repeatPassword);
  }

  public isDisabledResetBtn() {
    if (!this.resetForm.code
      || !this.resetForm.newPassword
      || !this.resetForm.repeatPassword
    ) {
      return true;
    }

    return this.resetErrors.code.isInvalid
      || this.resetErrors.newPassword.isInvalid
      || this.resetErrors.repeatPassword.isInvalid;
  }

  private resetModel() {
    this.resetForm = {
      code: '',
      newPassword: '',
      repeatPassword: ''
    };
    this.resetErrors = {
      code: new FieldError(),
      newPassword: new FieldError(),
      repeatPassword: new FieldError()
    };
    this.codeForm = {
      email: ''
    };
    this.codeErrors = {
      email: new FieldError()
    };
  }

  public async resetPassword() {
    if (this.isDisabledResetBtn()) {
      return;
    }
    this.common.showLoader();
    try {
      setTimeout(() => {
        this.common.showToaster(
          TOASTER_TYPE.success,
          this.common.translate('SUCCESS'),
          this.common.translate('PASSWORD_RESET_SUCCESS')
        );
        this.common.hideLoader();
        this.backToLogin();
      }, 2000);
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }
  /* End: For reset password Form */

  /* Start: Show/Hide form events */
  public showForgotPassword() {
    $('.sign-in').fadeOut(() => {
      this.resetModel();
      $('.request-code').fadeIn();
    });
  }

  public showResetPassword() {
    $('.request-code').fadeOut(() => {
      $('.reset-password').fadeIn();
    });
  }

  public backToLogin() {
    $('.request-code').fadeOut(() => {
      $('.reset-password').fadeOut(() => {
        this.resetModel();
        $('.sign-in').fadeIn();
      });
    });
  }

  public showPassword() {
    $('.weiter-container').fadeOut(() => {
      $('.password-container').fadeIn();
    });
  }
  /* End: Show/Hide form events */
}
