import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Alarm } from '../../../models';
import { Common, AlarmService } from 'src/app/services';
import { Configuration } from 'src/app/constants';

@Component({
  selector: 'app-glc-alarm-detail',
  templateUrl: './alarm-detail.component.html',
  styleUrls: ['./alarm-detail.component.scss']
})
export class AlarmDetailComponent implements OnInit {
  id: number;
  public alarm: Alarm = new Alarm();

  constructor(
    private activatedRoute: ActivatedRoute,
    private alarmService: AlarmService,
    private common: Common,
    private location: Location
  ) { }

  ngOnInit() {
    try {
      this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    } catch (e) {
      this.backToDevicesPage();
    }
    this.initData();
  }

  initData() {
    try {
      this.alarmService.getByID(this.id).subscribe((response: any) => {
        this.common.hideLoader();
        this.alarm = new Alarm(response);

      });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  backToDevicesPage() {
    this.common.navigate(Configuration.ROUTE_PATH.ALARMS);
  }

  backPage() {
    this.location.back();
  }
}
