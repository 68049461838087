import { Component, OnInit, Input } from '@angular/core';

declare var $;

@Component({
  selector: 'app-glc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabList: any;

  constructor() { }

  ngOnInit() {
  }

  scrollTop() {
    $('.tabs-content-scrollable').scrollTop(0);
  }
}
