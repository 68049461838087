<div class="tabs-header">
    <ul class="nav nav-tabs mt-20 clearfix" id="tabControl">
        <li *ngFor="let tab of tabList" class="nav-item" [routerLinkActive]="'active'" (click)="scrollTop()">
            <a [routerLink]="tab.link" class="nav-link">
                {{ tab.name | translate }}
            </a>
        </li>
    </ul>
</div>
<div class="tab-content tabs-content-scrollable mt-40 clearfix">
    <div class="tab-pane fade in active">
        <router-outlet></router-outlet>
    </div>
</div>