import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Common } from 'src/app/services';
import { Configuration } from 'src/app/constants';

@Component({
  selector: 'app-glc-device-detail-tabs',
  templateUrl: './device-detail-tabs.component.html',
  styleUrls: ['./device-detail-tabs.component.scss']
})
export class DeviceDetailTabsComponent implements OnInit {
  pageDescription = 'UPDATE_DEVICE';
  id: number;

  tabList = [
    {
      name: 'INFO',
      link: Configuration.ROUTE_PATH.DEVICE_INFO_TAB,
    },
    {
      name: 'DOCUMENTS',
      link: Configuration.ROUTE_PATH.DEVICE_DOCUMENTS_TAB,
    },
    {
      name: 'SERVICE',
      link: Configuration.ROUTE_PATH.DEVICE_SERVICE_TAB,
    },
    {
      name: 'CONTROL_REPAIR',
      link: Configuration.ROUTE_PATH.DEVICE_CONTROL_TAB,
    },
    {
      name: 'ALARMS',
      link: Configuration.ROUTE_PATH.DEVICE_ALARMS_TAB,
    }
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
  ) { }

  ngOnInit() {
    try {
      this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    } catch (error) {
      this.backToDevicesPage();
    }
  }

  backToDevicesPage() {
    this.common.navigate(Configuration.ROUTE_PATH.DEVICES);
  }
}
