import { USER_ROLE } from '../constants';

export class Credential {

  /**
   * id
   */
  public id: number;

  /**
   * email
   */
  public email: string;

  /**
   * password
   */
  public password: string;

  /**
   * hashPassword
   */
  public hashPassword: string;

  /**
   * accessToken
   */
  public accessToken: string;

  /**
   * expiresIn
   */
  public expiresIn: number;

  /**
   * refreshToken
   */
  public refreshToken: string;

  /**
   * remember
   */
  public remember: boolean;

  /**
   * connected
   */
  public connected: boolean;

  /**
   * name
   */
  public name: string;

  /**
   * role
   */
  public role: number;

  /**
   * settings
   */
  public settings: any;


  public constructor(data: any = {}) {
      data = (data === null) ? {} : data;
      this.id = data.id || null;
      this.email = data.email || '';
      this.name = data.name || '';
      this.password = data.password || '';
      this.hashPassword = data.hashPassword || '';
      this.accessToken = data.accessToken || '';
      this.expiresIn = data.expiresIn || null;
      this.refreshToken = data.refreshToken || '';
      this.remember = data.remember || false;
      this.connected = data.connected || false;
      this.role = data.role || USER_ROLE.STANDARD_USER;
      this.settings = data.settings || null;
  }
}
