<app-glc-page-content [pageTitle]="'DEVICES_TITLE'" [pageDescription]="pageDescription">
    <div class="block-page-header">
        <div class="title">
            <h1>Benutzerverwaltung</h1>
            <p>Ihre Benutzerübersicht</p>
        </div>
    </div>
    <app-glc-glsearch buttonText="Benutzer hinzufügen" [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
    <app-glc-table class="table-2" *ngIf="tableData" [data]="tableData" (page)="pageChange($event)" (sort)="sortChange($event)"
        [(showMultipleSelect)]="isShowMultipleSelect" [(hasDelete)]="isAdmin"
        (handleDeleteItem)="deleteDevices($event)"></app-glc-table>
</app-glc-page-content>