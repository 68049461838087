import { Component, OnInit } from '@angular/core';
import { Configuration, FILE_TYPE, TOASTER_TYPE } from 'src/app/constants';
import { FieldError, Device, Building } from 'src/app/models';
import { Common, Validator, DeviceService, BuildingService, FunctionService, DocumentCategoryService, InternalTeamService, ExternalTeamService } from 'src/app/services';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-glc-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent implements OnInit {
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  pageDescription = 'CREATE_DEVICE';
  id = 0;
  document_categories = [];
  listFunctions = [];
  public device: Device = new Device();
  public deviceBK: Device = new Device();

  // Declare validation
  errors: any = {
    name: new FieldError(),
    device_hard_id: new FieldError(),
    building_id: new FieldError(),
    function_id: new FieldError(),
    external_team_id: new FieldError(),
    service_date_time: new FieldError(),
    service_inform_days: new FieldError(),
    control_date_time: new FieldError(),
    control_inform_days: new FieldError(),
    device_document_category: new FieldError(),
  };

  public listDocumentCategory = [];

  isChanged: boolean;

  // Declare services
  privateBuildingService: any;
  privateFunctionService: any;
  privateInternalTeamService: any;
  privateExternalTeamService: any;
  privateDocumentCategoryService: any;

  // Declare document variables
  documents = [];

  fileTypes = FILE_TYPE.DEFAULT;
  validDocument = true;

  constructor(
    private common: Common,
    private validator: Validator,
    private deviceService: DeviceService,
    private buildingService: BuildingService,
    private functionService: FunctionService,
    private internalTeamService: InternalTeamService,
    private externalTeamService: ExternalTeamService,
    private documentCategoryService: DocumentCategoryService
  ) { }

  ngOnInit() {
    this.privateBuildingService = this.buildingService;
    this.privateFunctionService = this.functionService;
    this.privateInternalTeamService = this.internalTeamService;
    this.privateExternalTeamService = this.externalTeamService;
    this.privateDocumentCategoryService = this.documentCategoryService;
  }

  save() {
    if (this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        this.create();
      }
    );
  }

  create() {
    if (this.device.validate() === '') {
      try {
        // Reformat date time before post to server
        const paramsClone = this.common.cloneDeep(this.device);
        paramsClone.service_date_time = moment(paramsClone.service_date_time, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);
        paramsClone.control_date_time = moment(paramsClone.control_date_time, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);

        const formData = new FormData();
        formData.append('name', this.device.name);
        formData.append('device_hard_id', this.device.device_hard_id);
        formData.append('instaguard_id', this.device.instaguard_id);
        formData.append('building_id', `${this.device.building_id}`);
        formData.append('location', this.device.location);
        formData.append('function_id', `${this.device.function_id}`);
        if (this.device.internal_team_id) {
          formData.append('internal_team_id', `${this.device.internal_team_id}`);
        }
        if (this.device.external_team_id) {
          formData.append('external_team_id', `${this.device.external_team_id}`);
        }
        formData.append('service_date_time', `${paramsClone.service_date_time}`);
        if (this.device.service_inform_days) {
          formData.append('service_inform_days', `${this.device.service_inform_days}`);
        } else {
          formData.append('service_inform_days', `0`);
        }
        formData.append('control_date_time', `${paramsClone.control_date_time}`);
        if (this.device.control_inform_days) {
          formData.append('control_inform_days', `${this.device.control_inform_days}`);
        } else {
          formData.append('control_inform_days', `0`);
        }
        formData.append('follow_up', `${this.device.follow_up}`);

        if (this.documents.length > 0 && this.device.device_document_category) {
          _.forEach(this.documents, (document) => {
            formData.append('device_document', document);
          });
          // tslint:disable-next-line:no-string-literal
          const category = this.device.device_document_category['name'] ? this.device.device_document_category['name'] : this.device.device_document_category;
          formData.append('device_document_category', category);
        }

        this.deviceService.create(formData)
          .subscribe(
            (_response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('CREATE'),
                this.common.translate('CREATE_SUCCESSFUL')
              );
              this.backToDevicesPage();
            });
      } catch (error) {
        this.common.hideLoader();
        this.common.showError([JSON.stringify(error)]);
      }
    }
  }

  validate(field: string = null) {
    this.validator.validate(field, this.device, this.errors);
    if (field === 'building_id') {
      if (this.device.building_id) {
        try {
          this.buildingService.getByID(this.device.building_id).subscribe((response: any) => {
            this.common.hideLoader();
            const building = new Building(response);
            this.listFunctions = building.function;
          });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      } else {
        this.listFunctions = [];
        this.device.function_id = null;
      }
    }
  }

  validateDocument() {
    if (this.documents.length > 0 && !this.device.device_document_category) {
      return false;
    }
    return this.validDocument;
  }

  public isDisabledBtn() {
    if (!_.isEqual(this.deviceBK, this.device)) {
      this.isChanged = true;
    }
    const result = this.device.validate();
    return !this.validateDocument() || result !== '';
  }

  backToDevicesPage() {
    this.common.navigate(this.deviceRoute);
  }

  handleFileInput(error: boolean) {
    this.validDocument = !error;
  }
}
