import { Injectable } from '@angular/core';
import { Configuration } from '../constants';
import { ApiService } from './api.service';
import { User } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * getList
   * @param  options
   * @return  Observable
   */
  public getList(options: any, showLoader = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.USERS, params, showLoader);
  }

  /**
   * create
   * @param {User} user
   * @return  Observable
   */
  public create(user: User) {
    const params = {
      name: user.name,
      email: user.email,
      password: user.password,
      role_id: user.role,
      is_active: user.active
    };
    return this.apiService.restPOST(this.apiUrl.USERS, params);
  }

  /**
   * update
   * @param {User} user
   * @return  Observable
   */
  public update(user: User) {
    const params: any = {
      name: user.name,
      email: user.email,
      role_id: user.role,
      is_active: user.active
    };
    if (user.password) {
      params.password = user.password;
    }
    return this.apiService.restPATCH(`${this.apiUrl.USERS}/${user.id}`, params);
  }

  /**
   * details
   * @param  id
   * @return  Observable
   */
  public details(id: number) {
    return this.apiService.restGET(`${this.apiUrl.USERS}/${id}`);
  }

  /**
   * delete
   * @param  params
   * @return  Observable
   */
  public delete(params) {
    return this.apiService.restDELETE(this.apiUrl.USERS_DELETE, params);
  }
}
