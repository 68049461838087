import { Component, OnInit } from '@angular/core';
import { Common, AlarmService } from '../../../../services';
import { Configuration } from '../../../../constants';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-glc-alarms-tab',
  templateUrl: './alarms-tab.component.html',
  styleUrls: ['./alarms-tab.component.scss']
})
export class AlarmsTabComponent implements OnInit {
  id: number;
  alarmRoute = Configuration.ROUTE_PATH.ALARMS;
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private alarmService: AlarmService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    try {
      this.id = Number(this.activatedRoute.parent.snapshot.paramMap.get('id'));
    } catch (e) {
      this.backToDevicesPage();
    }
    this.initTable();
    this.initData();
  }

  initTable() {
    this.tableData = {
      displayedColumns: ['date', 'time', 'alarm_type', 'alarm_group', 'alarm_message', 'deactivated_by', 'action'],
      field: [
        {
          name: 'date',
          title: 'DATE'
        },
        {
          name: 'time',
          title: 'TIME'
        },
        {
          name: 'alarm_type',
          title: 'ALARM_TYPE'
        },
        {
          name: 'alarm_group',
          title: 'ALARM_GROUP'
        },
        {
          name: 'alarm_message',
          title: 'ALARM_MESSAGE'
        },
        {
          name: 'deactivated_by',
          title: 'DEACTIVATED_BY'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  initData() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.alarmService.getListByID(this.id, options)
        .subscribe(
          (response: any) => {
            console.log(response);
            const results = response.results.map(item => this.mapAlarmrData(item));

            this.tableData = {
              ...this.tableData,
              pageIndex: this.pageOffset,
              totalRecords: response.count,
              dataSource: results
            };

            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  mapAlarmrData(data: any) {
    data.date = moment.utc(data.date_time).format(Configuration.DATE_FORMAT);
    data.time = moment.utc(data.date_time).format(Configuration.TIME_FORMAT_WITHOUT_SECOND);
    const result = {
      ...data,
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: `/${this.alarmRoute}/${data.id}`
        }
      ]
    };
    return result;
  }

  applyFilter(_filterValue: string) {
    this.initData();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.initData();
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'date':
        this.sortValue.active = 'date_time';
        break;
      case 'time':
        this.sortValue.active = 'date_time';
        break;
    }
    this.initData();
  }

  backToDevicesPage() {
    this.common.navigate(Configuration.ROUTE_PATH.DEVICES);
  }
}
