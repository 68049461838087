import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-glc-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss']
})
export class PageDetailsComponent implements OnInit {

  private displayTitle = '';
  private displayDescription = '';
  private backText = '';

  // title (2-ways binding)
  @Output() pageTitleChange = new EventEmitter();
  @Input()
  get pageTitle() {
    return this.displayTitle;
  }
  set pageTitle(val) {
    this.displayTitle = val;
    this.pageTitleChange.emit(this.displayTitle);
  }

  // pageDescription (2-ways binding)
  @Output() pageDescriptionChange = new EventEmitter();
  @Input()
  get pageDescription() {
    return this.displayDescription;
  }
  set pageDescription(val) {
    this.displayDescription = val;
    this.pageDescriptionChange.emit(this.displayDescription);
  }

  // backBtnText (2-ways binding)
  @Output() backBtnTextChange = new EventEmitter();
  @Input()
  get backBtnText() {
    return this.backText;
  }
  set backBtnText(val) {
    this.backText = val;
    this.backBtnTextChange.emit(this.backText);
  }

  @Output() backPress: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onBackEvent() {
    this.backPress.emit();
  }

}
