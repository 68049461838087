import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Common } from '../services/common.service';
import { USER_ROLE } from '../constants';

@Injectable()
export class UserGuard implements CanActivate {

  constructor(
    private common: Common
  ) { }

  canActivate() {
    try {
      const credentials = this.common.getCredentials();
      const enableUserMenu = !!(credentials.role === USER_ROLE.ADMINISTRATOR || credentials.role === USER_ROLE.SUPER_USER);

      if (!enableUserMenu) {
        this.common.navigate('');
        return false;
      }
    } catch (e) {
      this.common.navigate('');
      return false;
    }
    return true;
  }
}
