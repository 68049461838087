/**
 * When handling authentication in an Angular app, it’s generally best to put everything you need in a dedicated
 * service. Any authentication service should have a few basic methods for allowing users to log in and log out. It
 * should also include a method for retrieving a JSON Web Token from wherever it is stored on the client and a way to
 * determine if the user is authenticated or not.
 *  * Exception Case:
 *  - Will handle all common backend error.
 *  - Will help handle the Frontend behavior in general case.
 *  - Set the timeout each request - 10 seconds
 *  - Retry - There are times when you might want to retry a failed request.
 *    For example, if the user is offline you might want to retry a few times or indefinitely.
 *  - Return the object {ok: false, status: error.status, error: error}
 *  --> More config from Constant File
 * Successful Case:
 *  - Return the object {ok: true, status: response.status, data: data}.
 */
/**
 * 3rd parties
 */
import { isArray, camelCase, map, isObject } from 'lodash';
import { Observable, of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
/**
 * Angular Cores
 */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
/**
 * App Cores
 */
import { Common } from '../services/common.service';
import { Configuration, VALIDATOR } from '../constants';
import { environment } from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private apiConfig = Configuration.API_CONFIG;
  private notUseTokenUrls = [
    Configuration.API_CONFIG.API_URL.AUTH,
    Configuration.API_CONFIG.API_URL.TOKEN_REFRESH
  ];


  /**
   * @ignore
   */
  constructor(
    private common: Common) {

  }

  /**
   * @ignore
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = `${environment.apiUrl}api/${request.url}`;
    // skip request for i18n translation JSON files
    if (url.indexOf(this.apiConfig.API_URL_SKIP.LANGUAGE_URL) > -1) {
      return next.handle(request);
    }

    if (url.indexOf('localhost') !== -1) {
      return of(new HttpResponse({ status: 200, body: { token: '123' } }));
    }
    // get the apiURL according to the environment
    let newData = {
      url,
      setHeaders: {},
      // body: this.handleRequestData(request.body),
      body: request.body
    };
    if (request && request.body && (request.body instanceof FormData)) {
      newData = {
        url,
        setHeaders: {},
        body: request.body
      };
    }

    // add authorization header with jwt token if available
    const credentials = this.common.getCredentials();

    if (credentials.accessToken && this.notUseTokenUrls.indexOf(request.url) === -1) {
      newData.setHeaders = { Authorization: `Bearer ${credentials.accessToken}` };
    }

    request = request.clone(newData);

    // tslint:disable-next-line:no-angle-bracket-type-assertion
    return <any> next.handle(request)
      .pipe(
        timeout(Configuration.TIMEOUT),
        catchError(this.common.handleError.bind(this.common))
      );
  }

  /**
   * Handle the response successful
   * @param data: any
   * @return <any>
   */
  public handleRequestData(data: any): any {
    if (!data) {
      return data;
    }
    if (!isArray(data)) {
      data = this.processCamel(data, true);
    } else {
      data = data.map((item) => {
        return this.processCamel(item, true);
      });
    }
    return data;
  }

  /**
   * Handle the camel or underscore data
   * @param data: any
   * @param isCamel: boolean
   * @return <any>
   */
  private processCamel(data: any, isCamel: boolean): any {
    const keyValues = Object.keys(data).map(key => {
      let newKey = '';
      if (isCamel) {
        newKey = camelCase(key);
      } else {
        newKey = key.replace(VALIDATOR.PATTERN_CHECK_CAMEL_CHARACTER, VALIDATOR.PATTERN_CAMEL_REPLACE_VALUE).toLowerCase();
      }
      if (isArray(data[key])) {
        const dataArray = map(data[key]).map(item => {
          if (item !== null && isObject(item)) {
            return this.processCamel(item, isCamel);
          }
          return item;
        });
        return { [newKey]: dataArray };
      } else if (isObject(data[key])) {
        return { [newKey]: this.processCamel(data[key], isCamel) };
      } else {
        return { [newKey]: data[key] };
      }
    });
    return Object.assign({}, ...keyValues);
  }
}

