<header class="top-header">
  <div class="left-wrapper">
    <img src="assets/images/logo.png" alt="GlencoreLogo" width="144">
    <ul class="top-menu">
      <li>
        <a class="menu" routerLink="/{{routePath.BUILDINGS}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Serviceaufträge' | translate }}</span>
        </a>
      </li>
      <li>
        <a class="menu" routerLink="/{{routePath.DEVICES}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Benutzerverwaltung' | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="right-wrapper">
    <img src="assets/images/Electrolux-Logo.png" alt="Electrolux Logo" class="h-40">
    <div class="user-wrapper">
      <img src="assets/images/user-avatar.jpg" class="user-avatar img-circle" alt="User">
      <div class="user-info">
        <div class="name">
          John Patrick Adelhardt
        </div>
        <div class="type">
          Administrator
        </div>
      </div>
      <div class="dropdown cursor">
        <i class="fas fa-chevron-down" data-toggle="dropdown"></i>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li><a href="#" (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>