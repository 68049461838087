import * as Validator from 'validatorjs';
import * as moment from 'moment';
import { Configuration, CONTROL_PROTOCOL_TYPE } from '../constants';

export class ControlProtocol {
  /**
   * user id
   */
  public id: number;
  /**
   * Device id
   */
  public device_id: number;
  /**
   * current date
   */
  public date_of_control: string;
  /**
   * defects
   */
  public defects: any;
  /**
   * service team
   */
  public service_team: any;
  /**
   * order
   */
  public orders: string;
  /**
   * contact person
   */
  public person_contact: any;
  /**
   * add_to_calendar
   */
  public add_to_calendar: boolean;
  /**
   * appointment
   */
  public date_of_appointment: string;
  /**
   * note
   */
  public note: string;
  /**
   * type
   */
  public add_todo: boolean;
  /**
   * done_by
   */
  public done_by: any;
  /**
   * Date of Signature
   */
  public date_of_signature: string;
  /**
   * inform_days
   */
  public inform_days: number;


  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.device_id = data.device_id || 0;
    this.date_of_control = data.date_of_control ? moment.utc(data.date_of_control).format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE) : moment().format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE);
    this.defects = data.defects || null;
    this.service_team = data.service_team || null;
    this.orders = data.orders || null;
    this.person_contact = data.person_contact || null;
    this.date_of_appointment = data.date_of_appointment || null;
    this.note = data.note || '';
    this.done_by = data.done_by || '';
    this.date_of_signature = data.date_of_signature || null;
    this.inform_days = data.inform_days || null;
    this.add_todo = data.add_todo === false ? false : true;
    this.add_to_calendar = data.add_to_calendar || false;
    if (this.add_to_calendar) {
      this.date_of_appointment = data.date_of_appointment ? moment.utc(data.date_of_appointment).format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE) : moment().format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE);
    } else {
      this.date_of_appointment = null;
    }
    if (!this.add_todo) {
      this.date_of_signature = data.date_of_signature ? moment.utc(data.date_of_signature).format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE) : moment().format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE);
    } else {
      this.date_of_signature = null;
    }
  }

  /**
   * Validate model when create or update
   */
  validate(field = null): any {
    const rules = {
      date_of_control: 'required',
      service_team: 'required',
      person_contact: 'required',
      inform_days: 'integer'
    };

    if (this.add_todo === CONTROL_PROTOCOL_TYPE.WORK_COMPLETE) {
      // tslint:disable-next-line:no-string-literal
      rules['date_of_signature'] = 'required';
      // tslint:disable-next-line:no-string-literal
      rules['done_by'] = 'required';
    }
    if (this.add_to_calendar) {
      // tslint:disable-next-line:no-string-literal
      rules['date_of_appointment'] = 'required';
    }

    const validation = new Validator(this, rules, {
      'required.date_of_control': 'CANNOT_BE_BLANK',
      'required.service_team': 'CANNOT_BE_BLANK',
      'required.person_contact': 'CANNOT_BE_BLANK',
      'required.date_of_appointment': 'CANNOT_BE_BLANK',
      'required.done_by': 'CANNOT_BE_BLANK',
      'required.date_of_signature': 'CANNOT_BE_BLANK',
      'integer.inform_days': 'ONLY_NUMBER_IS_ALLOWED'
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
