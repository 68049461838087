import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BuildingComponent } from './building/building.component';
import { ComponentsModule } from '../components/components.module';
import { SettingsComponent } from './settings/settings.component';
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { BuildingDetailComponent } from './building/building-detail/building-detail.component';
import { FunctionsComponent } from './functions/functions.component';
import { FunctionDetailComponent } from './functions/function-detail/function-detail.component';

import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventDetailComponent } from './event-calendar/event-detail/event-detail.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceDetailComponent } from './devices/device-detail/device-detail.component';
import { ServiceTeamComponent } from './service-team/service-team.component';
import { ServiceTeamDetailComponent } from './service-team/service-team-detail/service-team-detail.component';
import { AlarmComponent } from './alarm/alarm.component';
import { DeviceDetailTabsComponent } from './devices/device-detail-tabs/device-detail-tabs.component';
import { InfoTabComponent } from './devices/device-detail-tabs/info-tab/info-tab.component';
import { DocumentsTabComponent } from './devices/device-detail-tabs/documents-tab/documents-tab.component';
import { ServiceTabComponent } from './devices/device-detail-tabs/service-tab/service-tab.component';
import { ControlTabComponent } from './devices/device-detail-tabs/control-tab/control-tab.component';
import { AlarmsTabComponent } from './devices/device-detail-tabs/alarms-tab/alarms-tab.component';
import { TaskComponent } from './task/task.component';
import { TaskDetailComponent } from './task/task-detail/task-detail.component';
import { AlarmDetailComponent } from './alarm/alarm-detail/alarm-detail.component';
import { DocumentDetailComponent } from './devices/device-detail-tabs/documents-tab/document-detail/document-detail.component';
import { ServiceDetailComponent } from './devices/device-detail-tabs/service-tab/service-detail/service-detail.component';
import { DeviceControlDetailComponent } from './devices/device-control-detail/device-control-detail.component';

@NgModule({
    declarations: [
        DashboardComponent,
        LoginComponent,
        PageNotFoundComponent,
        SettingsComponent,
        BuildingComponent,
        UsersComponent,
        UserDetailComponent,
        BuildingDetailComponent,
        FunctionsComponent,
        FunctionDetailComponent,
        EventCalendarComponent,
        EventDetailComponent,
        DevicesComponent,
        DeviceDetailComponent,
        ServiceTeamComponent,
        ServiceTeamDetailComponent,
        AlarmComponent,
        DeviceDetailComponent,
        DeviceDetailTabsComponent,
        InfoTabComponent,
        DocumentsTabComponent,
        ServiceTabComponent,
        ControlTabComponent,
        AlarmsTabComponent,
        TaskComponent,
        TaskDetailComponent,
        AlarmDetailComponent,
        DocumentDetailComponent,
        ServiceDetailComponent,
        DeviceControlDetailComponent
    ],
    imports: [
        TranslateModule,
        ComponentsModule,
        NgbModalModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        FormsModule
    ],
    exports: [
        DashboardComponent,
        LoginComponent,
        PageNotFoundComponent,
        BuildingComponent,
        SettingsComponent,
        UsersComponent,
        FunctionsComponent,
        FunctionDetailComponent
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class ScreensModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ScreensModule,
            providers: []
        };
    }
}
