import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Common } from '../services/common.service';
import { Configuration } from '../constants';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private common: Common
  ) { }

  canActivate() {
    try {
      // TODO: check auth here
      const credentials = this.common.getCredentials();

      if (!credentials.connected) {
        this.common.navigate(`/${Configuration.ROUTE_PATH.LOGIN}`);
        return false;
      }
    } catch (e) {
      this.common.navigate(`/${Configuration.ROUTE_PATH.LOGIN}`);
      return false;
    }
    return true;
  }
}
