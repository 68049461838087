export class FieldError {
  /**
   * isInvalid - field is invalid
   */
  public isInvalid: boolean;
  /**
   * message - field error message
   */
  public message: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.isInvalid = data.isInvalid || false;
    this.message = data.message || '';
  }
}
