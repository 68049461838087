import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-glc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  private isChecked = false;
  private disableValue = false;
  private displayText = '';

  @Input() name = '';
  // 'before' | 'after'
  @Input() labelPosition = 'before';

  // text (2-ways binding)
  @Output() textChange = new EventEmitter();
  @Input()
  get text() {
    return this.displayText;
  }
  set text(val) {
    this.displayText = val;
    this.textChange.emit(this.displayText);
  }

  // isChecked (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.isChecked;
  }
  set value(val) {
    this.isChecked = val;
    this.valueChange.emit(this.isChecked);
  }

  // isDisabled (2-ways binding)
  @Output() isDisabledChange = new EventEmitter();
  @Input()
  get isDisabled() {
    return this.disableValue;
  }
  set isDisabled(val) {
    this.disableValue = val;
    this.isDisabledChange.emit(this.disableValue);
  }

  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }

}
