import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldError } from '../../models';

@Component({
  selector: 'app-glc-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  private inputValue;
  private rowsValue = 20;
  private disableValue = false;
  private displayPlaceholder = '';
  private displayTitle = '';
  private matIconDisplay = '';

  @Input() error: FieldError;
  @Input() inputFormControl: FormControl = new FormControl('', []);
  @Input() isRequired = false;
  @Input() isHideRequireMarker = false;
  @Input() floatLabel = 'always';
  @Input() name = '';
  @Input() maxlength = 500;

  // title (2-ways binding)
  @Output() titleChange = new EventEmitter();
  @Input()
  get title() {
    return this.displayTitle;
  }
  set title(val) {
    this.displayTitle = val;
    this.titleChange.emit(this.displayTitle);
  }

  // placeholder (2-ways binding)
  @Output() placeholderChange = new EventEmitter();
  @Input()
  get placeholder() {
    return this.displayPlaceholder;
  }
  set placeholder(val) {
    this.displayPlaceholder = val;
    this.placeholderChange.emit(this.displayPlaceholder);
  }

   // rows (2-ways binding)
   @Output() rowsChange = new EventEmitter();
   @Input()
   get rows() {
     return this.rowsValue;
   }
   set rows(val) {
     this.rowsValue = val;
     this.rowsChange.emit(this.rowsValue);
   }

  // value (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.inputValue;
  }
  set value(val) {
    this.inputValue = val;
    this.valueChange.emit(this.inputValue);
  }

  // isDisabled (2-ways binding)
  @Output() isDisabledChange = new EventEmitter();
  @Input()
  get isDisabled() {
    return this.disableValue;
  }
  set isDisabled(val) {
    this.disableValue = val;
    this.isDisabledChange.emit(this.disableValue);
  }

  // matIcon (2-ways binding)
  @Output() matIconChange = new EventEmitter();
  @Input()
  get matIcon() {
    return this.matIconDisplay;
  }
  set matIcon(val) {
    this.matIconDisplay = val;
    this.matIconChange.emit(this.matIconDisplay);
  }


  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() keydownEvent: EventEmitter<any> = new EventEmitter();
  @Output() enter: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }
  public onKeydownEvent(event) {
    this.keydownEvent.emit(event);
  }
  public enterEvent(event) {
    this.enter.emit(event);
  }
}
