import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from '../constants';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DeviceServiceService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get all device services
   * @param options: page, size, key
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.DEVICE_SERVICE, params, showLoader);
  }

  /**
   * Get device service by ID
   * @param id: Device service ID
   * @return Observable
   */
  public getByID(id) {
    return this.apiService.restGET(`${this.apiUrl.DEVICE_SERVICE}/${id}`);
  }

  /**
   * Get next device service
   * @param id: Device ID
   * @return Observable
   */
  public getNext(id) {
    return this.apiService.restGET(`${this.apiUrl.DEVICE_SERVICE}/${id}/next`);
  }

  /**
   * Get document info by device ID
   * @param options: page, size, key
   * @param id: DEVICE ID
   * @return Observable
   */
  public getByDeviceID(options: any, id) {
    const params = options || null;
    return this.apiService.restGET(`${this.apiUrl.DEVICE_SERVICE}/${id}/follow-up`, params);
  }

  /**
   * Create device service
   * @param params: any
   * @return Observable
   */
  public create(params: any) {
    return this.apiService.restPOST(this.apiUrl.DEVICE_SERVICE, params);
  }

  /**
   * Update device service
   * @param id: device id
   * @param params: any
   * @return Observable
   */
  public update(id, params: any) {
    return this.apiService.restPATCH(`${this.apiUrl.DEVICE_SERVICE}/${id}`, params);
  }
}
