<app-glc-page-content
  [pageTitle]="'SERVICE_TEAMS'"
  [pageDescription]="pageDescription"
>
  <div class="function-bar sv-team-bar">
    <button
      *ngIf="isAdmin && tableData.dataSource.length > 0"
      class="mat-stroked-button btn"
      (click)="getExportData()"
    >
      <i class="fa fa-print"></i>
      {{ 'PRINT' | translate }}
    </button>
    <button
      *ngIf="isAdmin"
      class="mat-stroked-button btn"
      (click)="addNew()"
    >
      <i class="fa fa-plus"></i>
      {{ 'ADD_NEW_TEAM' | translate }}
    </button>
    <app-glc-glsearch [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  </div>
  <app-glc-table
    [(data)]="tableData"
    (page)="pageChange($event)" 
    (sort)="sortChange($event)"
    [(hasDelete)]="isAdmin"
    (handleDeleteItem)="deleteDevices($event)"
    [(showMultipleSelect)]="multipleSelect"
  >
  </app-glc-table>
</app-glc-page-content>
