import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Configuration } from '../constants';
import { ApiService } from './api.service';
import { ServiceTeam } from '../models';
import { Common } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceTeamService {

  apiUrl = Configuration.API_CONFIG.API_URL.SERVICE;

  constructor(
    private common: Common,
    private apiService: ApiService
  ) { }

  /**
   * getList
   * @param  options
   * @param {boolean} showLoader
   * @return  Observable
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl, params, showLoader);
  }

  /**
   * getExportData
   * @param  options
   * @param {boolean} showLoader
   * @return  Observable
   */
  public getExportData(search: string = '', showLoader: boolean = true) {
    return this.apiService.restGetFile(`${this.apiUrl}/export`, {search}, showLoader).pipe(
      map( ( response: any ) => {
        if ( response && response.blob ) {
          this.common.handleDownloadFile( response, 'SERVICE_TEAM' );
          return true;
        }
        return false;
      } )
    );
  }

  /**
   * create
   * @param {ServiceTeamModel} model
   * @return  Observable
   */
  public create(model: ServiceTeam) {
    return this.apiService.restPOST(this.apiUrl, model);
  }

  /**
   * update
   * @param {ServiceTeamModel} model
   * @return  Observable
   */
  public update(model: ServiceTeam) {
    return this.apiService.restPATCH(`${this.apiUrl}/${model.id}`, model);
  }

  /**
   * details
   * @param  id
   * @return  Observable
   */
  public details(id: number) {
    return this.apiService.restGET(`${this.apiUrl}/${id}`);
  }

  /**
   * delete
   * @param  id
   * @return  Observable
   */
  public delete(param) {
    return this.apiService.restDELETE(Configuration.API_CONFIG.API_URL.SERVICE_DELETE, param);
  }
}
