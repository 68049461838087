import { environment } from '../../environments/environment';

const VERSION = 1;

export const Configuration = {
  VERSION,
  CURRENT_WINDOWS_NAME: 'Glencore_' + new Date().getTime(),
  APP_VERSION: '0.3.050919',
  HTTP_CONTENT: {
    CONTENT_TYPE: 'Content-Type',
    ACCEPT: 'Accept',
    APPLICATION_JSON: 'application/json',
    SESSION_ID: 'sessionId',
    CACHE_CONTROL: 'Cache-Control',
    NO_CACHE: 'no-cache',
    PRAGMA: 'Pragma',
    AUTHORIZATION: 'Authorization',
    AUTHORIZATION_TYPE: 'Bearer',
    ACCEPT_LANGUAGE: 'Accept-Language',
  },
  API_CONFIG: {
    API_URL: {
      AUTH: 'system/token-auth',
      FORGOT_PASSWORD: 'system/forgot-password',
      CHANGE_PASSWORD: 'system/change-password',
      TOKEN_REFRESH: 'system/token-refresh',
      BUILDING: 'building',
      USERS: 'users',
      USERS_DELETE: 'users/bulk-delete',
      USERS_CHANGE_SETTING: 'users/changeSetting',
      FUNCTIONS: 'function',
      SERVICE: 'service-team',
      SERVICE_DELETE: 'service-team/bulk-delete',
      ALARM: 'alarm-histories',
      TODO: 'todo',
      DEVICE: 'device',
      DEVICE_DELETE: 'device/bulk-delete',
      DOCUMENT: 'document',
      DOCUMENT_DELETE: 'document/bulk-delete',
      DEVICE_SERVICE: 'device-service',
      DEVICE_CONTROL: 'device-control',
      CALENDAR: 'calendar',
      DOCUMENT_CATEGORIES: 'document-categories'
    },
    API_URL_SKIP: {
      LANGUAGE_URL: '/assets/i18n'
    },
    API_REQUEST_TIMEOUT: 300000,
    API_INTERVAL_TIMEOUT: 60000,
    API_RETRY_TIMEOUT: 3000,
    API_REQUEST_HEADER: {
      CACHE_CONTROL: 'Cache-Control',
      PRAGMA: 'Pragma',
      ENVIRONMENT: 'Environment',
      LANGUAGE: 'Accept-Language'          // Header of language key from FE side send to BE side for each request
    },
    INTERVAL_LOCAL_STORAGE_TIMEOUT: 1000,
    LOCAL_STORAGE_RETRY: 1000,
    REQUEST_ERROR_NAME: {
      TIMEOUT_ERROR: 'TimeoutError',      // request timeout
      HTTP_RESPONSE_ERROR: 'HttpErrorResponse'  // Http failure response for (unknown url): 0 Unknown Error
    }
  },
  TIMEOUT: 30000,
  LOADING_TIMEOUT: 10,
  REFRESH_TOKEN_INTERVAL: 2 * 60 * 1000,
  COOKIE: {
    TIMEOUT: 4 * 60 * 60 * 1000,
    ACCESS_TOKEN: 'accessToken'
  },

  DATE_TIME_FORMAT_NO_PIPE: 'DD.MM.YYYY HH:mm',
  DATE_TIME_FORMAT: 'DD.MM.YYYY | HH:mm',
  FULL_DATE_TIME_FORMAT: 'DD.MM.YYYY | HH:mm:ss',
  DATE_FORMAT: 'DD.MM.YYYY',
  DATE_SERVER_FORMAT: 'YYYY-MM-DD',
  TIME_FORMAT: 'HH:mm:ss',
  TIME_FORMAT_WITHOUT_SECOND: 'HH:mm',
  DATE_TIME_FORMAT_FOR_CALENDAR_VALUE: 'DD.MM.YYYY HH:mm',
  DATE_TIME_FORMAT_FOR_CALENDAR: 'd.m.Y H:i',
  DATE_TIME_MASK_FOR_CALENDAR: '31.12.9999 23:59',
  DATE_TIME_FORMAT_SERVER: 'YYYY-MM-DD HH:mm',

  pageSize: 30,
  visiblePages: 10,

  HTTP_RESPONSE_CODE: {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
    BAD_REQUEST: 400,
    INTERNAL_SERVER_ERROR: 500
  },
  JSON_ERROR_CODE: {
    // Clean credential data in device and back to login screen
    AUTH_INVALID: 800,
    // Clean credential data in device and back to login screen
    AUTH_MISSING_DATA: 801,
    // Display error message in device
    TOKEN_FAILED: 802,
    // Call refresh token api to get new token and recall API
    TOKEN_EXPIRED: 803
  },

  APP_SESSION_TIMEOUT: 60 * 10,
  API_RETRY_TIMER: 3000,

  VALIDATION_PATTERNS: {
    REQUIRED: 'required',
    FLOAT: 'float',
    BOOLEAN: 'boolean',
    EMAIL: 'email',
    ARRAY: 'array',
    PHONE: 'phone',
    DATE: 'date'
  },

  LIST_SUPPORTED_LANGS: [
    {
      key: 'en',
      value: 'English'
    },
    {
      key: 'de',
      value: 'Deutsch'
    }
  ],

  WATCHDOG_FIELDS: {
    channel: 'channel',
    timeout: 'timeout'
  },

  TOASTER_CONFIG: {
    newestOnTop: false,
    showCloseButton: true,
    tapToDismiss: false,
    limit: 5,
    preventDuplicates: true,
    timeout: {
      error: 10000,
      warning: 10000,
      success: 10000
    }
  },

  LOCAL_STORAGE_KEY: {
    LANGUAGE_UI: 'UI_language',
    LANGUAGE: 'language',
    LOGIN_ACTION: 'loginAction',
    USER_INFO: 'userInfo',
    CREDENTIALS: 'prototype_credentials',
    TOKEN: 'token'
  },
  ENCRYPT_TEXT_KEY: 'ab975de9294067470d1684442555767fcb007c5a3b89927714e449c3f66cb2a4',
  ENCRYPT_IV_KEY: '9AAECFCF7E82ABB8118D8E567D42EE86',
  ROUTE_PATH: {
    LOGIN: 'login',
    BUILDINGS: 'buildings',
    FUNCTIONS: 'functions',
    DEVICES: 'devices',
    ALARMS: 'alarms',
    SERVICE_TEAMS: 'service-teams',
    USERS: 'users',
    SETTINGS: 'settings',
    TO_DO_LIST: 'to-do-list',
    PAGE_NOT_FOUND: '404',
    EVENT_CALENDAR: 'event-calendar',
    DEVICE_INFO_TAB: 'info',
    DEVICE_DOCUMENTS_TAB: 'documents',
    DEVICE_SERVICE_TAB: 'service',
    DEVICE_CONTROL_TAB: 'control',
    DEVICE_ALARMS_TAB: 'alarms'
  },
  EVENT_CALENDAR: {
    COLORS: {
      GREENS: {
        primary: '#00afaa',
        secondary: '#00fcf4'
      },
    },
    ACTIONS: {
      ADD: 1,
      EDIT: 2
    }
  },
  SORT_DIRECTION: {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC'
  },
  CONFIRM_DIALOG: {
    WIDTH: '500px'
  },
  CALENDAR: {
    TYPE: {
      SERVICE: 0,
      CONTROL: 1
    }
  }
};

export const CAPTCHA = {
  SITE_KEY: environment.siteKey,
  REQUEST_TIME_OUT: 10000
};

export const TOASTER_TYPE = {
  warning: 'warning',
  error: 'error',
  success: 'success'
};

export const USER_ROLE = {
  ADMINISTRATOR: 1,
  SUPER_USER: 2,
  STANDARD_USER: 3
};

export const SERVICE_TYPE = {
  EXTERNAL_SERVICE: 'E',
  INTERNAL_SERVICE: 'I'
};

export const FILE_TYPE = {
  BUILDING: '.png,.jpg,.jpeg',
  DEFAULT: '.png,.jpg,.gif,.pdf,.txt,.doc,.docx,.xls,.xlsx',
  SERVICE: '.png,.jpg,.jpeg',
};

export const TASK_CATEGORY = {
  GENERAL: 1,
  CONTROL: 2,
  SERVICE: 3
};

/**
 * Confirmation modal configuration
 */
export const CONFIRM_MODAL_OPTIONS = {
  TYPE: {
    DEFAULT: 'default',
    BLUE: 'blue',
    GREEN: 'green',
    RED: 'red',
    ORANGE: 'orange',
    PURPLE: 'purple',
    DARK: 'dark'
  },
  YES_BUTTON_TEXT: 'YES',
  NO_BUTTON_TEXT: 'NO',
  TITLE_TEXT: 'CONFIRM',
  BODY_TEXT: '',
  ICON_HEADER_CLASS: 'mdi mdi-exclamation',
  CLICK_TYPE: {
    SAVE_CLICK: 'save click',
    NO_CLICK: 'no click',
    CROSS_CLICK: 'cross click'
  }
};

export const RESPONSE_TYPE = {
  Text: 0,
  Json: 1,
  ArrayBuffer: 2,
  Blob: 3
};

export const CONTROL_PROTOCOL_TYPE = {
  TODO: true,
  WORK_COMPLETE: false
};

export const TO_DO_TYPE = {
  FINISHED: 1,
  OPEN: 2
};
