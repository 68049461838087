import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FieldError } from 'src/app/models';

@Component({
  selector: 'app-glc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  private matIconDisplay = '';
  private displayTitle = '';
  private selectedItemValue: any;
  private disableValue = false;
  private data: any[] = [];
  private displayPlaceholder = '';

  @Input() error: FieldError;
  @Input() name = '';
  @Input() isRequired = false;
  @Input() floatLabel = 'always';
  @Input() valueKey = 'id';
  @Input() textKey = 'text';
  @Input() isHideRequireMarker = false;

  // matIcon (2-ways binding)
  @Output() matIconChange = new EventEmitter();
  @Input()
  get matIcon() {
    return this.matIconDisplay;
  }
  set matIcon(val) {
    this.matIconDisplay = val;
    this.matIconChange.emit(this.matIconDisplay);
  }

  // title (2-ways binding)
  @Output() titleChange = new EventEmitter();
  @Input()
  get title() {
    return this.displayTitle;
  }
  set title(val) {
    this.displayTitle = val;
    this.titleChange.emit(this.displayTitle);
  }

  // selectedItem (2-ways binding)
  @Output() selectedItemChange = new EventEmitter();
  @Input()
  get selectedItem() {
    return this.selectedItemValue;
  }
  set selectedItem(val) {
    this.selectedItemValue = val;
    this.selectedItemChange.emit(this.selectedItemValue);
  }

  // isDisabled (2-ways binding)
  @Output() isDisabledChange = new EventEmitter();
  @Input()
  get isDisabled() {
    return this.disableValue;
  }
  set isDisabled(val) {
    this.disableValue = val;
    this.isDisabledChange.emit(this.disableValue);
  }

  // dataSource (2-ways binding)
  @Output() dataSourceChange = new EventEmitter();
  @Input()
  get dataSource() {
    return this.data;
  }
  set dataSource(val) {
    this.data = val;
    this.dataSourceChange.emit(this.data);
  }

  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();

  // placeholder (2-ways binding)
  @Output() placeholderChange = new EventEmitter();
  @Input()
  get placeholder() {
    return this.displayPlaceholder;
  }
  set placeholder(val) {
    this.displayPlaceholder = val;
    this.placeholderChange.emit(this.displayPlaceholder);
  }

  constructor() { }

  ngOnInit() {
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }

}
