import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { VALIDATOR } from '../constants';
import { FieldError } from '../models';

@Injectable()
export class Validator {
  /**
   * validate email by using regex
   * @param value: a string to validate
   * @return {boolean} if string is email, value will be true, false if it is not
   */
  public validateEmail( value: string ) {
    const re = VALIDATOR.PATTERN_EMAIL;
    return re.test( value );
  }

  /**
   * validate if value is filled
   * @param value: a string to validate
   * @return {boolean} if string is filled, value will be true, false if it is not
   */
  public validateRequired( value: string ) {
    return !!value;
  }

  /**
   * handle the validate for input Password
   * @param value: a string to validate
   * @return FieldError
   */
  public validatePassword( value: string ): FieldError {
    const validateObject = new FieldError();
    const isValid = this.validateRequired(value);

    if (!isValid) {
      validateObject.isInvalid = true;
      validateObject.message = 'PASSWORD_IS_REQUIRED';
    } else {
      if (value.length < VALIDATOR.PASSWORD.MIN) {
        validateObject.isInvalid = true;
        validateObject.message = 'PASSWORD_LENGTH_MUST_BE_LONGER';
      }
    }

    return validateObject;
  }

  /**
   * handle the validate for input Name
   * @param value: a string to validate
   * @return FieldError
   */
  public validateName( value: string ): FieldError {
    const validateObject = new FieldError();
    const isValid = this.validateRequired(value);

    if (!isValid) {
      validateObject.isInvalid = true;
      validateObject.message = 'NAME_IS_REQUIRED';
    } else {
      if (value.length < VALIDATOR.NAME.MIN) {
        validateObject.isInvalid = true;
        validateObject.message = 'NAME_MUST_BE_LONGER';
      }
    }

    return validateObject;
  }

  /**
   * handle the validate for input Name
   * @param value: a string to validate
   * @return FieldError
   */
  public validateTelephone( value: string ): FieldError {
    const validateObject = new FieldError();
    const isValid = this.validateRequired(value);

    if (!isValid) {
      validateObject.isInvalid = true;
      validateObject.message = 'TELEPHONE_IS_REQUIRED';
    }

    return validateObject;
  }

  /**
   * Validate data
   * @param {string} [field=null]
   * @param {*} data
   * @param {*} errors
   * @param {boolean} editing
   * @memberof Validator
   */
  validate(field: string = null, data: any, errors: any, editing = false) {
    if (field) {
      const message = data.validate(field, editing);
      if (message) {
        errors[field].isInvalid = true;
        errors[field].message = message;
      } else {
        errors[field].isInvalid = false;
      }
    } else {
      const errorMessages = data.validate();
      console.log(errorMessages);
      _.forEach(errorMessages.errors, (message, key) => {
        if (errors[key]) {
          errors[key].isInvalid = true;
          errors[key].message = message.join(', ');
        } else {
          errors[key].isInvalid = false;
        }
      });
    }
  }
}
