import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Common } from '../services/common.service';

@Injectable()
export class AdminSuperUserGuard implements CanActivate {

  constructor(
    private common: Common
  ) { }

  canActivate() {
    try {
      // TODO: check auth here
      const isAdminOrSuperUser = this.common.isAdminOrSuperUser();

      if (!isAdminOrSuperUser) {
        this.common.navigate('');
        return false;
      }
    } catch (e) {
      this.common.navigate('');
      return false;
    }
    return true;
  }
}
