<app-glc-page-details
  [pageTitle]="'ALARM_DETAILS'"
  [pageDescription]="'ALARM_DETAILS_DESCRIPTION'"
  [backBtnText]="'BACK'"
  (backPress)="backPage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          [title]="'BUILDING'"
          [(value)]="alarm.building_name"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'FUNCTION'"
          [(value)]="alarm.function_name"
          isDisabled
        ></app-glc-input>
        <app-glc-input
        [title]="'DEVICE'"
        [(value)]="alarm.device_name"
        isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'DEACTIVATED_BY'"
          [(value)]="alarm.deactivated_by"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'DATE'"
          [(value)]="alarm.date"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'TIME'"
          [(value)]="alarm.time"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'COMMENT'"
          [(value)]="alarm.comment"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'ALARM_TYPE'"
          [(value)]="alarm.alarm_type"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'ALARM_GROUP'"
          [(value)]="alarm.alarm_group"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'ALARM_MESSAGE'"
          [(value)]="alarm.alarm_message"
          isDisabled
        ></app-glc-input>
      </div>
    </div>
  </div>
</app-glc-page-details>
  