export class FunctionModel {
  /**
   * id - function id
   */
  public id: number;
  /**
   * name - function name
   */
  public name: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.name = data.name || '';
  }
}
