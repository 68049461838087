import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Login } from '../models';
import { Configuration } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * login
   * @param  loginInfo: Login
   * @param  recaptcha: string
   * @return  Observable
   */
  public login(loginInfo: Login, recaptcha, showLoader = true) {
    const params = {
      email: loginInfo.email,
      password: loginInfo.password,
      recaptcha
    };
    return this.apiService.restPOST(this.apiUrl.AUTH, params, showLoader);
  }

  /**
   * forgotPassword
   * @param  form: forgot password form
   * @param  recaptcha: string
   * @return  Observable
   */
  public forgotPassword(form, recaptcha) {
    const params = {
      email: form.email,
      recaptcha
    };
    return this.apiService.restPOST(this.apiUrl.FORGOT_PASSWORD, params);
  }

  /**
   * resetPassword
   * @param  form: reset password form
   * @param  recaptcha: string
   * @return  Observable
   */
  public resetPassword(form, recaptcha) {
    const params = {
      recaptcha,
      email: form.email,
      new_password: form.password,
      forgot_password_code: form.code
    };
    return this.apiService.restPOST(this.apiUrl.CHANGE_PASSWORD, params);
  }

  /**
   * resetPassword
   * @param  form: reset password form
   * @param  recaptcha: string
   * @return  Observable
   */
  public refreshToken(token) {
    const params = {
      token
    };
    return this.apiService.restPOST(this.apiUrl.TOKEN_REFRESH, params, false);
  }
}
