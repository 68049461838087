<app-glc-page-content
  [pageTitle]="'ALARM_HISTORY'"
  [pageDescription]="'ALARM_HISTORY_DESCRIPTION'"
>
  <div class="function-bar">
    <app-glc-glsearch [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  </div>
  <app-glc-table
    *ngIf="tableData"
    [data]="tableData"
    (page)="pageChange($event)"
    (sort)="sortChange($event)"
  ></app-glc-table>
</app-glc-page-content>
