<label class="tab-label">{{ 'DEVICE_DETAILS' | translate }}</label>
<div class="mt-20">
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          isRequired
          [title]="'NAME'"
          [placeholder]="'DEVICE_NAME_PLACEHOLDER'"
          [name]="'name'"
          [(value)]="device.name"
          [(error)]="errors.name"
          (modelChangeEvent)="validate('name')"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-input>
        <app-glc-input
          isRequired
          [title]="'DEVICE_ID'"
          [placeholder]="'DEVICE_ID_PLACEHOLDER'"
          [name]="'device_hard_id'"
          [(value)]="device.device_hard_id"
          [(error)]="errors.device_hard_id"
          (modelChangeEvent)="validate('device_hard_id')"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-input>
        <app-glc-input
          [title]="'INSTAGUARD_ID'"
          [placeholder]="'INSTAGUARD_ID_PLACEHOLDER'"
          [name]="'instaguard_id'"
          [(value)]="device.instaguard_id"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-input>
        <app-glc-select-searchable
          [isRequired]="true"
          [title]="'BUILDING'"
          [textKey]="'name'"
          [placeholder]="'PLEASE_SELECT'"
          [dataService]="privateBuildingService"
          [(value)]="device.building_id"
          [(error)]="errors.building_id"
          (modelChangeEvent)="validate('building_id')"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-select-searchable>
        <app-glc-input
          [title]="'LOCATION'"
          [placeholder]="'LOCATION_PLACEHOLDER'"
          [name]="'location'"
          [(value)]="device.location"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-input>
        <app-glc-select
          isRequired
          [title]="'FUNCTION'"
          [placeholder]="'PLEASE_SELECT'"
          [(selectedItem)]="device.function_id"
          [valueKey]="'id'"
          [textKey]="'name'"
          [(dataSource)]="listFunctions"
          [(error)]="errors.function_id"
          (modelChangeEvent)="validate('function_id')"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-select>
        <app-glc-select-searchable
          [title]="'INTERNAL_TEAM'"
          [textKey]="'name'"
          [placeholder]="'PLEASE_SELECT'"
          [dataService]="privateInternalTeamService"
          [(value)]="device.internal_team_id"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-select-searchable>
        <div class="team-info" *ngIf="device.internal_team_id">
          <div class="phone">
              <i class="fas fa-phone-alt"></i> <a href="tel:+{{device.internal_team_id.telephone}}" class="value">{{ device.internal_team_id.telephone }}</a>
          </div>
          <div class="email">
              <i class="far fa-envelope"></i> <a href="mailto:{{device.internal_team_id.email}}" class="value">{{ device.internal_team_id.email }}</a>
          </div>
        </div>
        <app-glc-select-searchable
          [title]="'EXTERNAL_TEAM'"
          [textKey]="'name'"
          [placeholder]="'PLEASE_SELECT'"
          [dataService]="privateExternalTeamService"
          [(value)]="device.external_team_id"
          [(error)]="errors.external_team_id"
          (modelChangeEvent)="validate('external_team_id')"
          [isDisabled]="!isAdminOrSuperUser"
        ></app-glc-select-searchable>
        <div class="team-info" *ngIf="device.external_team_id">
          <div class="phone">
              <i class="fas fa-phone-alt"></i> <a href="tel:+{{device.external_team_id.telephone}}" class="value">{{ device.external_team_id.telephone }}</a>
          </div>
          <div class="email">
              <i class="far fa-envelope"></i> <a href="mailto:{{device.external_team_id.email}}" class="value">{{ device.external_team_id.email }}</a>
          </div>
        </div>
        <button
          *ngIf="isAdminOrSuperUser"
          mat-button
          class="btn btn-primary pull-right"
          (click)="save()"
          [disabled]="isDisabledBtn()"
        >
            {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>            
</div>