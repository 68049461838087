<app-glc-page-details
  [pageTitle]="'FUNCTION_DETAILS'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_FUNCTIONS'"
  (backPress)="backToFunctionPage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          isRequired
          [title]="'NAME'"
          [placeholder]="'NAME'"
          [name]="'name'"
          [(value)]="functionModel.name"
          [isDisabled]="!isAdmin"
          [(error)]="errors.name"
        ></app-glc-input>
        <div *ngIf="isAdmin">
          <button
            mat-button
            class="btn btn-primary pull-right"
            (click)="save()"
            [disabled]="isDisabledBtn()"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-glc-page-details>
  