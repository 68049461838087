import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FieldError } from 'src/app/models';
import { Configuration } from 'src/app/constants';

declare var $;

@Component({
  selector: 'app-glc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  private inputValue;
  private disableValue = false;
  private displayPlaceholder = '';
  private displayTitle = '';
  private id = '';

  @Input() error: FieldError;
  @Input() disabledDates = [];
  @Input() isRequired = false;
  @Input() name = '';

  // title (2-ways binding)
  @Output() titleChange = new EventEmitter();
  @Input()
  get title() {
    return this.displayTitle;
  }
  set title(val) {
    this.displayTitle = val;
    this.titleChange.emit(this.displayTitle);
  }

  // elementId (2-ways binding)
  @Output() elementIdChange = new EventEmitter();
  @Input()
  get elementId() {
    return this.id;
  }
  set elementId(val) {
    this.id = val;
    this.elementIdChange.emit(this.id);
  }

  // placeholder (2-ways binding)
  @Output() placeholderChange = new EventEmitter();
  @Input()
  get placeholder() {
    return this.displayPlaceholder;
  }
  set placeholder(val) {
    this.displayPlaceholder = val;
    this.placeholderChange.emit(this.displayPlaceholder);
  }

  // value (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.inputValue;
  }
  set value(val) {
    this.inputValue = val;
    this.valueChange.emit(this.inputValue);
  }

  // isDisabled (2-ways binding)
  @Output() isDisabledChange = new EventEmitter();
  @Input()
  get isDisabled() {
    return this.disableValue;
  }
  set isDisabled(val) {
    this.disableValue = val;
    this.isDisabledChange.emit(this.disableValue);
  }

  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    const newElementId = this.elementId ? `${this.elementId}-${this.uuidv4()}` : `glc-datepicker-${this.uuidv4()}`;
    this.elementId = newElementId;
    setTimeout(() => {
      $(`#${this.elementId}`).datetimepicker({
        formatDate: 'd.m.Y',
        disabledDates: this.disabledDates,
        format: Configuration.DATE_TIME_FORMAT_FOR_CALENDAR,
        onChangeDateTime: (dp, $input) => {
          this.value = $input.val();
          this.modelChangeEvent.emit();
        }
      });
    }, 300);
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:one-variable-per-declaration
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line:triple-equals
      const v = c == 'x' ? r :
        // tslint:disable-next-line:no-bitwise
        (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  openCalendar() {
    if (this.isDisabled) {
      return;
    }
    $(`#${this.elementId}`).datetimepicker('show');
  }
}
