<app-glc-page-content
  [pageTitle]="'TO_DO_LIST'"
  [pageDescription]="'TO_DO_LIST_DESCRIPTION'"
>
  <div class="function-bar">
    <button
      class="mat-stroked-button btn"
      (click)="add()"
    >
      <i class="fa fa-plus"></i>
      {{ 'CREATE_TASK' | translate }}
    </button>
    <app-glc-glsearch [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  </div>
  <app-glc-table
    *ngIf="tableData"
    [data]="tableData"
    (page)="pageChange($event)"
    (sort)="sortChange($event)"
  ></app-glc-table>
</app-glc-page-content>
