<div class="root">
    <label class="control-title">{{title | translate }}</label>
    <span *ngIf="required" class="requiredSym"> *</span>
    <!-- <mat-label>{{ title | translate }}</mat-label> -->
    <div class="glc-file-input"  
      [ngClass]="{'selected': filesSelected && filesSelected.length, 'error': hasError}"
    >
      <div class="placeholdder" >{{placeHolder | translate}}<a href="#">{{'BROWSE' | translate}}</a></div>
      <input 
        type="file" 
        name="file_control"    
        accept="{{fileTypes}}"
        (change)="handleFileInput($event.target.files)"
        [multiple]="isMultiple"
      >
    </div>
    <div *ngIf="filesSelected && filesSelected.length" class="filesName">
      <ul>
        <li *ngFor="let file of filesSelected; let i = index">
          <a (click)="removeFile(i)"><i class="far fa-times-circle"></i></a>
          ({{getFileSize(file)}}) {{file.name}}
        </li>
      </ul>    
    </div>
    <mat-error *ngIf="hasError">
      {{ error | translate }}
    </mat-error>
</div>
