import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Common } from '../services';

export interface CanComponentDeactivate {
  isChanged: boolean;
}

/**
 * Can-deactivate guard
 * @export
 * @class CanDeactivateGuard
 * @implements {CanDeactivate<CanComponentDeactivate>}
 */
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  /**
   * Creates an instance of CanDeactivateGuard.
   * @param {Common} common
   * @memberof CanDeactivateGuard
   */
  constructor(
    private common: Common
  ) { }

  /**
   * Check before navigate
   * @param {CanComponentDeactivate} component
   * @returns
   * @memberof CanDeactivateGuard
   */
  async canDeactivate(component: CanComponentDeactivate) {
    const credentials = this.common.getCredentials();
    if (!credentials.connected) {
      this.common.closeAllModal();
      return true;
    }
    if (component.isChanged) {
      let result = false;
      const promise = new Promise((resolve, reject) => {
        this.common.showConfirm(
          this.common.translate('WARNING'),
          this.common.translate('CONFIRM_DISCARD_MESSAGE'),
          () => {
            result = true;
            resolve(true);
          }, () => {
            resolve(false);
          }
        );
      });
      await promise;
      return result;
    } else {
      return true;
    }
  }
}
