import * as Validator from 'validatorjs';
import * as _ from 'lodash';
export class Building {
  /**
   * user id
   */
  public id: number;
  /**
   * building name
   */
  public name: string;
  /**
   * location
   */
  public location: string;
  /**
   * street
   */
  public street: string;
  /**
   * zip
   */
  public zipcode: string;
  /**
   * city
   */
  public city: string;
  /**
   * image url
   */
  public image: string;
  /**
   * Functions
   */
  public function: Array<any>;

  public selected: boolean;
  public action: any;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.name = data.name || '';
    this.location = data.location || '';
    this.street = data.street || '';
    this.zipcode = data.zipcode || '';
    this.city = data.city || '';
    this.image = data.image || '';
    this.selected = false;
    if (data.function_list) {
      const arrList = [];
      _.forEach(data.function_list, (value, key) => {
        arrList.push({
          id: key,
          name: value
        });
      });
      this.function = arrList;
    } else {
      this.function = [];
    }
  }

  /**
   * Validate model when create or update
   */
  validate(field = null, editing = false): any {
    const rules = {
      function: 'required',
    };

    const validation = new Validator(this, rules, {
      'required.function': 'FUNCTION_IS_REQUIRED',
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
