import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-glc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {
  private inputValue;
  private disableValue = false;
  private displayTitle = '';

  @Input() floatLabel = 'always';
  @Input() name = '';
  @Input() maxlength = 50;
  @Input() options = [];

  // title (2-ways binding)
  @Output() titleChange = new EventEmitter();
  @Input()
  get title() {
    return this.displayTitle;
  }
  set title(val) {
    this.displayTitle = val;
    this.titleChange.emit(this.displayTitle);
  }

  // value (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.inputValue;
  }
  set value(val) {
    this.inputValue = val;
    this.valueChange.emit(this.inputValue);
  }

  // isDisabled (2-ways binding)
  @Output() isDisabledChange = new EventEmitter();
  @Input()
  get isDisabled() {
    return this.disableValue;
  }
  set isDisabled(val) {
    this.disableValue = val;
    this.isDisabledChange.emit(this.disableValue);
  }

  constructor() { }

  ngOnInit() {
  }

}
