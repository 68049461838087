import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

import { Common, DeviceControlService } from '../../../../services';
import { Configuration } from '../../../../constants';
import { forkJoin } from 'rxjs';
import { ControlProtocol } from 'src/app/models';

@Component({
  selector: 'app-glc-control-tab',
  templateUrl: './control-tab.component.html',
  styleUrls: ['./control-tab.component.scss']
})
export class ControlTabComponent implements OnInit {
  routePath = Configuration.ROUTE_PATH;
  isAdmin = false;
  deviceID: number;
  nextControl = new ControlProtocol();

  // Declare table settings
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private activatedRoute: ActivatedRoute,
    private deviceControlService: DeviceControlService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    try {
      this.deviceID = Number(this.activatedRoute.parent.snapshot.paramMap.get('id'));
    } catch (e) {
      this.backToDevicesPage();
    }
    this.initTable();
    this.initData();
  }

  initTable() {
    this.tableData = {
      displayedColumns: ['date', 'time', 'done_by', 'action'],
      field: [
        {
          name: 'date',
          title: 'Date'
        },
        {
          name: 'time',
          title: 'TIME'
        },
        {
          name: 'done_by',
          title: 'DONE_BY'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: 5,
      dataSource: []
    };
  }

  initData() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue,
    });
    // tslint:disable-next-line:no-string-literal
    options['status'] = 1;
    this.common.showLoader();
    try {
      forkJoin([
        this.deviceControlService.getByDeviceID(options, this.deviceID),
        this.deviceControlService.getNext(this.deviceID)
      ]).subscribe((responses: any) => {
        this.common.hideLoader();
        const results = responses[0].results.map(item => {
          const service = item;
          service.date = moment.utc(service.date_of_control).format(Configuration.DATE_FORMAT);
          service.time = moment.utc(service.date_of_control).format(Configuration.TIME_FORMAT_WITHOUT_SECOND);
          service.done_by = service.done_by;
          service.action = [
            {
              text: 'VIEW',
              icon: 'eye',
              url: `${item.id}`
            }
          ];
          return service;
        });

        this.tableData = {
          ...this.tableData,
          pageIndex: this.pageOffset,
          totalRecords: responses[0].count,
          dataSource: results
        };

        this.nextControl = new ControlProtocol(responses[1]);
        if (responses[1].date_of_control) {
          this.nextControl.date_of_control = moment(this.nextControl.date_of_control, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_FORMAT);
        } else {
          this.nextControl.date_of_control = '';
        }
        this.nextControl.inform_days = this.nextControl.inform_days || 0;
      });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.initData();
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'date':
        this.sortValue.active = 'date_of_control';
        break;
      case 'time':
        this.sortValue.active = 'date_of_control';
        break;
    }
    this.initData();
  }

  doControl() {
    this.common.navigate(`${this.routePath.DEVICES}/${this.deviceID}/${this.routePath.DEVICE_CONTROL_TAB}/create`);
  }

  viewCalendar() {
    this.common.navigate(`${this.routePath.DEVICES}/${this.deviceID}/${this.routePath.DEVICE_CONTROL_TAB}/${this.routePath.EVENT_CALENDAR}`);
  }

  backToDevicesPage() {
    this.common.navigate(this.routePath.DEVICES);
  }
}
