import { ReplaySubject, of, Subscription } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
// Vendors
import * as _ from 'lodash';
import * as CryptoJS from 'crypto-js';
// Angular Core
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BodyOutputType, ToasterService } from 'angular2-toaster';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
// Services
import { LoadingService } from '../components';
// Models
import { Configuration, TOASTER_TYPE, USER_ROLE, CONFIRM_MODAL_OPTIONS, CAPTCHA } from '../constants/app.constants';
import { User, Credential } from '../models';
import { TABLE_SETTINGS } from '../constants';
import { MessageEventService } from './message-event.service';

declare var $;

@Injectable()
export class Common {
  idleState = 'Not started.';
  timedOut = false;
  public currentUser: ReplaySubject<User> = new ReplaySubject<User>(1);
  ENCRYPT_TEXT_KEY = Configuration.ENCRYPT_TEXT_KEY;
  ENCRYPT_IV_KEY = Configuration.ENCRYPT_IV_KEY;

  constructor(
    private translateService: TranslateService,
    private loadingService: LoadingService,
    public dialog: MatDialog,
    private router: Router,
    private idle: Idle,
    private toasterService: ToasterService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private messageEventService: MessageEventService
  ) { }

  private singleExecutionSubscription: Subscription;
  private confirmModal: any = {};

  /**
   * @function closeConfirmDialog
   * @description close confirm dialog
   */
  public closeConfirmDialog() {
    const $confirmDialog = $('#confirm-modal-dialog');
    const $modalBackdrop = $('.modal-backdrop');
    if (
      $confirmDialog &&
      $confirmDialog.length &&
      $modalBackdrop &&
      $modalBackdrop.length
    ) {
      $modalBackdrop.remove();
      $confirmDialog.remove();
    }
  }

  /**
   * @function detectCHROME
   * @description check the current browser is CHROME or not
   * @return true/false | any
   */
  public detectCHROME(): any {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  }

  /**
   * @function detectIE
   * @description check the current browser is IE or not
   * @return true/false | any
   */
  public detectIE(): any {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  /**
   * @function getCredentials
   * @description getting user credentials information from browser local storage
   * @return info object
   */
  public getCredentials(): Credential {
    const info = localStorage.getItem(Configuration.LOCAL_STORAGE_KEY.CREDENTIALS);
    const returnObject = info ? JSON.parse(info) : null;
    return new Credential(returnObject);
  }

  /**
   * @function getInfo
   * @description getting info with appropriate key from browser local storage
   * @param {string} key
   */
  public getInfo(key) {
    const info = localStorage.getItem(key);
    return info ? JSON.parse(info) : info;
  }

  /**
   * @function getUserInfo
   * @description getting user info from browser local storage
   * @return User model
   */
  public getUserInfo(): User {
    const info = localStorage.getItem(
      Configuration.LOCAL_STORAGE_KEY.USER_INFO
    );
    return info ? new User(JSON.parse(info)) : new User();
  }

  parseApiMessage(message) {
    let result = [];
    if (message) {
      if (message instanceof Object) {
        let messages = [];
        const self = this;

        Object.keys(message).forEach(key => {
          if (message[key] instanceof Array) {
            message[key].forEach(item => {
              messages.push(`${key}: ${item}`);
            });
          } else if (message[key] instanceof Object) {
            const data = self.parseApiMessage(message[key]);
            messages = messages.concat(data);
          } else {
            messages.push(`${message[key]}`);
          }
        });
        result = messages;
      } else if (typeof message === 'string') {
        result = [message];
      }
    }
    return result;
  }

  /**
   * @function parseApiError
   * @description handle error case for api retrieval
   * @param {any} errorObj
   * @return errorObj
   */
  parseApiError(errorObj) {
    const errorTmp = errorObj;

    if (errorTmp) {
      const { message, error } = errorObj;

      if (message) {
        errorTmp.message = this.parseApiMessage(message);
      } else if (error) {
        if (typeof error === 'string') {
          errorTmp.message = [error];
        }
      } else {
        const messages = [];

        Object.keys(errorTmp).forEach(key => {
          if (errorTmp[key] instanceof Array) {
            errorTmp[key].forEach(item => {
              messages.push(`${item}`);
            });
          } else {
            messages.push(`${errorTmp[key]}`);
          }
        });
        errorTmp.message = messages;
      }
    }

    return errorObj;
  }

  public handleErrorMessage(error: any) {
    this.hideLoader();
    if (_.isArray(error.message)) {
      this.showError(error.message);
    } else {
      this.showError([this.translate(error.message)]);
    }
  }

  /**
   * @function handleError
   * @description handle error case for api retrieval
   * @param {any} response
   */
  public handleError(response: any) {
    const errorNetwork = {
      code: -1,
      message: 'NETWORK_ERROR'
    };

    if (!response.error) {
      this.handleErrorMessage(errorNetwork);
      return of(errorNetwork);
    }

    const { error, status } = response;

    const serverError = {
      code: 500,
      message: 'INTERNAL_SERVER_ERROR'
    };

    if (_.isString(error)) {
      console.log(error);
      this.handleErrorMessage(serverError);
      return of(serverError);
    }

    if (!_.isObject(error)) {
      this.handleErrorMessage(serverError);
      return of(serverError);
    }

    const newError = this.parseApiError(error);

    if (status === Configuration.HTTP_RESPONSE_CODE.INTERNAL_SERVER_ERROR) {
      this.handleErrorMessage(serverError);
      return of(serverError);
    }
    if (status === Configuration.HTTP_RESPONSE_CODE.UNAUTHORIZED) {
      this.handleErrorMessage(newError);
      this.handleLogoutAction();
      return of(newError);
    }

    this.handleErrorMessage(newError);
    return of(newError);
  }

  /**
   * @function handleLogoutAction
   * @description handle logout action
   * @param {string} url
   */
  public handleLogoutAction(url = '') {
    const credentials = this.getCredentials();
    if (credentials) {
      credentials.connected = false;
      this.storeCredentials(credentials);
    }
    // send message event before logout
    this.stopHandleSessionTimeout();
    this.removeToken();

    this.closeAllModal();
    this.router.navigate([Configuration.ROUTE_PATH.LOGIN]).then(() => {
      this.handleAfterLogoutAction();
    });
  }

  /**
   * @function handleSessionTimeout
   * @description handle session timeout action
   */
  public handleSessionTimeout() {
    this.handleLogoutAction(this.router.routerState.snapshot.url);
  }

  /**
   * @function handleSuccess
   * @description handle api 's success response
   * @param {any} response
   * @return Promise
   */
  public handleSuccess(response: any) {
    const { body, status } = response;

    if (status === Configuration.HTTP_RESPONSE_CODE.SUCCESS) {
      return of(body);
    }

    return of(response);
  }

  /**
   * @function hideLoader
   * @description hide system api loading
   */
  public hideLoader(): void {
    setTimeout(() => {
      this.loadingService.hide();
    }, Configuration.LOADING_TIMEOUT);
  }

  /**
   * @function setCurrentUser
   * @description setting current user
   * @param {User} user
   */
  public setCurrentUser(user: User) {
    this.currentUser.next(user);
  }

  /**
   * @function showError
   * @description show toaster error notification
   * @return true/false
   */
  public showError(errorFields: Array<any>) {
    if (errorFields.length > 0) {
      this.showToaster(
        'error',
        this.translate('ERROR'),
        errorFields.join('<br/>')
      );
      return false;
    } else {
      return true;
    }
  }

  /**
   * @function showLoader
   * @description show system api loading
   */
  public showLoader(): void {
    this.loadingService.show();
  }

  /**
   * @function showToaster
   * @description general toaster init method
   * @param {string} type
   * @param {string} title
   * @param {string} displayStr
   */
  public showToaster(type: string, title: string, displayStr: string) {
    // Overwrite the title of error type
    const errorTitle = this.translate('ERROR');
    title = type === TOASTER_TYPE.error ? errorTitle : title;
    this.toasterService.pop({
      type,
      title,
      body: displayStr,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml
    });
  }

  /**
   * @function storeCredentials
   * @description storing user credentials information to browser local storage
   * @param {object} info
   */
  public storeCredentials(info) {
    localStorage.setItem(
      Configuration.LOCAL_STORAGE_KEY.CREDENTIALS,
      JSON.stringify(info)
    );
  }

  /**
   * @function storeInfo
   * @description storing info with appropriate key to browser local storage
   * @param {string} key
   * @param {object} info
   */
  public storeInfo(key, info) {
    localStorage.setItem(key, JSON.stringify(info));
  }

  /**
   * @function storeUserInfo
   * @description storing user info information to browser local storage
   * @param {any} user
   */
  public storeUserInfo(user) {
    localStorage.setItem(
      Configuration.LOCAL_STORAGE_KEY.USER_INFO,
      JSON.stringify(user)
    );
  }

  /**
   * remove info with appropriate key to browser local storage
   * @param {string} key
   */
  public removeInfo(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * handle removeLocalStorage - delete local storage
   */
  public removeLocalStorage() {
    this.removeInfo(Configuration.LOCAL_STORAGE_KEY.CREDENTIALS);
    this.removeInfo(Configuration.LOCAL_STORAGE_KEY.USER_INFO);
  }

  /**
   * @function handleAfterLogoutAction
   * @description will handle action after redirect to login page
   */
  private handleAfterLogoutAction() {
    this.showLoader();
    setTimeout(() => {
      // always hide loading when log out
      this.hideLoader();
      this.dialog.closeAll();
      this.closeConfirmDialog();
      const credentials = this.getCredentials();
      if (credentials && credentials.remember === false) {
        this.removeLocalStorage();
      }
    }, 500);
  }

  /**
   * @function stopHandleSessionTimeout
   * @description stop handle session timeout action
   */
  private stopHandleSessionTimeout() {
    if (this.idle) {
      this.idle.stop();
      console.log('Stopped');
    }
  }

  /**
   * @function isArrayEqual
   * @description check if 2 array is equal
   */
  public isArrayEqual(x, y) {
    return _(x)
      .differenceWith(y, _.isEqual)
      .isEmpty();
  }

  public setToken(value) {
    this.setCookie(Configuration.COOKIE.ACCESS_TOKEN, value);
  }

  public getToken() {
    return this.getCookie(Configuration.COOKIE.ACCESS_TOKEN);
  }

  public removeToken() {
    return this.deleteCookie(Configuration.COOKIE.ACCESS_TOKEN);
  }

  public setCookie(cname, cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + Configuration.COOKIE.TIMEOUT);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  public deleteCookie(cname) {
    const expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = cname + '=' + ';' + expires + ';path=/';
  }

  public getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public showSessionTimeoutMsg() {
    this.showError([this.translate('SESSION_TIMEOUT')]);
  }

  /**
   * encryptMessage
   * @param text
   */
  public encryptMessage(text) {
    const key = CryptoJS.enc.Hex.parse(this.ENCRYPT_TEXT_KEY);
    const iv = CryptoJS.enc.Hex.parse(this.ENCRYPT_IV_KEY);
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv, mode: CryptoJS.mode.CFB });
    return encrypted.toString();
  }

  /**
   * decryptMessage
   * @param text
   */
  public decryptMessage(text) {
    const key = CryptoJS.enc.Hex.parse(this.ENCRYPT_TEXT_KEY);
    const iv = CryptoJS.enc.Hex.parse(this.ENCRYPT_IV_KEY);
    const decrypted = CryptoJS.AES.decrypt(text, key, { iv, mode: CryptoJS.mode.CFB });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  /**
   * @function handleAppSession
   * @description handle app session
   */
  public handleAppSession() {
    console.log('handleAppSession');
    this.idle.setIdle(Configuration.APP_SESSION_TIMEOUT);
    this.idle.setTimeout(5);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      const credentials = this.getCredentials();
      console.log('timeout: ', credentials.connected);
      if (credentials.connected) {
        this.idleState = 'Timed out!';
        console.log(this.idleState + ' : ' + new Date());
        this.timedOut = true;
        this.handleSessionTimeout();
      }
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      console.log(this.idleState);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });
  }

  /**
   * @function resetSession
   * @description handle reset session
   */
  public resetSession() {
    this.idle.watch();
    this.idleState = 'Started.';
    console.log(this.idleState + ' : ' + new Date());
    this.timedOut = false;
  }

  public translate(text) {
    return this.translateService.instant(text);
  }

  public getCaptchaToken(action) {
    return new Promise((resolve, reject) => {
      try {
        if (this.singleExecutionSubscription) {
          this.singleExecutionSubscription.unsubscribe();
        }
        this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
          .pipe(
            timeout(CAPTCHA.REQUEST_TIME_OUT),
            catchError(error => {
              reject(error);
              return of(error);
            })
          )
          .subscribe((token) => {
            resolve(token);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  public navigate(path) {
    this.router.navigate([path]);
  }

  public isAdmin(): boolean {
    const credentials = this.getCredentials();
    return credentials.role === USER_ROLE.ADMINISTRATOR;
  }

  public isSuperUser(): boolean {
    const credentials = this.getCredentials();
    return credentials.role === USER_ROLE.SUPER_USER;
  }

  public isAdminOrSuperUser(): boolean {
    const credentials = this.getCredentials();
    return credentials.role === USER_ROLE.SUPER_USER || credentials.role === USER_ROLE.ADMINISTRATOR;
  }

  public isEmptyObject(obj) {
    if (!obj) {
      return true;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public mergeTableSettings(settings, tableName) {
    const defaultTableSettings = TABLE_SETTINGS.DEFAULT_SETTINGS[tableName];

    if (!settings || this.isEmptyObject(settings)) {
      return defaultTableSettings;
    }

    let tableSetting = settings[tableName];

    if (!tableSetting) {
      return defaultTableSettings;
    }

    // TODO: merge local config to BE config here
    tableSetting = JSON.parse(settings[tableName]);

    if (!tableSetting) {
      return defaultTableSettings;
    }

    const mergeSettings = _.forEach(defaultTableSettings, item => {
      const columnSettings = _.find(tableSetting, { column: item.column });
      if (columnSettings) {
        item.active = columnSettings.active;
      }
    });

    return mergeSettings;
  }

  private getTableSetting(tableName) {
    const credentials = this.getCredentials();
    const settings = credentials.settings;

    return this.mergeTableSettings(settings, tableName);
  }

  public getListColumns(tableName) {
    const tableSettings = this.getTableSetting(tableName);
    const displayedColumns = [];

    tableSettings.forEach((item: any) => {
      if (item.active) {
        displayedColumns.push(item.column);
      }
    });
    displayedColumns.push('action');

    return displayedColumns;
  }

  public showConfirm(title: string = '', content: string = '', actionConfirm: any = null, actionCancel: any = null, type: string = CONFIRM_MODAL_OPTIONS.TYPE.DEFAULT) {
    let confirmBtnClass = 'btn-primary';
    if (type !== CONFIRM_MODAL_OPTIONS.TYPE.DEFAULT) {
      confirmBtnClass = `btn-${type}`;
    }
    const modalId = this.uuidv4();

    this.confirmModal[modalId] = $.confirm({
      boxWidth: Configuration.CONFIRM_DIALOG.WIDTH,
      useBootstrap: false,
      animationSpeed: 200,
      title,
      content,
      type,
      draggable: false,
      buttons: {
        confirm: {
          btnClass: confirmBtnClass,
          text: this.translate('YES'),
          action: () => {
            delete this.confirmModal[modalId];
            if (actionConfirm != null) {
              actionConfirm();
            }
          }
        },
        cancel: {
          btnClass: '',
          text: this.translate('NO'),
          action: () => {
            delete this.confirmModal[modalId];
            if (actionCancel != null) {
              actionCancel();
            }
          }
        }
      }
    });

    return this.confirmModal[modalId];
  }

  public handleEventMessage() {
    return this.messageEventService.getMessage();
  }

  public sendEventMessage(message, data) {
    return this.messageEventService.sendMessage(message, data);
  }

  public generateGetListParams({ pageSize, pageOffset, searchValue, sortValue }) {
    const options = {
      limit: pageSize,
      offset: pageOffset * pageSize,
      search: searchValue,
      ordering: ''
    };
    if (sortValue.direction && sortValue.direction.length) {
      options.ordering = sortValue.direction.toUpperCase() === Configuration.SORT_DIRECTION.ASCENDING ? sortValue.active : `-${sortValue.active}`;
    } else {
      delete options.ordering;
    }
    if (options && !options.search) {
      delete options.search;
    }

    return options;
  }

  public isDifferentObject(object1, object2): boolean {
    return !_.isEqual(object1, object2);
  }

  public cloneDeep(data): any {
    return _.cloneDeep(data);
  }

  /**
   * @function handleDownloadFile
   * @description download csv file
   * @param response
   * @return template string
   */
  public handleDownloadFile( response, keyStringFileName ) {
    const fileName    = this.translate( keyStringFileName );
    const isIEBrowser = this.detectIE();
    if ( isIEBrowser ) {
      return window.navigator.msSaveBlob( response.blob, `${ fileName }.csv` );
    }
    const dwldLink    = document.createElement( 'a' );
    const downloadUrl = URL.createObjectURL( response.blob );
    dwldLink.setAttribute( 'href', downloadUrl );
    dwldLink.setAttribute( 'download', `${ fileName }.csv` );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild( dwldLink );
    dwldLink.click();
    document.body.removeChild( dwldLink );
  }

  public getCurrentLoginUserName(): string {
    const credentials = this.getCredentials();
    return credentials.name;
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:one-variable-per-declaration
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line:triple-equals
      const v = c == 'x' ? r :
        // tslint:disable-next-line:no-bitwise
        (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public closeAllModal() {
    Object.keys(this.confirmModal).forEach(modalId => {
      try {
        if (this.confirmModal && this.confirmModal[modalId]) {
          this.confirmModal[modalId].close();
        }
        delete this.confirmModal[modalId];
      } catch (error) {
        console.log(error);
      }
    });
  }
}
