import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { ServiceTeamService, Validator, Common } from '../../../services';
import { ServiceTeam, FieldError } from '../../../models';
import { TOASTER_TYPE, SERVICE_TYPE, Configuration } from '../../../constants';

@Component({
  selector: 'app-glc-service-team-detail',
  templateUrl: './service-team-detail.component.html',
  styleUrls: ['./service-team-detail.component.scss']
})
export class ServiceTeamDetailComponent implements OnInit {
  isAdmin = false;
  serviceTeamRoute = Configuration.ROUTE_PATH.SERVICE_TEAMS;
  pageDescription = '';
  text = '';

  public isChanged = false;
  public serviceTeam: ServiceTeam = new ServiceTeam();
  public serviceTeamBk: ServiceTeam = new ServiceTeam();
  public listType = Object.keys(SERVICE_TYPE).map(item => {
    return {
      id: SERVICE_TYPE[item],
      text: item
    };
  });
  errors: any = {
    name: new FieldError(),
    email: new FieldError(),
    telephone: new FieldError()
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
    private validator: Validator,
    private serviceTeamService: ServiceTeamService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdminOrSuperUser();
    this.activatedRoute.params.subscribe((params: Params) => {
      const state = window.history.state;
      const serviceTeamId = parseInt(params.id, 10);
      if (serviceTeamId) {
        if (state.id !== serviceTeamId) {
          this.backToServiceTeamPage();
        }
        if (this.isAdmin) {
          this.pageDescription = '';
        }
        this.serviceTeam = new ServiceTeam(state);
        this.serviceTeamBk = this.common.cloneDeep(this.serviceTeam);
      } else {
        this.pageDescription = 'ADD_NEW_TEAM';
      }
    });
  }

  public isEmailValid(event) {
    const validateObject = new FieldError();

    if (event) {
      const isValid = this.validator.validateEmail(event);
      if (!isValid) {
        validateObject.isInvalid = true;
        validateObject.message = 'EMAIL_INVALID';
      }
    }
    this.errors.email = validateObject;
  }

  public isPasswordValid(event) {
    if (this.serviceTeam.id && !event) {
      this.errors.password = new FieldError();
    } else {
      this.errors.password = this.validator.validatePassword(event);
    }
  }

  public isNameValid(event) {
    this.errors.name = this.validator.validateName(event);
  }

  public isTelValid(event) {
    this.errors.telephone = this.validator.validateTelephone(event);
  }

  save() {
    if (!this.isAdmin || this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        if (this.serviceTeam.id) {
          this.update();
        } else {
          this.create();
        }
      }
    );
  }

  update() {
    try {
      this.serviceTeamService.update(this.serviceTeam)
        .subscribe(
          () => {
            this.isChanged = false;
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.common.hideLoader();
            this.backToServiceTeamPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  create() {
    try {
      this.serviceTeamService.create(this.serviceTeam)
        .subscribe(
          () => {
            this.isChanged = false;
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('CREATE'),
              this.common.translate('CREATE_SUCCESSFUL')
            );
            this.backToServiceTeamPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.serviceTeamBk, this.serviceTeam);
    if (!this.isChanged
      || !this.serviceTeam.email
      || !this.serviceTeam.name
      || !this.serviceTeam.telephone
      || !this.serviceTeam.in_house
    ) {
      return true;
    }

    return this.errors.email.isInvalid;
  }

  backToServiceTeamPage() {
    this.common.navigate(`/${this.serviceTeamRoute}`);
  }
}
