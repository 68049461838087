import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-glc-slide-option',
  templateUrl: './slide-option.component.html',
  styleUrls: ['./slide-option.component.scss']
})
export class SlideOptionComponent implements OnInit {
  private inputValue;
  private displayText;

  // text (2-ways binding)
  @Output() textChange = new EventEmitter();
  @Input()
  get text() {
    return this.displayText;
  }
  set text(val) {
    this.displayText = val;
    this.textChange.emit(this.displayText);
  }

  // value (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.inputValue;
  }
  set value(val) {
    this.inputValue = val;
    this.valueChange.emit(this.inputValue);
  }

  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }

}
