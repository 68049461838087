import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class DocumentCategoryService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }
  /**
   * Get all document categories
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getList(showLoader: boolean = true) {
    return this.apiService.restGET(this.apiUrl.DOCUMENT_CATEGORIES, null, showLoader);
  }
}
