<!-- Content Header (Page header) -->
<section class="content-header page-detail-header">
  <div class="back-bar">
    <button
      class="mat-stroked-button btn"
      (click)="onBackEvent()"
    >
      <i class="fas fa-long-arrow-alt-left"></i>
      {{ backBtnText | translate }}
    </button>
  </div>
  <h1>
    {{ pageTitle | translate }}
  </h1>
  <small>{{ pageDescription | translate }}</small>
</section>
<!-- Main content -->
<section class="content page-detail-content">
  <ng-content></ng-content>
</section>
<!-- /.content -->
  