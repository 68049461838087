<app-glc-page-details
  [pageTitle]="'USER_DETAILS'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_USER'"
  (backPress)="backToUserPage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <div *ngIf="user.id && isAdmin">
          <button
            mat-button
            class="btn btn-danger pull-right"
            (click)="delete()"
          >
            {{ 'DELETE' | translate }}
          </button>
        </div>
        <app-glc-input
          isRequired
          [title]="'NAME'"
          [placeholder]="'NAME'"
          [name]="'name'"
          [(value)]="user.name"
          [(error)]="errors.name"
          (modelChangeEvent)="isNameValid($event)"
          [isDisabled]="!isAdmin"
        ></app-glc-input>
        <app-glc-input
          isRequired
          [title]="'EMAIL'"
          [placeholder]="'EMAIL'"
          [name]="'email'"
          [(value)]="user.email"
          [(error)]="errors.email"
          (modelChangeEvent)="isEmailValid($event)"
          [isDisabled]="!isAdmin"
        ></app-glc-input>
        <app-glc-input
          [(isRequired)]="!user.id"
          [title]="'PASSWORD'"
          [placeholder]="'PASSWORD'"
          [type]="'password'"
          [name]="'password'"
          [(value)]="user.password"
          [(error)]="errors.password"
          (modelChangeEvent)="isPasswordValid($event)"
          [isDisabled]="!isAdmin"
        ></app-glc-input>
        <app-glc-select
          [title]="'ROLE'" 
          [(selectedItem)]="user.role"
          [valueKey]="'id'"
          [textKey]="'text'"
          [(dataSource)]="listRole"
          [isDisabled]="!isAdmin"
        ></app-glc-select>
        <app-glc-checkbox
          [name]="'active'"
          [text]="'ACTIVE'"
          [(value)]="user.active"
          [isDisabled]="!isAdmin"
        ></app-glc-checkbox>
        <div *ngIf="isAdmin">
          <button
            mat-button
            class="btn btn-primary pull-right"
            (click)="save()"
            [disabled]="isDisabledBtn()"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-glc-page-details>
