import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Common } from '../services/common.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private common: Common
  ) { }

  canActivate() {
    const credentials = this.common.getCredentials();

    if (credentials.connected) {
      this.common.navigate('/');
      return false;
    }
    return true;
  }
}
