import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { UserService, Validator, Common } from '../../../services';

import { User, FieldError, Credential } from '../../../models';
import { TOASTER_TYPE, USER_ROLE, Configuration, CONFIRM_MODAL_OPTIONS } from '../../../constants';

@Component({
  selector: 'app-glc-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  isAdmin = false;
  userRoute = Configuration.ROUTE_PATH.USERS;
  pageDescription = '';
  text = '';

  private credentials: Credential;

  public isChanged = false;
  public user: User = new User();
  public userBk: User = new User();
  public listRole = Object.keys(USER_ROLE).map(item => {
    return {
      id: USER_ROLE[item],
      text: item
    };
  });
  errors: any = {
    name: new FieldError(),
    email: new FieldError(),
    password: new FieldError()
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
    private validator: Validator,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.credentials = this.common.getCredentials();
    this.isAdmin = this.credentials.role === USER_ROLE.ADMINISTRATOR;

    this.activatedRoute.params.subscribe((params: Params) => {
      const state = window.history.state;
      const userId = parseInt(params.id, 10);
      if (userId) {
        if (state.id !== userId) {
          this.backToUserPage();
        }
        if (this.isAdmin) {
          this.pageDescription = 'EDIT_USER';
        }
        this.user = new User(state);
        this.userBk = this.common.cloneDeep(this.user);
      } else {
        this.pageDescription = 'CREATE_USER';
      }
    });
  }

  public isEmailValid(event) {
    const validateObject = new FieldError();

    if (event) {
      const isValid = this.validator.validateEmail(event);
      if (!isValid) {
        validateObject.isInvalid = true;
        validateObject.message = 'EMAIL_INVALID';
      }
    }
    this.errors.email = validateObject;
  }

  public isPasswordValid(event) {
    if (this.user.id && !event) {
      this.errors.password = new FieldError();
    } else {
      this.errors.password = this.validator.validatePassword(event);
    }
  }

  public isNameValid(event) {
    this.errors.name = this.validator.validateName(event);
  }

  save() {
    if (this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        if (this.user.id) {
          this.update();
        } else {
          this.create();
        }
      }
    );
  }

  update() {
    if (!this.user.id || !this.isAdmin) {
      return;
    }
    try {
      this.userService.update(this.user)
        .subscribe(
          () => {
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToUserPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  create() {
    if (!this.isAdmin) {
      return;
    }
    try {
      this.userService.create(this.user)
        .subscribe(
          () => {
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('CREATE'),
              this.common.translate('CREATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToUserPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.userBk, this.user);
    if (!this.isChanged
      || !this.user.id && (!this.user.email
      || !this.user.password
      || !this.user.name
    )) {
      return true;
    }

    return this.errors.email.isInvalid
      || this.errors.password.isInvalid
      || this.errors.name.isInvalid;
  }

  backToUserPage() {
    this.common.navigate(`/${this.userRoute}`);
  }

  delete() {
    const userId = this.credentials.id;
    if (userId === this.user.id) {
      this.common.showError([this.common.translate('ERR_CANNOT_DELETE_SELF')]);
      return;
    }

    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_QUESTION'),
      () => {
        try {
          this.userService.delete({
            ids: [this.user.id]
          })
            .subscribe(
              () => {
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.backToUserPage();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([error.message]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );

  }
}
