import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { FormsModule } from '@angular/forms';
// angular translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
// 3rd parties
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToasterModule } from 'angular2-toaster';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { MessageEventService } from './services/message-event.service';
import { ServicesModule } from './services/services.module';
import { ScreensModule } from './screens/screens.module';
import { JwtInterceptor } from './helpers';
import { AuthGuard, LoginGuard, AdminGuard, UserGuard, CanDeactivateGuard, AdminSuperUserGuard } from './guards';
import { CAPTCHA } from './constants';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?t=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    RecaptchaV3Module,
    HttpClientModule,
    FormsModule,
    ServicesModule.forRoot(),
    ComponentsModule.forRoot(),
    ScreensModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ToasterModule,
    NgbModule
  ],
  providers: [
    MessageEventService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: CAPTCHA.SITE_KEY
    },
    AuthGuard,
    LoginGuard,
    AdminGuard,
    UserGuard,
    CanDeactivateGuard,
    AdminSuperUserGuard
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
