<app-glc-page-details
  [pageTitle]="'CONTROL_REPAIR_PROTOCOL'"
  [pageDescription]="'CONTROL_REPAIR_PROTOCOL_DESCRIPTION'"
  [backBtnText]="'DEVICE_DETAILS'"
  (backPress)="backToDeviceDetail()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          [title]="'BUILDING'"
          [placeholder]="'BUILDING'"
          [name]="'building'"
          [(value)]="building.name"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          [title]="'USER'"
          [placeholder]="'USER'"
          [name]="'user'"
          [(value)]="currentUser.name"
          isDisabled
        ></app-glc-input>
        <app-glc-input
          *ngIf="isAdminOrSuperUser"
          [title]="'INFORM_DAYS_BEFORE'"
          [placeholder]="'INFORM_DAYS_BEFORE_PLACEHOLDER'"
          [name]="'inform_days'"
          [(value)]="control.inform_days"
          [(error)]="errors.inform_days"
          (modelChangeEvent)="validate('inform_days')"
          [isDisabled]="!isAdminOrSuperUser || !controlBK.add_todo"
        ></app-glc-input>
        <app-glc-datepicker
          [isRequired]="true"
          [title]="'CURRENT_DATE'"
          [placeholder]="'PLEASE_SELECT'"
          [name]="'date_of_control'"
          [(value)]="control.date_of_control"
          [(error)]="errors.date_of_control"
          (modelChangeEvent)="validate('date_of_control')"
          [isDisabled]="!controlBK.add_todo"
          [disabledDates]="disabledDates"
        ></app-glc-datepicker>
        <app-glc-input
          [title]="'DEFECTS'"
          [placeholder]="'DEFECTS'"
          [name]="'defects'"
          [(value)]="control.defects"
          [isDisabled]="!controlBK.add_todo"
        ></app-glc-input>
        <app-glc-input
          isRequired
          [title]="'SERVICE_TEAM'"
          [placeholder]="'SERVICE_TEAM'"
          [name]="'service_team'"
          [(value)]="control.service_team"
          [(error)]="errors.service_team"
          (modelChangeEvent)="validate('service_team')"
          [isDisabled]="!controlBK.add_todo"
        ></app-glc-input>
        <app-glc-input
          [title]="'ORDER'"
          [placeholder]="'ORDER'"
          [name]="'orders'"
          [(value)]="control.orders"
          [isDisabled]="!controlBK.add_todo"
        ></app-glc-input>
        <app-glc-input
          isRequired
          [title]="'PERSON_OF_CONTACT'"
          [placeholder]="'PERSON_OF_CONTACT'"
          [name]="'person_contact'"
          [(value)]="control.person_contact"
          [(error)]="errors.person_contact"
          (modelChangeEvent)="validate('person_contact')"
          [isDisabled]="!controlBK.add_todo"
        ></app-glc-input>
        <app-glc-datepicker
          [(isRequired)]="control.add_to_calendar"
          [title]="'APPOINTMENT'"
          [placeholder]="'PLEASE_SELECT'"
          [name]="'date_of_appointment'"
          [(value)]="control.date_of_appointment"
          [(error)]="errors.date_of_appointment"
          (modelChangeEvent)="validate('date_of_appointment')"
          [isDisabled]="!control.add_to_calendar || !controlBK.add_todo"
          [disabledDates]="disabledDates"
        ></app-glc-datepicker>
        <app-glc-checkbox
          [name]="'add_to_calendar'"
          [text]="'ADD_TO_CALENDAR'"
          [(value)]="control.add_to_calendar"
          (modelChangeEvent)="onChangeAddToCalendar($event)"
          [isDisabled]="!controlBK.add_todo"
        ></app-glc-checkbox>
        <app-glc-input
          [title]="'NOTE'"
          [placeholder]="'NOTE_PLACEHOLDER'"
          [name]="'note'"
          [(value)]="control.note"
          [isDisabled]="!controlBK.add_todo"
        ></app-glc-input>
        <mat-radio-group
          class="custom-radio-group"
          [(ngModel)]="control.add_todo"
          [disabled]="!controlBK.add_todo"
        >
          <mat-radio-button
            class="custom-radio-button"
            [value]="addTodoListOption.value"
            (change)="radioChange($event)"
            [disabled]="!controlBK.add_todo"
          >
            {{ addTodoListOption.text | translate }}
          </mat-radio-button>
          <mat-radio-button
            class="custom-radio-button"
            [value]="workCompleteOption.value"
            (change)="radioChange($event)"
            [disabled]="!controlBK.add_todo"
          >
            {{ workCompleteOption.text | translate }}
          </mat-radio-button>
          <app-glc-input
            [(isRequired)]="control.add_todo === workCompleteOption.value"
            [title]="'WORK_DONE'"
            [placeholder]="'WORK_DONE_PLACEHOLDER'"
            [name]="'done_by'"
            [(value)]="control.done_by"
            [(error)]="errors.done_by"
            (modelChangeEvent)="validate('done_by')"
            [isDisabled]="(control.add_todo !== workCompleteOption.value) || !controlBK.add_todo"
          ></app-glc-input>
          <app-glc-datepicker
            [(isRequired)]="control.add_todo === workCompleteOption.value"
            [title]="'DATE_OF_SIGNATURE'"
            [placeholder]="'PLEASE_SELECT'"
            [name]="'date_of_signature'"
            [(value)]="control.date_of_signature"
            [(error)]="errors.date_of_signature"
            (modelChangeEvent)="validate('date_of_signature')"
            [isDisabled]="(control.add_todo !== workCompleteOption.value) || !controlBK.add_todo"
          ></app-glc-datepicker>
        </mat-radio-group>
        
        <div>
          <button
            mat-button
            class="btn btn-primary pull-right"
            (click)="save()"
            [disabled]="isDisabledBtn() || !controlBK.add_todo"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-glc-page-details>
