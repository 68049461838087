<app-glc-page-details
  [pageTitle]="'BUILDING_DETAIL'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_BUILDINGS'"
  (backPress)="backToBuildingPage()"
>
  <div class="row js">
    <div class="col-md-4">
      <form>
        <app-glc-file *ngIf="isAdmin"
          (fileSelect)="handleFileInput($event)"
          [title]="'BUILDING_IMAGE'"
          [required]="true"
          [isMultiple]="false"
          [maxSize]="3"
          [wrongFormatMess]="'BUILDING_IMAGE_INVALID'"
          [oversizeMess]="'BUILDING_MAXIMUM_SIZE_ALLOW'"
          [placeHolder]="'IMAGE_CHOOSE_MESSAGE'"
          [fileTypes]="fileTypes"
          [(files)]="buildingImage"
        >
        </app-glc-file>
        <div *ngIf="id !== 0" class="img-container">
          <img src={{buildingForm.image}} />
        </div>
        <app-glc-select-searchable
        [isRequired]="true"
        [title]="'FUNCTION'"
        [textKey]="'name'"
        [placeholder]="'PLEASE_SELECT'"
        [dataService]="privateFunctionService"
        [(value)]="buildingForm.function"
        [(error)]="errors.function"
        (modelChangeEvent)="validate('function')"
        [isDisabled]="!isAdmin"
        [multiple]="true"
        >
        </app-glc-select-searchable>
        <app-glc-input isRequired [isDisabled]="!isAdmin" [title]="'NAME'" [placeholder]="'NAME'" [name]="'name'" [(value)]="buildingForm.name" [(error)]="errors.email"></app-glc-input>
        <app-glc-input isRequired [isDisabled]="!isAdmin" [title]="'LOCATION'" [placeholder]="'LOCATION'" [name]="'location'" [(value)]="buildingForm.location" [(error)]="errors.location"></app-glc-input>
        <app-glc-input isRequired [isDisabled]="!isAdmin" [title]="'STREET_AND_NR'" [placeholder]="'STREET'" [name]="'street'" [(value)]="buildingForm.street" [(error)]="errors.street"></app-glc-input>
        <app-glc-input isRequired [isDisabled]="!isAdmin" [title]="'ZIP'" [placeholder]="'ZIP'" [name]="'zip'" [(value)]="buildingForm.zipcode" [(error)]="errors.zip"></app-glc-input>
        <app-glc-input isRequired [isDisabled]="!isAdmin" [title]="'CITY'" [placeholder]="'CITY'" [name]="'city'" [(value)]="buildingForm.city" [(error)]="errors.city"></app-glc-input>
        <div *ngIf="isAdmin">
          <button class="btn btn-primary pull-right"
            type='button'
            mat-button
            [disabled]="isDisabledBtn()"
            (click)="save()"
          >
            {{ id == 0 ? ('BUILDING_ADD' | translate) : ('SAVE' | translate) }}
          </button>          
        </div>
      </form>
    </div>
  </div>
</app-glc-page-details>
