import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRadioButton,
    MatRadioGroup
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { LoadingComponent } from './loading/loading.component';
import { LoadingService } from './loading/loading.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { MainComponent } from './main/main.component';
import { GLTableComponent } from './gltable/gltable.component';
import { InputComponent } from './input/input.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { PageContentComponent } from './page-content/page-content.component';
import { GLSearchComponent } from './glsearch/glsearch.component';
import { SlideOptionComponent } from './slide-option/slide-option.component';
import { SelectComponent } from './select/select.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { FileComponent } from './file/file.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { TabsComponent } from './tabs/tabs.component';
import { PageDetailsComponent } from './page-details/page-details.component';
import { TextareaComponent } from './textarea/textarea.component';
import { SelectSearchableComponent } from './select-searchable/select-searchable.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';

@NgModule({
    exports: [
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatRadioModule
    ]
})
export class MaterialModule {}

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        LoadingComponent,
        MenuComponent,
        MainComponent,
        GLTableComponent,
        GLSearchComponent,
        InputComponent,
        CheckboxComponent,
        PageContentComponent,
        SlideOptionComponent,
        SelectComponent,
        ConfirmationModalComponent,
        DatepickerComponent,
        TabsComponent,
        FileComponent,
        PageDetailsComponent,
        TextareaComponent,
        SelectSearchableComponent,
        RadioButtonComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        RouterModule,
        TranslateModule,
        NgbModalModule,
        MatNativeDateModule,
        NgSelectModule,
    ],
    exports: [
        CommonModule,
        FooterComponent,
        HeaderComponent,
        LoadingComponent,
        MenuComponent,
        MainComponent,
        GLTableComponent,
        GLSearchComponent,
        InputComponent,
        CheckboxComponent,
        PageContentComponent,
        SlideOptionComponent,
        SelectComponent,
        ConfirmationModalComponent,
        DatepickerComponent,
        TabsComponent,
        FileComponent,
        PageDetailsComponent,
        TextareaComponent,
        SelectSearchableComponent,
        RadioButtonComponent,
        MatRadioButton,
        MatRadioGroup
    ],
    providers: [
        LoadingService
    ],
    entryComponents: [
    ]
})
export class ComponentsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ComponentsModule,
            providers: []
        };
    }
}
