import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { concat, Subject, of, Observable } from 'rxjs';
import { FieldError } from 'src/app/models';

@Component({
  selector: 'app-glc-select-searchable',
  templateUrl: './select-searchable.component.html',
  styleUrls: ['./select-searchable.component.scss']
})
export class SelectSearchableComponent implements OnInit {
  private inputValue;

  @Input() title: string;
  @Input() placeholder: string;
  @Input() valueKey: string;
  @Input() textKey: string;
  @Input() dataService: any;
  @Input() isRequired = false;
  @Input() error: FieldError;
  @Input() isDisabled = false;
  @Input() isServerSide = true;
  @Input() addTag = false;
  @Input() multiple = false;
  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();

  // value (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.inputValue;
  }
  set value(val) {
    this.inputValue = val;
    this.valueChange.emit(this.inputValue);
  }

  data: Observable<any>;
  loading = false;
  dataInput = new Subject<string>();

  constructor() { }

  ngOnInit() {
    this.loadData();
  }

  trackByFn(item: any) {
    return item.id;
  }

  private loadData() {
    if (this.isServerSide) {
      this.data = concat(
        of([]), // default items
        this.dataInput.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.loading = true),
          switchMap(term => this.dataService.getList({
            limit: 100,
            offset: 0,
            search: term
          }, false)
            .pipe(
              map((data: any) => {
                const results = data.results.filter(item => item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1);
                return results;
              }),
              catchError(() => of([])), // empty list on error
              tap(() => this.loading = false)
            ))
        )
      );
    } else {
      this.data = this.dataService.getList(false).pipe(
        map((data: any) => {
          if (data.categories) {
            return data.categories;
          }
          return data.results;
        }),
        catchError(() => of([])), // empty list on error
        tap(() => this.loading = false)
      );
    }
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }
}
