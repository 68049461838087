import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as _ from 'lodash';
import { FILE_TYPE } from 'src/app/constants';

@Component({
  selector: 'app-glc-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  filesSelected = [];

  @Input() title = '';
  @Input() required = false;
  @Input() isMultiple = false;
  @Input() fileTypes = FILE_TYPE.DEFAULT;
  @Input() maxSize = 3;
  @Input() placeHolder = 'FILE_CHOOSE_MESSAGE';
  @Input() wrongFormatMess = '';
  @Input() oversizeMess = '';
  @Output() fileSelect = new EventEmitter();

    // files (2-ways binding)
  @Output() filesChange = new EventEmitter();
  @Input()
  get files() {
    return this.filesSelected;
  }
  set files(val) {
    this.filesSelected = val;
    this.filesChange.emit(this.filesSelected);
  }

  hasError = false;
  error = '';
  filesName = '';
  totalSize = 0;

  constructor(
  ) {

  }

  ngOnInit() {
    this.totalSize = 0;
    this.error = '';
  }

  toArray(fileList) {
    return Array.prototype.slice.call(fileList);
  }

  handleFileInput( files: FileList ) {
    this.totalSize = 0;
    this.error = '';
    if (files && files.item) {
      this.filesName = _.map(files, 'name');
      if (files.item(0)) {
        if (this.isMultiple) {
        this.filesSelected = this.filesSelected.concat(this.toArray(files));
        } else {
          this.filesSelected = this.toArray(files);
        }
      }
      this.hasError = !this.checkValidType(this.filesSelected);
      this.filesChange.emit(this.filesSelected);
      this.fileSelect.emit(this.hasError);
    }
  }

  removeFile(index) {
    this.filesSelected.splice(index, 1);
    this.hasError = !this.checkValidType(this.filesSelected);
    this.filesChange.emit(this.filesSelected);
    this.fileSelect.emit(this.hasError);
  }

  checkValidType(files) {
    let result = true;
    this.totalSize = 0;
    for ( let i = 0; i < files.length; i++) {
      // const fileType = files[i].type;
      const fileSize = files[i].size;
      const fileExtension = files[i].name.split('.');
      const fileType = fileExtension.length > 1 ? fileExtension[fileExtension.length - 1].toLowerCase() : null;
      this.totalSize += fileSize;
      const acceptedFiles = this.fileTypes.toLowerCase();
      if (fileType && acceptedFiles.indexOf(fileType) !== -1) {
        result = result && true;
      } else {
        result = result && false;
      }
    }
    if (!result) {
      this.error = this.wrongFormatMess;
    } else if (this.totalSize / 1024 / 1024 > this.maxSize) {
      result = false;
      this.error = this.oversizeMess;
    }
    return result;
  }

  getFileSize(file) {
    const fileSizeInMb = file.size / 1024 / 1024;
    return `${fileSizeInMb.toFixed(1)} Mb`;
  }

}
