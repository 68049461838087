export class ResetPassword {
  public code: string;
  public newPassword: string;
  public repeatPassword: string;

  /**
   * @ignore
   */
  constructor( info: any = {} ) {
    this.code = info && info.code ? info.code : '';
    this.newPassword = info && info.newPassword ? info.newPassword : '';
    this.repeatPassword = info && info.repeatPassword ? info.repeatPassword : '';
  }
}
