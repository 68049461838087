import * as Validator from 'validatorjs';
import { VALIDATOR } from '../constants';

export class Device {
  /**
   * ID - auto increment
   */
  public id: number;
  /**
   * Device name
   */
  public name: string;
  /**
   * Device ID
   */
  public device_hard_id: string;
  /**
   * instaGuard ID
   */
  public instaguard_id: string;
  /**
   * Building id
   */
  public building_id: any;
  /**
   * Location
   */
  public location: string;
  /**
   * Function id
   */
  public function_id: any;
  /**
   * Internal team id
   */
  public internal_team_id: any;
  /**
   * External team id
   */
  public external_team_id: any;
  /**
   * Device document category
   */
  public device_document_category: any;
  /**
   * Service date time
   */
  public service_date_time: string;
  /**
   * Service inform days before
   */
  public service_inform_days: number;
  /**
   * Control date time
   */
  public control_date_time: string;
  /**
   * Control inform days before
   */
  public control_inform_days: number;
  /**
   * Follow up
   */
  public follow_up: number;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.name = data.name || '';
    this.device_hard_id = data.device_hard_id || '';
    this.instaguard_id = data.instaguard_id || '';
    this.building_id = data.building_id || null;
    this.location = data.location || '';
    this.function_id = data.function_id || null;
    this.internal_team_id = data.internal_team_id || null;
    this.external_team_id = data.external_team_id || null;
    this.device_document_category = data.device_document_category || null;
    this.service_date_time = data.service_date_time || null;
    this.service_inform_days = data.service_inform_days || null;
    this.control_date_time = data.control_date_time || null;
    this.control_inform_days = data.control_inform_days || null;
    this.follow_up = data.follow_up || 0;
  }

  /**
   * Validate model when create or update
   */
  validate(field = null, editing = false): any {
    const rules = {
      name: `required|min:${VALIDATOR.NAME.MIN}`,
      device_hard_id: 'required',
      building_id: 'required',
      function_id: 'required',
      external_team_id: 'required',
      service_inform_days: 'integer',
      control_inform_days: 'integer'
    };

    if (!editing) {
      // tslint:disable-next-line:no-string-literal
      rules['service_date_time'] = 'required';
      // tslint:disable-next-line:no-string-literal
      rules['control_date_time'] = 'required';
    }

    const validation = new Validator(this, rules, {
      'required.name': 'NAME_IS_REQUIRED',
      'required.device_hard_id': 'DEVICE_ID_IS_REQUIRED',
      'required.building_id': 'BUILDING_IS_REQUIRED',
      'required.function_id': 'FUNCTION_IS_REQUIRED',
      'required.service_date_time': 'SERVICE_IS_REQUIRED',
      'required.control_date_time': 'CONTROL_IS_REQUIRED',
      'required.external_team_id': 'EXTERNAL_TEAM_IS_REQUIRED',
      'min.name': 'NAME_MUST_BE_LONGER',
      'integer.service_inform_days': 'INFORM_DAYS_BEFORE_MUST_BE_NUMBER',
      'integer.control_inform_days': 'INFORM_DAYS_BEFORE_MUST_BE_NUMBER'
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
