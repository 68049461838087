<app-glc-page-details
  [pageTitle]="'GENERAL_TASK'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_TO_DO'"
  (backPress)="backToToDoListPage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <div *ngIf="task.id" class="pull-right">
          <a *ngIf="isDisabled" class="p-relative dark-gray underline" (click)="enableEdit()">
              <i class="link far fa-edit"></i>
              {{ 'EDIT' | translate }}
          </a>
          <a *ngIf="!isDisabled" class="p-relative dark-gray underline" (click)="cancelEdit()">
              <i class="link fas fa-times"></i>
              {{ 'CANCEL' | translate }}
          </a>
        </div>
        <app-glc-textarea
          isRequired
          [title]="'TASK_DESCRIPTION'"
          [placeholder]="'TASK_DESCRIPTION'"
          [name]="'comment'"
          [(value)]="task.comment"
          [(error)]="errors.comment"
          (modelChangeEvent)="validate('comment')"
          [isDisabled]="isDisabled"
        ></app-glc-textarea>
        <div>
          <button
            *ngIf="!isDisabled"
            mat-button
            class="btn btn-primary pull-right"
            (click)="save()"
            [disabled]="isDisabledBtn()"
          >
            {{ 'SAVE' | translate }}
          </button>
          <button
            *ngIf="isDisabled"
            mat-button
            class="btn btn-primary pull-right"
            (click)="completeTask()"
          >
            {{ 'COMPLETE_TASK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-glc-page-details>
