<div class="form-group" [ngClass]="{ 'has-error': error && error.isInvalid }">
  <label class="custom-label">{{ title | translate}} <span class="required" *ngIf="isRequired">*</span></label>
  <div class="picker-container" [ngClass]="{ 'control-disabled': isDisabled }">
    <input 
      [attr.id]="elementId"
      [(ngModel)]="value" 
      class="custom-datetime-picker"
      [required]="isRequired" 
      placeholder="{{ placeholder | translate }}"
      name="{{ name }}" 
      (ngModelChange)="onModelChangeEvent($event)"
      [disabled]="isDisabled" 
      class="custom-datetime-picker">
      <span class="icon" (click)="openCalendar()"><i class="far fa-calendar-alt" ></i></span>
  </div>
  <div class="error required" *ngIf="error && error.isInvalid">{{ error.message | translate }}</div>
</div>