import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from '../constants';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get all calendar events by type
   * @param options: type (0: service, 1: control)
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(`${this.apiUrl.CALENDAR}/event`, params, showLoader);
  }

  /**
   * Create calendar event
   * @param params: any
   * @return Observable
   */
  public create(params: any) {
    return this.apiService.restPOST(this.apiUrl.CALENDAR, params);
  }

  /**
   * Update calendar event
   * @param params: any
   * @return Observable
   */
  public update(params: any) {
    const paramsClone = _.cloneDeep(params);
    delete paramsClone.id;
    return this.apiService.restPATCH(`${this.apiUrl.CALENDAR}/${params.id}`, paramsClone);
  }

  /**
   * Delete
   * @param  params
   * @return  Observable
   */
  public delete(params) {
    return this.apiService.restDELETE(`${this.apiUrl.CALENDAR}/${params.id}`);
  }
}
