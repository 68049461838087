import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';

import { AuthenticationService } from '../../services/authentication.service';
import { TaskService, Common } from '../../services';

import { Configuration, MESSAGE_EVENT } from '../../constants';

@Component({
  selector: 'app-glc-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  refreshTokenInterval: any;

  constructor(
    private common: Common,
    private authenticationService: AuthenticationService,
    private taskService: TaskService
  ) { }

  ngOnInit() {
    // this.initIntervalRefresh();
    // this.getTotalTaskCount();
  }
  ngAfterViewInit() {
    this.common.resetSession();
    // $('body').addClass('hide-captcha');
  }

  ngOnDestroy() {
    this.clearIntervalRefresh();
  }

  initIntervalRefresh() {
    this.clearIntervalRefresh();
    this.refreshTokenInterval = setInterval(async () => {
      this.clearIntervalRefresh();
      const credentials = this.common.getCredentials();
      if (!credentials.connected) {
        this.common.handleLogoutAction();
        return;
      }
      const token = credentials.accessToken;
      try {
        this.authenticationService.refreshToken(token)
          .subscribe(
            (response: any) => {
              credentials.accessToken = response.token;
              this.common.storeCredentials(credentials);
              this.getTotalTaskCount();
              this.initIntervalRefresh();
            });
      } catch (error) {
        this.common.hideLoader();
        this.common.showError([JSON.stringify(error)]);
      }
    }, Configuration.REFRESH_TOKEN_INTERVAL);
  }

  getTotalTaskCount() {
    try {
      this.taskService.getTaskCount()
        .subscribe(
          (response: any) => {
            this.common.sendEventMessage( MESSAGE_EVENT.UPDATE_MENU_TASK_COUNT, response.count );
          });
    } catch (error) {
      this.common.showError([error.message]);
    }
  }

  clearIntervalRefresh() {
    if (this.refreshTokenInterval) {
      clearInterval(this.refreshTokenInterval);
    }
  }
}
