import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ApiService } from './api.service';
import { Configuration } from '../constants';
import { ControlProtocol } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DeviceControlService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get all device control services
   * @param options: page, size, key
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.DEVICE_CONTROL, params, showLoader);
  }

  /**
   * Get device control service by ID
   * @param id: Device service ID
   * @return Observable
   */
  public getByID(id) {
    return this.apiService.restGET(`${this.apiUrl.DEVICE_CONTROL}/${id}`);
  }

  /**
   * Get calendar
   * @return event list
   */
  public getCalendar() {
    return this.apiService.restGET(this.apiUrl.DEVICE_CONTROL);
  }

  /**
   * Get next device control
   * @param id: Device ID
   * @return Observable
   */
  public getNext(id) {
    return this.apiService.restGET(`${this.apiUrl.DEVICE_CONTROL}/${id}/next`);
  }

  /**
   * Get document info by device ID
   * @param options: page, size, key
   * @param id: DEVICE ID
   * @return Observable
   */
  public getByDeviceID(options: any, id) {
    const params = options || null;
    return this.apiService.restGET(`${this.apiUrl.DEVICE_CONTROL}/${id}/follow-up`, params);
  }

  /**
   * Create device control service
   * @param params: ControlProtocol
   * @return Observable
   */
  public create(params: ControlProtocol) {
    let paramsClone = _.cloneDeep(params);
    paramsClone = this.formatDateData(paramsClone);

    return this.apiService.restPOST(this.apiUrl.DEVICE_CONTROL, paramsClone);
  }

  /**
   * Update device control service
   * @param params: ControlProtocol
   * @return Observable
   */
  public update(params: ControlProtocol) {
    let paramsClone = _.cloneDeep(params);
    paramsClone = this.formatDateData(paramsClone);

    return this.apiService.restPATCH(`${this.apiUrl.DEVICE_CONTROL}/${params.id}`, paramsClone);
  }

  private formatDateData(data) {
    data.date_of_control = moment(data.date_of_control, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);
    if (data.add_to_calendar) {
      data.date_of_appointment = moment(data.date_of_appointment, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);
    } else {
      data.date_of_appointment = null;
    }
    if (!data.add_todo) {
      data.work_complete = true;
      data.date_of_signature = moment(data.date_of_signature, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_TIME_FORMAT_SERVER);
    } else {
      data.work_complete = false;
      data.date_of_signature = null;
    }

    if (!data.inform_days) {
      data.inform_days = 0;
    }
    return data;
  }
}
