import { Component, OnInit, AfterViewInit } from '@angular/core';

import { UserService } from '../../services';
import { Common } from '../../services/common.service';
import { Configuration, USER_ROLE, TOASTER_TYPE, CONFIRM_MODAL_OPTIONS, TABLE_SETTINGS } from '../../constants';
import { Credential } from '../../models';

@Component({
  selector: 'app-glc-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
  private credentials: Credential;
  isAdmin = false;
  userRoute = Configuration.ROUTE_PATH.USERS;
  pageDescription = 'USERS_VIEW_DESCRIPTION';

  tableData: any = null;
  isShowMultipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.credentials = this.common.getCredentials();
    this.isAdmin = this.credentials.role === USER_ROLE.ADMINISTRATOR;
    if (this.isAdmin) {
      this.pageDescription = 'USERS_DESCRIPTION';
    }
    this.initData();
    this.getUserList();
  }

  ngAfterViewInit() {
  }

  getUserList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.userService.getList(options)
        .subscribe(
          (response: any) => {
            console.log(response);
            const results = response.results.map(item => {
              return this.mapUserData(item);
            });

            this.tableData = {
              ...this.tableData,
              pageIndex: this.pageOffset,
              totalRecords: response.count,
              dataSource: results
            };

            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  initData() {
    const tableDisplayColumns = this.common.getListColumns(TABLE_SETTINGS.TABLE_NAME.USERS);
    let displayedColumns = ['selectedId', 'name'];
    displayedColumns = displayedColumns.concat(tableDisplayColumns);

    this.tableData = {
      displayedColumns,
      field: [
        {
          name: 'selectedId',
          title: 'ID'
        },
        {
          name: 'name',
          title: 'NAME'
        },
        {
          name: 'email',
          title: 'EMAIL'
        },
        {
          name: 'roleText',
          title: 'ROLE'
        },
        {
          name: 'activeText',
          title: 'ACTIVE_INACTIVE'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  mapUserData(user) {
    return {
      selectedId: user.id,
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role_id,
      roleText: user.role_id === USER_ROLE.ADMINISTRATOR ? 'ADMINISTRATOR' : user.role_id === USER_ROLE.SUPER_USER ? 'SUPER_USER' : 'STANDARD_USER',
      active: user.is_active,
      activeText: user.is_active ? 'ACTIVE' : 'INACTIVE',
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: `/${this.userRoute}/${user.id}`
        }
      ],
      selected: false
    };
  }

  applyFilter(_filterValue: string) {
    this.getUserList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'roleText':
        this.sortValue.active = 'role_id';
        break;
      case 'activeText':
        this.sortValue.active = 'is_active';
        break;
    }
    this.getUserList();
  }

  add() {
    if (this.isAdmin) {
      this.common.navigate(`${this.userRoute}/create`);
    }
  }

  deleteUsers(listIds) {
    const userId = this.credentials.id;
    if (listIds.indexOf(userId) !== -1) {
      this.common.showError([this.common.translate('ERR_CANNOT_DELETE_SELF')]);
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_USER'),
      () => {
        this.common.showLoader();
        try {
          this.userService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                console.log(response);
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.isShowMultipleSelect = false;
                this.getUserList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }
}
