import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Configuration } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * building
   * @param  options: page, size, key
   * @param {boolean} showLoader
   * @return  Building list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.BUILDING, params, showLoader);
  }

  /**
   * Get building info by ID
   * @param id: BUILDING ID
   * @param {boolean} showLoader
   * @return Observable
   */
  public getByID(id, showLoader: boolean = true) {
    return this.apiService.restGET(`${this.apiUrl.BUILDING}/${id}`, null, showLoader);
  }

  /**
   * building
   * @param  params: Building
   * @return  Observable
   */
  public create(params) {
    return this.apiService.restPOST(this.apiUrl.BUILDING, params);
  }

  /**
   * building
   * @param  params: Building
   * @return  Observable
   */
  public update(buildingId, params) {
    return this.apiService.restPATCH(`${this.apiUrl.BUILDING}/${buildingId}`, params);
  }
}
