import { Injectable } from '@angular/core';

import { Configuration, TO_DO_TYPE } from '../constants';
import { ApiService } from './api.service';
import { Task } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * getList
   * @param  options
   * @return  Observable
   */
  public getList(options: any) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.TODO, params);
  }

  /**
   * getTaskCount
   * @return  Observable
   */
  public getTaskCount() {
    const params = { limit: 1, offset: 0};
    return this.apiService.restGET(this.apiUrl.TODO, params, false);
  }

  /**
   * create
   * @param {Task} task
   * @return  Observable
   */
  public create(task: Task) {
    const params = {
      name: task.comment,
      comment: task.comment,
      category: task.category,
      status: task.status
    };
    return this.apiService.restPOST(this.apiUrl.TODO, params);
  }

  /**
   * update
   * @param {Task} task
   * @return  Observable
   */
  public update(task: Task) {
    const params: any = {
      name: task.comment,
      comment: task.comment,
    };
    return this.apiService.restPATCH(`${this.apiUrl.TODO}/${task.id}`, params);
  }

  /**
   * complete
   * @param {Task} task
   * @return  Observable
   */
  public complete(task: Task) {
    const params: any = {
      status: TO_DO_TYPE.FINISHED
    };
    return this.apiService.restPATCH(`${this.apiUrl.TODO}/${task.id}`, params);
  }

}
