export class User {
  /**
   * id user id
   */
  public id: number;
  /**
   * name user name
   */
  public name: string;
  /**
   * email user email
   */
  public email: string;
  /**
   * password user password
   */
  public password: string;
  /**
   * role user role
   */
  public role: number;
  /**
   * active user active
   */
  public active: boolean;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.name = data.name || '';
    this.email = data.email || '';
    this.password = data.password || '';
    this.role = data.role && !isNaN(data.role) ? parseInt(data.role, 10) : 1;
    this.active = data.active || false;
  }
}
