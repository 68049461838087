<div class="modal-header">
    <h3 class="modal-title">{{ modalData?.title | translate}}</h3>
    <div class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </div>
</div>
<div class="modal-body">
    <app-glc-textarea
        isRequired
        [title]="'COMMENT'"
        [placeholder]="'COMMENT_PLACEHOLDER'"
        [name]="'comment'"
        [(value)]="modalData?.event.title"
        [(error)]="errors.comment"
        (modelChangeEvent)="validate('comment')"
        [rows]="5"
        [isDisabled]="!isAdmin"
    ></app-glc-textarea>
</div>
<div class="modal-footer" *ngIf="isAdmin">
    <button type="button" class="btn btn-outline-secondary" (click)="save()" [disabled]="isDisabledBtn()">
        {{ 'SAVE' | translate }}
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="delete()" *ngIf="modalData.action === configuration.EVENT_CALENDAR.ACTIONS.EDIT">
        {{ 'DELETE' | translate }}
    </button>
</div>