<app-glc-page-details
  [pageTitle]="'SERVICE_TEAMS'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_SERVICE_TEAMS'"
  (backPress)="backToServiceTeamPage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          isRequired
          [title]="'NAME'"
          [placeholder]="'NAME'"
          [name]="'name'"
          [(value)]="serviceTeam.name"
          [(error)]="errors.name"
          (modelChangeEvent)="isNameValid($event)"
          [isDisabled]="!isAdmin"
        ></app-glc-input>
        <app-glc-input
          isRequired
          [title]="'EMAIL'"
          [placeholder]="'EMAIL'"
          [name]="'email'"
          [(value)]="serviceTeam.email"
          [(error)]="errors.email"
          (modelChangeEvent)="isEmailValid($event)"
          [isDisabled]="!isAdmin"
        ></app-glc-input>
        <app-glc-input
          isRequired
          [title]="'TEL'"
          [placeholder]="'TEL'"
          [name]="'tel'"
          [(value)]="serviceTeam.telephone"
          [(error)]="errors.telephone"
          (modelChangeEvent)="isTelValid($event)"
          [isDisabled]="!isAdmin"
        ></app-glc-input>
        <app-glc-select
          [title]="'SERVICE_TEAM_TYPE'" 
          [(selectedItem)]="serviceTeam.in_house"
          [valueKey]="'id'"
          [textKey]="'text'"
          [(dataSource)]="listType"
          [isDisabled]="!isAdmin || serviceTeam.assigned > 0"
        ></app-glc-select>
        <div *ngIf="isAdmin">
          <button
            mat-button
            class="btn btn-primary pull-right"
            (click)="save()"
            [disabled]="isDisabledBtn()"
          >
            {{ !serviceTeam.id ? ('ADD_NEW_TEAM' | translate) : ('SAVE' | translate) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-glc-page-details>

  