import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class MessageEventService {
    private subject = new Subject<any>();

    sendMessage(message: string, data: any = null) {
        this.subject.next({ message, data});
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
