import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Configuration } from 'src/app/constants';
import { FieldError, Event } from 'src/app/models';
import { Common, Validator } from 'src/app/services';

@Component({
  selector: 'app-glc-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  @Input() modalData: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();

  configuration = Configuration;
  isAdmin = false;
  public event: Event = new Event();
  public eventBK: Event = new Event();

  // Declare validation
  errors: any = {
    comment: new FieldError(),
  };

  constructor(
    private common: Common,
    private validator: Validator,
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
  }

  close() {
    this.closeModal.emit();
  }

  save() {
    this.saveEvent.emit();
  }

  delete() {
    this.deleteEvent.emit();
  }

  validate(field: string = null) {
    this.event.comment = this.modalData.event.title;
    this.validator.validate(field, this.event, this.errors);
  }

  isDisabledBtn() {
    const isChanged = this.common.isDifferentObject(this.event, this.eventBK);
    const result = this.event.validate();
    return !isChanged || result !== '';
  }
}
