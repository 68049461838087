import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Common } from './common.service';
import { RESPONSE_TYPE } from '../constants';

@Injectable()
export class ApiService {
  constructor(
    public http: HttpClient,
    public common: Common
  ) {
  }

  /**
   * @function restGET
   * @description Http Get
   * @param {string} url
   * @param params
   * @param {boolean} showLoader
   * @return Observable<any>
   */
  public restGET(url: string, params?: any, showLoader: boolean = true) {
    if (showLoader) {
      this.common.showLoader();
    }
    const bindingData = this.bindHeaderAndParams(params);
    return this.http.get(url, { params: bindingData.params, headers: bindingData.headers });
  }

  /**
   * @function restPOST
   * @description handle http post
   * @param {string} url
   * @param params
   * @param {boolean} showLoader
   * @return Observable<any>
   */
  public restPOST(url: string, params?: any, showLoader: boolean = true) {
    if (showLoader) {
      this.common.showLoader();
    }
    const bindingData = this.bindHeaderAndParams(params);
    return this.http.post(url, bindingData.params, { headers: bindingData.headers });
  }

  /**
   * @function restPUT
   * @description handle http put
   * @param {string} url
   * @param params
   * @param {boolean} showLoader
   * @return Observable<any>
   */
  public restPUT(url: string, params?: any, showLoader: boolean = true) {
    if (showLoader) {
      this.common.showLoader();
    }
    const bindingData = this.bindHeaderAndParams(params);
    return this.http.put(url, bindingData.params, { headers: bindingData.headers });
  }

  /**
   * @function restDELETE
   * @description handle http delete
   * @param {string} url
   * @param params
   * @param {boolean} showLoader
   * @return Observable<any>
   */
  public restDELETE(url: string, params?: any, showLoader: boolean = true) {
    if (showLoader) {
      this.common.showLoader();
    }
    const httpOptions = {
      headers: {},
      body: params
  };

    return this.http.delete(url, httpOptions);
  }

  /**
   * @function restPATCH
   * @description handle http patch
   * @param {string} url
   * @param params
   * @param {boolean} showLoader
   * @return Observable<any>
   */
  public restPATCH(url: string, params?: any, showLoader: boolean = true) {
    if (showLoader) {
      this.common.showLoader();
    }

    const bindingData = this.bindHeaderAndParams(params);
    return this.http.patch(url, bindingData.params, { headers: bindingData.headers });
  }

  public restGetFile(url: string, params?: any, showLoader: boolean = true) {
    if (showLoader) {
      this.common.showLoader();
    }

    const bindingData  = this.bindHeaderAndParams( params );
    const options: any = { params: bindingData.params, headers: bindingData.headers, responseType: RESPONSE_TYPE.Blob.toString() };
    return this.http.get( url, options ).pipe(map(this.handleFileCSV.bind( this )));
  }

  /**
   * allows generating the header and params
   * @param {HttpParams} params
   * @return params: HttpParams; headers: {}
   */
  private bindHeaderAndParams(params: HttpParams)
    : { params: HttpParams, headers: {} } {
    const headers: any = {};

    // Allow custom header
    return { params, headers };
  }

  /**
   * allows to append into Http params option
   * @param paramsOptions
   * @return HttpParams
   */
  public generateHttpParams(paramsOptions: any) {
    let httpParams = new HttpParams();
    if (!paramsOptions) {
      return httpParams;
    }
    for (const name in paramsOptions) {
      if (name && paramsOptions.hasOwnProperty(name) && name.length) {
        httpParams = httpParams.append(name, paramsOptions[name]);
      }
    }
    return httpParams;
  }

  /**
   * handle response CSV file
   * @param response
   */
  private handleFileCSV( response: any ): object {
    if ( response ) {
      const contentType = 'text/csv';
      return {
        blob: new Blob( [ response ], { type: contentType } )
      };
    }
  }
}
