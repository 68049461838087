<label class="tab-label">
  {{ 'ALARM_HISTORY' | translate }}
</label>
<div class="mt-10">
  <div class="row">
    <div class="col-md-12">
      <div class="box-body">
        <app-glc-table
          *ngIf="tableData"
          [data]="tableData"
          (page)="pageChange($event)"
          (sort)="sortChange($event)"
        ></app-glc-table>
      </div>
    </div>
  </div>
</div>
