<div class="form-group" [ngClass]="{'has-icon': matIcon } ">
  <i *ngIf="matIcon" class="input-icon material-icons">
    {{ matIcon }}
  </i>
  <mat-form-field
    [ngClass]="{'mat-form-field-invalid': error && error.isInvalid }"
    [floatLabel]="floatLabel"
    [hideRequiredMarker]="isHideRequireMarker"
  >
    <mat-label>{{ title | translate }}</mat-label>
    <mat-select
      [(value)]="selectedItem"
      [disabled]="isDisabled"
      (ngModelChange)="onModelChangeEvent($event)"
      placeholder="{{ placeholder | translate }}"
      [required]="isRequired"
    >
      <mat-option *ngFor="let item of dataSource" [value]="item[valueKey]">
        {{  item[textKey] | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error *ngIf="error && error.isInvalid">
    {{ error.message | translate }}
  </mat-error>
</div>