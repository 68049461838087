<div class="form-group" [ngClass]="{'has-icon': matIcon } ">
  <i *ngIf="matIcon" class="input-icon material-icons">
    {{ matIcon }}
  </i>
  <mat-form-field
    [ngClass]="{'mat-form-field-invalid': error && error.isInvalid }"
    [floatLabel]="floatLabel"
    [hideRequiredMarker]="isHideRequireMarker"
  >
    <mat-label>{{ title | translate }}</mat-label>
    <input 
      matInput
      [attr.type]="type"
      name="{{ name }}"
      maxlength="{{ maxlength }}"
      [disabled]="isDisabled"
      [(ngModel)]="value"
      (ngModelChange)="onModelChangeEvent($event)"
      (keydown)='onKeydownEvent($event)'
      placeholder="{{ placeholder | translate }}"
      autocomplete="new-password"
      (keyup.enter)="enterEvent($event)"
      [required]="isRequired"
    >
    <span *ngIf="hasEye" class="has-eye-icon" (click)="changeType()">
      <span *ngIf="type === 'password'">
        <i class="eye-icon far fa-eye"></i>
      </span>
      <span *ngIf="type === 'text'">
        <i class="eye-icon far fa-eye-slash"></i>
      </span>
    </span>
  </mat-form-field>
  <mat-error *ngIf="error && error.isInvalid">
    {{ error.message | translate }}
  </mat-error>
</div>