import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldError } from 'src/app/models';

@Component({
  selector: 'app-glc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  private inputValue;
  private typeValue = 'text';
  private disableValue = false;
  private displayPlaceholder = '';
  private displayTitle = '';
  private matIconDisplay = '';
  private hasEyeValue = false;

  @Input() error: FieldError;
  @Input() inputFormControl: FormControl = new FormControl('', []);
  @Input() isRequired = false;
  @Input() isHideRequireMarker = false;
  @Input() floatLabel = 'always';
  @Input() name = '';
  @Input() maxlength = 50;

  // title (2-ways binding)
  @Output() titleChange = new EventEmitter();
  @Input()
  get title() {
    return this.displayTitle;
  }
  set title(val) {
    this.displayTitle = val;
    this.titleChange.emit(this.displayTitle);
  }

  // placeholder (2-ways binding)
  @Output() placeholderChange = new EventEmitter();
  @Input()
  get placeholder() {
    return this.displayPlaceholder;
  }
  set placeholder(val) {
    this.displayPlaceholder = val;
    this.placeholderChange.emit(this.displayPlaceholder);
  }

   // type (2-ways binding)
   @Output() typeChange = new EventEmitter();
   @Input()
   get type() {
     return this.typeValue;
   }
   set type(val) {
     this.typeValue = val;
     this.typeChange.emit(this.typeValue);
   }

  // value (2-ways binding)
  @Output() valueChange = new EventEmitter();
  @Input()
  get value() {
    return this.inputValue;
  }
  set value(val) {
    this.inputValue = val;
    this.valueChange.emit(this.inputValue);
  }

  // isDisabled (2-ways binding)
  @Output() isDisabledChange = new EventEmitter();
  @Input()
  get isDisabled() {
    return this.disableValue;
  }
  set isDisabled(val) {
    this.disableValue = val;
    this.isDisabledChange.emit(this.disableValue);
  }

  // matIcon (2-ways binding)
  @Output() matIconChange = new EventEmitter();
  @Input()
  get matIcon() {
    return this.matIconDisplay;
  }
  set matIcon(val) {
    this.matIconDisplay = val;
    this.matIconChange.emit(this.matIconDisplay);
  }

  // hasEye (2-ways binding)
  @Output() hasEyeChange = new EventEmitter();
  @Input()
  get hasEye() {
    return this.hasEyeValue;
  }
  set hasEye(val) {
    this.hasEyeValue = val;
    this.hasEyeChange.emit(this.hasEyeValue);
  }


  @Output() modelChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() keydownEvent: EventEmitter<any> = new EventEmitter();
  @Output() enter: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  public onModelChangeEvent(event) {
    this.modelChangeEvent.emit(event);
  }
  public onKeydownEvent(event) {
    this.keydownEvent.emit(event);
  }
  public enterEvent(event) {
    this.enter.emit(event);
  }

  public changeType() {
    if (this.hasEye) {
      switch (this.type) {
        case 'text':
          this.type = 'password';
          break;
        case 'password':
          this.type = 'text';
          break;
      }
    }
  }
}
