import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Common } from '../../services/common.service';
import { Configuration, TABLE_SETTINGS } from 'src/app/constants';
import { BuildingService } from 'src/app/services';
import { Router } from '@angular/router';
import { Building } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-glc-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit, AfterViewInit {
  tableData: any;

  buildingRoute = Configuration.ROUTE_PATH.BUILDINGS;
  isAdmin = false;
  multipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  pageDescription = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    id: '01',
    date: '01.08.2019',
    name: 'DUSK Property Management ZH',
    mieter: 'Phillip Strommacher',
    service: '---',
    status: 'Auftrag erhalten. In Bearbeitung',
    price: 'CHF 0',
  }, {
    id: '01',
    date: '01.08.2019',
    name: 'DUSK Property Management ZH',
    mieter: 'Samantha Bee',
    service: '05.08.19',
    status: 'Serviceterminanfrage verschickt',
    price: 'CHF 100',
  }, {
    id: '01',
    date: '01.08.2019',
    name: 'Immobilien AG ZH',
    mieter: 'Rebeca Landdorf',
    service: '18.08.19',
    status: 'Reparaturkosten abgelehnt!',
    price: 'CHF 700',
  }, {
    id: '01',
    date: '02.08.2019',
    name: 'DUSK Property Management ZH',
    mieter: 'Jason Rhodes',
    service: '12.08.19',
    status: 'Warten auf Servicetermin',
    price: 'CHF 200',
  },
  {
    id: '01',
    date: '02.08.2019',
    name: 'Immobilien AG ZH',
    mieter: 'John Patrick Adelhardt',
    service: '08.08.19',
    status: 'Reparaturkostenanfrage geschickt',
    price: 'CHF 1298',
  }];

  @ViewChild('content', {static: false}) content: ElementRef;

  constructor(
    private common: Common,
    private buildingService: BuildingService,
    private router: Router,
    private modalService: NgbModal
  ) {
  }

  open(content) {
    this.modalService.open(content, {size: 'lg'}).result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    this.pageDescription = this.isAdmin ? 'BUILDING_DESCRIPTION' : '';
    this.initTableData();
  }

  initTableData() {
    const displayedColumns = ['id', 'date', 'name', 'mieter', 'service', 'status', 'price', 'customEvent'];
    const tableData = {
      displayedColumns,
      field: [
        { name: 'id', title: 'ID' },
        { name: 'date', title: 'Datum' },
        { name: 'name', title: 'Bewirtschaftungsfirma' },
        { name: 'mieter', title: 'Mieter' },
        { name: 'service', title: 'Servicetermin' },
        { name: 'status', title: 'Status' },
        { name: 'price', title: 'Reparaturkosten' },
        { name: 'customEvent', title: 'ACTIONS', customAction: true, sortable: false }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };

    this.tableData = tableData;
  }

  ngAfterViewInit() {
    this.getBuildingList();
  }

  getBuildingList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });

    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.customEvent = {
        text: 'Details',
        icon: 'pencil-alt',
        event: () => {
          this.open(this.content);
        }
      };
      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  applyFilter(_filterValue: string) {
    this.getBuildingList();
  }

  pageChange(event) {
    console.log(event);
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    this.getBuildingList();
  }

  createNewUser(id: number) {
    return {
      id: id.toString(),
      name: 'Building ' + id.toString(),
      location: 'Location ' + id.toString(),
      street: 'Bubikon sw ' + id.toString(),
      zipcode: '6068',
      city: 'Bubikon',
      action: [
        {
          text: 'View',
          icon: 'eye',
          url: `/${this.buildingRoute}/${id}`
        }
      ],
      imgUrl: 'http://www.mbci.com/assets/0/72/126/128/514/1630/9f876320-3c08-4abc-9022-f82e7911275b.jpg',
      selected: false
    };
  }

  addNew() {
    this.router.navigate([`/${this.buildingRoute}/create`]);
  }

}
