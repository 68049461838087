<div class="form-group" [ngClass]="{ 'has-error': error && error.isInvalid}">
    <label class="custom-label">{{ title | translate}} <span class="required" *ngIf="isRequired">*</span></label>
    <textarea 
      matInput
      [attr.rows]="rows"
      name="{{ name }}"
      [disabled]="isDisabled"
      [(ngModel)]="value"
      (ngModelChange)="onModelChangeEvent($event)"
      placeholder="{{ placeholder | translate }}"
      [required]="isRequired"
    ></textarea>
    <div class="error required" *ngIf="error && error.isInvalid">{{ error.message | translate }}</div>
</div>