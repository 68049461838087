import { Component, OnInit } from '@angular/core';
import { Configuration, TABLE_SETTINGS } from '../../constants';
import { Common, AlarmService } from '../../services';
import * as moment from 'moment';

@Component({
  selector: 'app-glc-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})
export class AlarmComponent implements OnInit {
  route = Configuration.ROUTE_PATH;

  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private alarmService: AlarmService
  ) { }

  ngOnInit() {
    this.initTable();
    this.getAlarmList();
  }

  initTable() {
    const tableDisplayColumns = this.common.getListColumns(TABLE_SETTINGS.TABLE_NAME.ALARMS);
    let displayedColumns = ['building_name'];
    displayedColumns = displayedColumns.concat(tableDisplayColumns);

    this.tableData = {
      displayedColumns,
      field: [
        { name: 'building_name', title: 'BUILDING' },
        { name: 'function_name', title: 'FUNCTION' },
        { name: 'device_name', title: 'DEVICE' },
        { name: 'deactivated_by', title: 'DEACTIVATED_BY' },
        { name: 'date', title: 'DATE' },
        { name: 'time', title: 'TIME' },
        { name: 'comment', title: 'COMMENT' },
        { name: 'action', title: 'ACTIONS', link: true }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  getAlarmList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.alarmService.getList(options)
        .subscribe(
          (response: any) => {
            const results = response.results.map(item => this.mapAlarmData(item));

            this.tableData = {
              ...this.tableData,
              pageIndex: this.pageOffset,
              totalRecords: response.count,
              dataSource: results
            };

            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  mapAlarmData(data: any) {
    data.date = moment.utc(data.date_time).format(Configuration.DATE_FORMAT);
    data.time = moment.utc(data.date_time).format(Configuration.TIME_FORMAT_WITHOUT_SECOND);
    const result = {
      ...data,
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: `${data.id}`
        }
      ],
      selected: false
    };
    return result;
  }

  applyFilter(_filterValue: string) {
    this.getAlarmList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.getAlarmList();
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'date':
        this.sortValue.active = 'date_time';
        break;
      case 'time':
        this.sortValue.active = 'date_time';
        break;
      case 'building_name':
        this.sortValue.active = 'building__name';
        break;
      case 'function_name':
        this.sortValue.active = 'function__name';
        break;
      case 'device_name':
        this.sortValue.active = 'device__name';
        break;
    }
    this.getAlarmList();
  }

}
