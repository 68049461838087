export class ServiceTeam {

  /**
   * id
   */
  public id: number;

  /**
   * email
   */
  public email: string;

  /**
   * name
   */
  public name: string;

  /**
   * tel
   */
  public telephone: string;

  /**
   * team
   */
  // tslint:disable-next-line:variable-name
  public in_house: string;

  /**
   * team
   */
  // tslint:disable-next-line:variable-name
  public assigned: number;


  public constructor(data: any = {}) {
      data = (data === null) ? {} : data;
      this.id = data.id || null;
      this.email = data.email || '';
      this.name = data.name || '';
      this.telephone = data.telephone || '';
      this.in_house = data.in_house || 'I';
      this.assigned = data.assigned || 0;
  }
}
