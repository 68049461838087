import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  UserService,
  ServiceTeamService,
  BuildingService,
  DeviceService,
  Validator,
  Common,
  DeviceControlService,
  CalendarService
} from '../../../services';
import { ControlProtocol, FieldError } from '../../../models';
import { Configuration, TOASTER_TYPE, CONTROL_PROTOCOL_TYPE } from '../../../constants';

@Component({
  selector: 'app-glc-device-control-detail',
  templateUrl: './device-control-detail.component.html',
  styleUrls: ['./device-control-detail.component.scss']
})
export class DeviceControlDetailComponent implements OnInit {
  isAdminOrSuperUser = false;
  disabledDates = [];

  routePath = Configuration.ROUTE_PATH;
  public control: ControlProtocol = new ControlProtocol();
  public controlBK: ControlProtocol = new ControlProtocol();

  building: any = {
    name: ''
  };

  currentUser: any = {
    name: ''
  };

  controlID: number;
  deviceID: number;

  addTodoListOption = {
    value: CONTROL_PROTOCOL_TYPE.TODO,
    text: 'ADD_TO_TODO_LIST'
  };
  workCompleteOption = {
    value: CONTROL_PROTOCOL_TYPE.WORK_COMPLETE,
    text: 'WORK_COMPLETED'
  };

  // Declare validation
  errors: any = {
    date_of_control: new FieldError(),
    service_team: new FieldError(),
    person_contact: new FieldError(),
    date_of_appointment: new FieldError(),
    add_todo: new FieldError(),
    done_by: new FieldError(),
    date_of_signature: new FieldError(),
    inform_days: new FieldError()
  };

  isChanged: boolean;
  doneByBK: string;

  // Declare services
  privateBuildingService: any;
  privateUserService: any;
  privateTeamService: any;
  privateDeviceService: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
    private validator: Validator,
    private deviceService: DeviceService,
    private buildingService: BuildingService,
    private userService: UserService,
    private teamService: ServiceTeamService,
    private deviceControlService: DeviceControlService,
    private calendarService: CalendarService
  ) { }

  ngOnInit() {
    this.initVariable();
    this.initData();
  }

  initVariable() {
    this.isAdminOrSuperUser = this.common.isAdminOrSuperUser();
    this.privateBuildingService = this.buildingService;
    this.privateUserService = this.userService;
    this.privateTeamService = this.teamService;
    this.privateDeviceService = this.deviceService;
  }

  initData() {
    try {
      this.deviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));
      this.controlID = Number(this.activatedRoute.snapshot.paramMap.get('id'));

      if (this.controlID) {
        this.deviceControlService.getByID(this.controlID).subscribe((response: any) => {
          this.control = new ControlProtocol(response);
          this.controlBK = this.common.cloneDeep(this.control);
          this.isChanged = false;
        });
      }
      this.preloadFields();
    } catch (e) {
      this.backToDeviceDetail();
    }
  }

  validate(field: string = null) {
    this.validator.validate(field, this.control, this.errors);
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.controlBK, this.control);
    const result = this.control.validate();
    return result !== '';
  }

  public backToDeviceDetail() {
    this.common.navigate(`/${this.routePath.DEVICES}/${this.deviceID}/${this.routePath.DEVICE_CONTROL_TAB}`);
  }

  public save() {
    if (this.isDisabledBtn() || !this.isChanged || !this.controlBK.add_todo) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        if (this.control.id) {
          this.update();
        } else {
          this.create();
        }
      }
    );
  }

  create() {
    try {
      if (this.control.validate() === '') {
        this.deviceControlService.create(this.control)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('CREATE'),
                this.common.translate('CREATE_SUCCESSFUL')
              );
              this.backToDeviceDetail();
            });
      }
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  update() {
    try {
      if (this.control.validate() === '') {
        this.deviceControlService.update(this.control)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('UPDATE'),
                this.common.translate('UPDATE_SUCCESSFUL')
              );
              this.backToDeviceDetail();
            });
      }
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  public preloadFields() {
    const currentLoginUserName = this.common.getCurrentLoginUserName();
    // prefill User
    this.currentUser.name = currentLoginUserName;
    this.doneByBK = currentLoginUserName;

    // get service details
    this.deviceService.getByID(this.deviceID).subscribe((response: any) => {
      // prefill building
      this.building.name = response.building ? response.building.name : '';
      // prefill Service Team and Person of contact
      if (!this.controlID) {
        this.control = new ControlProtocol({
          service_team: response.external_team ? response.external_team.name : '',
          person_contact: response.external_team ? response.external_team.name : '',
          device_id: this.deviceID
        });
      }
      this.common.hideLoader();
    });

    this.calendarService.getList({
      type: Configuration.CALENDAR.TYPE.CONTROL,
      device_id: this.deviceID
    }).subscribe((response: any) => {
      this.common.hideLoader();
      this.processEvents(response);
    });
  }

  processEvents(data) {
    if (data && data.length > 0) {
      _.forEach(data, (item) => {
        const date_object = moment.utc(item.event_time).format(Configuration.DATE_FORMAT);
        this.disabledDates.push(date_object);
      });
    }
  }

  public radioChange(_event) {
    this.control.date_of_signature = null;
    this.errors.done_by = new FieldError();
    this.errors.date_of_signature = new FieldError();

    if (this.control.add_todo !== this.workCompleteOption.value) {
      this.control.done_by = this.doneByBK;
    } else {
      this.doneByBK = this.control.done_by;
      this.control.done_by = '';
    }
  }

  public onChangeAddToCalendar(event) {
    if (!event) {
      this.control.date_of_appointment = null;
      this.errors.date_of_appointment = new FieldError();
    }
  }
}
