export class Login {
  public email: string;
  public password: string;
  public isRemember: boolean;

  /**
   * @ignore
   */
  constructor( info: any = {} ) {
    this.email = info && info.email ? info.email : '';
    this.password = info && info.password ? info.password : '';
    this.isRemember = info.isRemember || false;
  }
}
