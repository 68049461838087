import { Injectable } from '@angular/core';
import { Configuration } from '../constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalTeamService {

  apiUrl = Configuration.API_CONFIG.API_URL.SERVICE;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * getList
   * @param  options
   * @param {boolean} showLoader
   * @return  Observable
   */
  public getList(options: any, showLoader: boolean = true) {
    let params = options || null;
    params = {
      in_house: 'E'
    };
    return this.apiService.restGET(this.apiUrl, params, showLoader);
  }
}
