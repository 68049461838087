
<div class="form-group" [ngClass]="{ 'has-error': error && error.isInvalid}">
    <label class="custom-label">{{ title | translate}} <span class="required" *ngIf="isRequired">*</span></label>
    <ng-select 
    [items]="data | async"
    [bindLabel]="textKey"
    [addTag]="addTag"
    [bindValue]="valueKey"
    [loading]="loading"
    [typeahead]="dataInput"
    [(ngModel)]="value"
    [trackByFn]="trackByFn"
    [hideSelected]="true"
    placeholder="{{ placeholder | translate }}"
    (ngModelChange)="onModelChangeEvent($event)"
    [disabled]="isDisabled"
    [multiple]="multiple">
    </ng-select>
    <div class="error required" *ngIf="error && error.isInvalid">{{ error.message | translate }}</div>
</div>