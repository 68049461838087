import * as Validator from 'validatorjs';

export class Event {
  /**
   * ID - auto increment
   */
  public id: number;
  /**
   * Comment
   */
  public comment: string;
  /**
   * Event time
   */
  public event_time: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.comment = data.comment || null;
    this.event_time = data.event_time || null;
  }

  /**
   * Validate model when create or update
   */
  validate(field = null, editing = false): any {
    const rules = {
      comment: `required`
    };

    const validation = new Validator(this, rules, {
      'required.comment': 'COMMENT_IS_REQUIRED',
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
