import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldError, Building } from 'src/app/models';
import { TOASTER_TYPE, Configuration, FILE_TYPE } from 'src/app/constants';
import { Common, FunctionService, Validator } from 'src/app/services';
import { BuildingService } from 'src/app/services';
import * as _ from 'lodash';

@Component({
  selector: 'app-glc-building-detail',
  templateUrl: './building-detail.component.html',
  styleUrls: ['./building-detail.component.scss']
})
export class BuildingDetailComponent implements OnInit {
  public isChanged = false;
  public buildingForm: Building = new Building();
  public buildingFormBk: Building = new Building();

  pageDescription = 'BUILDING_DETAIL';
  id = 0;
  isAdmin = false;
  buildingImage = [];
  buildingRoute = Configuration.ROUTE_PATH.BUILDINGS;
  fileTypes = FILE_TYPE.BUILDING;
  validImage = true;

  toasterType = TOASTER_TYPE;
  errors: any = {
    name: new FieldError(),
    location: new FieldError(),
    street: new FieldError(),
    zip: new FieldError(),
    city: new FieldError(),
    function: new FieldError()
  };

  // Declare services
  privateFunctionService: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
    private buildingService: BuildingService,
    private functionService: FunctionService,
    private validator: Validator
  ) { }

  ngOnInit() {
    this.privateFunctionService = this.functionService;
    this.isAdmin = this.common.isAdmin();
    try {
      this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      if (this.id) {
        this.pageDescription = 'BUILDING_DETAIL';
        this.initData();
      } else {
        this.pageDescription = 'BUILDING_ADD';
      }
    } catch (e) {
      this.backToBuildingPage();
    }
  }

  initData() {
    try {
      this.buildingService.getByID(this.id).subscribe((response: any) => {
        this.common.hideLoader();
        this.buildingForm = new Building(response);
        this.buildingFormBk = _.cloneDeep(this.buildingForm);
      });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  validateImage() {
    if (this.id) {
      return this.validImage;
    } else {
      if (this.buildingImage.length < 1) {
        return false;
      } else {
        return this.validImage;
      }
    }
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.buildingFormBk, this.buildingForm);
    if (
      !this.isChanged ||
      !this.buildingForm.name ||
      !this.buildingForm.location ||
      !this.buildingForm.street ||
      !this.buildingForm.zipcode ||
      !this.buildingForm.city ||
      !this.buildingForm.function ||
      !this.validateImage()
    ) {
      return true;
    }
  }

  save() {
    if (!this.isAdmin || this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        this.common.showLoader();
        if (this.id) {
          this.update();
        } else {
          this.create();
        }
      }
    );
  }

  create() {
    const formData = new FormData();
    formData.append('image', this.buildingImage[0], this.buildingImage[0].name);
    formData.append('name', this.buildingForm.name);
    formData.append('location', this.buildingForm.location);
    formData.append('street', this.buildingForm.street);
    formData.append('zipcode', this.buildingForm.zipcode);
    formData.append('city', this.buildingForm.city);
    // Process function before post to BE
    const functions = this.prepareFunction();
    formData.append('function', JSON.stringify(functions));

    try {
      this.buildingService.create(formData)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('CREATE'),
              this.common.translate('CREATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.common.hideLoader();
            this.backToBuildingPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  update() {
    const formData = new FormData();
    if (this.buildingImage.length) {
      formData.append('image', this.buildingImage[0], this.buildingImage[0].name);
    }
    formData.append('name', this.buildingForm.name);
    formData.append('location', this.buildingForm.location);
    formData.append('street', this.buildingForm.street);
    formData.append('zipcode', this.buildingForm.zipcode);
    formData.append('city', this.buildingForm.city);
    // Process function before post to BE
    const functions = this.prepareFunction();
    formData.append('function', JSON.stringify(functions));

    try {
      this.buildingService.update(this.buildingForm.id, formData)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.common.hideLoader();
            this.backToBuildingPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  handleFileInput(error: boolean) {
    this.validImage = !error;
  }

  backToBuildingPage() {
    this.common.navigate(`/${this.buildingRoute}`);
  }

  validate(field: string = null) {
    this.validator.validate(field, this.buildingForm, this.errors);
  }

  prepareFunction()  {
    return this.buildingForm.function.map(item => {
      return item.id;
    });
  }
}
