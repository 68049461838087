import { Injectable } from '@angular/core';
import { Configuration } from '../constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AlarmService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get all alarm histories
   * @param options: page, size, key
   * @param {boolean} showLoader
   * @return Alarm history list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.ALARM, params, showLoader);
  }

  /**
   * Get all alarm histories by device ID
   * @param deviceID: device ID
   * @param options: page, size, key
   * @param {boolean} showLoader
   * @return Alarm history list
   */
  public getListByID(deviceID: number, options: any, showLoader: boolean = true) {
    const params = options || null;
    params.device_id = deviceID;
    return this.apiService.restGET(this.apiUrl.ALARM, params, showLoader);
  }

  /**
   * Get alarm history ID
   * @param alarmID: alarm ID
   * @param {boolean} showLoader
   * @return Alarm history list
   */
  public getByID(alarmID: number, showLoader: boolean = true) {
    return this.apiService.restGET(`${this.apiUrl.ALARM}/${alarmID}`, showLoader);
  }
}
