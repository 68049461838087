import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Configuration, USER_ROLE, MESSAGE_EVENT } from '../../constants';
import { Credential } from '../../models';
import { Subscription } from 'rxjs';
import { Common } from '../../services/common.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  public routePath = Configuration.ROUTE_PATH;
  public credentials: Credential = new Credential();
  public enableUserMenu = false;

  subscription: Subscription;
  taskCount = 0;

  version = Configuration.APP_VERSION;
  public translateService;

  constructor(
    public translate: TranslateService,
    private common: Common
  ) {
    this.translateService = translate;
   }

  ngOnInit() {
    this.checkEnableMenuUser();
    this.initSubscriptionEvent();
  }

  ngAfterViewInit() {
  }

  changeLang() {
    const currentLang = this.translateService.currentLang;
    if (currentLang === 'en') {
      this.translateService.use('de');
    } else {
      this.translateService.use('en');
    }
  }

  logout() {
    this.common.handleLogoutAction();
  }

  checkEnableMenuUser() {
    this.credentials = this.common.getCredentials();
    this.enableUserMenu = !!(this.credentials.role === USER_ROLE.ADMINISTRATOR || this.credentials.role === USER_ROLE.SUPER_USER);
  }

  initSubscriptionEvent() {
    // subscribe tasks count events
    this.subscription = this.common.handleEventMessage().subscribe(event => {
      const message = event.message;
      const data = event.data;
      if (MESSAGE_EVENT.UPDATE_MENU_TASK_COUNT === message) {
        this.taskCount = data;
      }
    });
  }

}
