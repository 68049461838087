export * from './dashboard/dashboard.component';
export * from './login/login.component';
export * from './page-not-found/page-not-found.component';
export * from './settings/settings.component';
export * from './building/building.component';
export * from './building/building-detail/building-detail.component';
export * from './users/users.component';
export * from './users/user-detail/user-detail.component';
export * from './functions/functions.component';
export * from './functions/function-detail/function-detail.component';
export * from './event-calendar/event-calendar.component';
export * from './service-team/service-team.component';
export * from './service-team/service-team-detail/service-team-detail.component';
export * from './devices/devices.component';
export * from './devices/device-detail/device-detail.component';
export * from './devices/device-control-detail/device-control-detail.component';
export * from './devices/device-detail-tabs/device-detail-tabs.component';
export * from './devices/device-detail-tabs/info-tab/info-tab.component';
export * from './devices/device-detail-tabs/documents-tab/documents-tab.component';
export * from './devices/device-detail-tabs/service-tab/service-tab.component';
export * from './devices/device-detail-tabs/control-tab/control-tab.component';
export * from './devices/device-detail-tabs/alarms-tab/alarms-tab.component';
export * from './devices/device-detail-tabs/documents-tab/document-detail/document-detail.component';
export * from './alarm/alarm.component';
export * from './alarm/alarm-detail/alarm-detail.component';
export * from './task/task.component';
export * from './task/task-detail/task-detail.component';
export * from './devices/device-detail-tabs/service-tab/service-detail/service-detail.component';
