<app-glc-page-details
[pageTitle]="'DEVICE_DETAILS'"
[(pageDescription)]="pageDescription"
[backBtnText]="'BACK_TO_DEVICES'"
(backPress)="backToDevicesPage()"
>
    <div class="function-bar">
        <button
        class="mat-stroked-button btn"
        (click)="backToDevicesPage()"
        >
            <i class="fas fa-long-arrow-alt-left"></i>
            {{ 'BACK_TO_DEVICES' | translate }}
        </button>
    </div>
    <app-glc-tabs [tabList]="tabList">
    </app-glc-tabs>
</app-glc-page-details>