import { Component, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

import { Common, SettingService } from '../../services';
import { TOASTER_TYPE, TABLE_SETTINGS } from '../../constants';

@Component({
  selector: 'app-glc-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  private tableName = cloneDeep(TABLE_SETTINGS.TABLE_NAME);


  isAdmin = false;
  currentSelectedTab = 1;
  tabIndex: any = {
    buildingTab: 1,
    devicesTab: 2,
    alarmsTab: 3,
    serviceTeamsTab: 4
  };
  tableList = [];

  constructor(
    private common: Common,
    private settingService: SettingService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    if (this.isAdmin) {
      this.tabIndex = {
        ...this.tabIndex,
        usersTab: 5
      };
    }
    this.getTableOptions();
  }

  public onTabChanged(index) {
    this.currentSelectedTab = index;
  }

  public onModelChange(data) {
    console.log(data);
    const uploadData: any = {};

    this.tableList.forEach(item => {
      uploadData[item.tabId] = JSON.stringify(item.columns);
    });

    this.update(uploadData);
  }

  private update(data) {
    try {
      this.settingService.update(data)
        .subscribe(
          (response: any) => {
            console.log(response);
            const credentials = this.common.getCredentials();
            credentials.settings = data;
            this.common.storeCredentials(credentials);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.common.hideLoader();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  private getOptions(data: any) {
    const basicSettings = [
      {
        tabIndex: this.tabIndex.buildingTab,
        tableName: 'BUILDINGS',
        tableSettings: 'BUILDING_SETTINGS',
        tabId: this.tableName.BUILDINGS,
        columns: this.common.mergeTableSettings(data, this.tableName.BUILDINGS)
      },
      {
        tabIndex: this.tabIndex.devicesTab,
        tableName: 'DEVICES',
        tableSettings: 'DEVICES_SETTINGS',
        tabId: this.tableName.DEVICES,
        columns: this.common.mergeTableSettings(data, this.tableName.DEVICES)
      },
      {
        tabIndex: this.tabIndex.alarmsTab,
        tableName: 'ALARMS',
        tableSettings: 'ALARMS_SETTINGS',
        tabId: this.tableName.ALARMS,
        columns: this.common.mergeTableSettings(data, this.tableName.ALARMS)
      },
      {
        tabIndex: this.tabIndex.serviceTeamsTab,
        tableName: 'SERVICE_TEAMS',
        tableSettings: 'SERVICE_TEAMS_SETTINGS',
        tabId: this.tableName.SERVICE_TEAMS,
        columns: this.common.mergeTableSettings(data, this.tableName.SERVICE_TEAMS)
      }
    ];

    if (this.isAdmin) {
      basicSettings.push({
        tabIndex: this.tabIndex.usersTab,
        tableName: 'USERS',
        tableSettings: 'USERS_SETTINGS',
        tabId: this.tableName.USERS,
        columns: this.common.mergeTableSettings(data, this.tableName.USERS)
      });
    }

    return basicSettings;
  }

  private getTableOptions() {
    const credentials = this.common.getCredentials();
    const settings = credentials.settings;
    this.tableList = this.getOptions(settings);
  }
}
