<app-glc-page-content [pageTitle]="'Objektliste'" [pageDescription]="pageDescription">
  <div class="block-page-header">
    <div class="title">
      <h1>Serviceaufträge</h1>
      <p>Ihre Benutzerübersicht</p>
    </div>
    <ul class="menu-style-1">
      <li>
        <a class="menu active">
          <span class="menu-name">{{ 'Laufende Aufträge' | translate }}</span>
        </a>
      </li>
      <li>
        <a class="menu">
          <span class="menu-name">{{ 'Abgeschlossene Aufträge' | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
  <app-glc-glsearch [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  <app-glc-table class="myTable table-1" [(data)]="tableData" (page)="pageChange($event)" (sort)="sortChange($event)"
    [showMultipleSelect]="multipleSelect">
  </app-glc-table>
</app-glc-page-content>

<ng-template #content let-modal>
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">ID 01 - SteamCrisp funktioniert nicht</span>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
     <!-- Nav tabs -->
  <ul class="nav nav-tabs" role="tablist">
      <li role="presentation" class="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab">Auftrag Infos</a></li>
      <li role="presentation"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab">Reparaturkosten</a></li>
      <li role="presentation"><a href="#messages" aria-controls="messages" role="tab" data-toggle="tab">Rechnungen</a></li>
    </ul>
  
    <!-- Tab panes -->
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane active" id="home"><img src="assets/images/tab1.jpg"></div>
      <div role="tabpanel" class="tab-pane" id="profile"><img src="assets/images/tab2.jpg"></div>
      <div role="tabpanel" class="tab-pane" id="messages"><img src="assets/images/tab2.jpg"></div>
    </div>
  </div>

</ng-template>