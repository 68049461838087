import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from '../constants';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get all documents
   * @param options: page, size, key
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl.DOCUMENT, params, showLoader);
  }

  /**
   * Get all document categories
   * @param {boolean} showLoader
   * @return DEVICE list
   */
  public getListCategories(showLoader: boolean = true) {
    return this.apiService.restGET(this.apiUrl.DOCUMENT, null, showLoader);
  }

  /**
   * Get document info by ID
   * @param id: DOCUMENT ID
   * @return Observable
   */
  public getByID(id) {
    return this.apiService.restGET(`${this.apiUrl.DOCUMENT}/${id}`);
  }

  /**
   * Get document info by device ID
   * @param options: page, size, key
   * @param id: DEVICE ID
   * @return Observable
   */
  public getByDeviceID(options: any, id) {
    const params = options || null;
    return this.apiService.restGET(`${this.apiUrl.DOCUMENT}/${id}/${this.apiUrl.DEVICE}`, params);
  }

  /**
   * Create document
   * @param params: DOCUMENT
   * @return Observable
   */
  public create(params) {
    return this.apiService.restPOST(this.apiUrl.DOCUMENT, params);
  }

  /**
   * Update document
   * @param id: DOCUMENT ID
   * @param params: FORMDATA
   * @return Observable
   */
  public update(id, params: any) {
    return this.apiService.restPATCH(`${this.apiUrl.DOCUMENT}/${id}`, params);
  }

  /**
   * Delete
   * @param  params
   * @return  Observable
   */
  public delete(params) {
    return this.apiService.restDELETE(this.apiUrl.DOCUMENT_DELETE, params);
  }

  /**
   * Bulk Upload
   * @param params: DOCUMENT
   * @return Observable
   */
  public bulkUpload(params) {
    return this.apiService.restPOST(`${this.apiUrl.DOCUMENT}/bulk-upload`, params);
  }
}
