import * as Validator from 'validatorjs';
import * as moment from 'moment';
import { Configuration } from '../constants';

export class Service {
  /**
   * ID - auto increment
   */
  public id: number;
  /**
   *  Carried out
   */
  public carried_out: string;
  /**
   * Comment
   */
  public comment: string;
  /**
   * Description
   */
  public description_finding: string;
  /**
   * Device ID
   */
  public device_id: number;
  /**
   * End date
   */
  public end_date: string;
  /**
   * Finding
   */
  public finding: string;
  /**
   * Follow up
   */
  public follow_up: number;
  /**
   * Inform days
   */
  public inform_days: number;
  /**
   * Procedure
   */
  public procedure: string;
  /**
   * Service time
   */
  public service_date: string;
  /**
   * Event time
   */
  public event_time: string;
  /**
   * Document category
   */
  public document_category: any;
  /**
   * Checked by
   */
  public checked_by: string;
  /**
   * service team
   */
  public service_team: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.carried_out = data.carried_out || '';
    this.comment = data.comment || null;
    this.description_finding = data.description_finding || '';
    this.device_id = data.device_id || null;
    this.end_date = data.end_date ? moment.utc(data.end_date).format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE) : moment().format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE);
    this.finding = data.finding || null;
    this.follow_up = data.follow_up || 0;
    this.inform_days = data.inform_days || null;
    this.procedure = data.procedure || '';
    this.service_date = data.service_date ? moment.utc(data.service_date).format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE) : moment().format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE);
    this.event_time = data.event_time ? moment.utc(data.event_time).format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE) : moment().format(Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE);
    this.document_category = null;
    this.checked_by = data.checked_by || '';
    this.service_team = data.service_team || '';
  }

  /**
   * Validate model when create or update
   */
  validate(field = null, editing = false): any {
    const rules = {
      carried_out: `required`,
      finding: 'required',
      description_finding: 'required',
      procedure: 'required',
      service_date: 'required',
      inform_days: 'integer',
    };

    const validation = new Validator(this, rules, {
      'required.carried_out': 'SERVICE_CARRIED_IS_REQUIRED',
      'required.finding': 'FINDINGS_IS_REQUIRED',
      'required.description_finding': 'DESCRIPTION_FINDING_IS_REQUIRED',
      'required.procedure': 'PROCEDURE_IS_REQUIRED',
      'required.service_date': 'DATE_OF_SERVICE_IS_REQUIRED',
      'integer.inform_days': 'INFORM_DAYS_BEFORE_MUST_BE_NUMBER',
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
