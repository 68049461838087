export const VALIDATOR = {
  PATTERN_EMAIL: /^(?!\.)((?!.*\.{2})[a-zA-Z0-9\u0080-\u0408\._-]+)@(?!\.)([a-zA-Z0-9\u0080-\u0408\_-]+)((\.([a-zA-Z0-9\u0080-\u0408]){2,63})+)$/i,
  PATTERN_USERNAME: /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/,
  PATTERN_PASSWORD: /(?=.*[a-z].*)(?=.*[A-Z].*)((?=.*\d.*)|(?=.*[+*ç%&\/()=?~!-_@#¢].*))[a-zA-Z0-9\S]{8,20}/,
  PATTERN_PERSON_NAME: /[0-9@~!#$%\^&*+=_\[\]\;,./(){}|\":<>\?]/i,
  PATTERN_CHECK_CAMEL_CHARACTER: /([A-Z])/g,
  PATTERN_CAMEL_REPLACE_VALUE: '_$1',
  PASSWORD: {
    MIN: 3
  },
  NAME: {
    MIN: 2
  },
  COMMENT: {
    MIN: 10
  }
};
