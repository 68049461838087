// Angular Core
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import {
    MatDialogModule
} from '@angular/material';
// Component / modules

// Services
import { Common } from './common.service';
import { throwIfAlreadyLoaded } from './module-import-guard.service';
import { Validator } from './validator.service';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';

@NgModule({
    exports: [
        MatDialogModule
    ]
})
export class MaterialModule {}

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MaterialModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule
    ],
    declarations: [],
    providers: [
        ApiService,
        Common,
        Validator,
        ToasterService,
        AuthenticationService
    ]
} )
export class ServicesModule {
    constructor( @Optional() @SkipSelf() parentModule: ServicesModule ) {
        throwIfAlreadyLoaded( parentModule, 'CoreModule' );
    }
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServicesModule,
            providers: []
        };
    }
}

