<app-glc-page-details
  [pageTitle]="'SERVICE_PROTOCOL'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_DEVICES'"
  (backPress)="backToDevicePage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          [title]="'BUILDING'"
          [name]="'buidling'"
          [value]="building"
          [isDisabled]="true"
        >
        </app-glc-input>
        <app-glc-datepicker
          [isRequired]="true"
          [title]="'DATE_OF_SERVICE'"
          [placeholder]="'PLEASE_SELECT'"
          [name]="'service_date'"
          [(value)]="service.service_date"
          [(error)]="errors.service_date"
          (modelChangeEvent)="validate('service_date')"
          [isDisabled]="isDisabled"
          [disabledDates]="disabledDates"
        >
        </app-glc-datepicker>
        <app-glc-input
          [title]="'INFORM_DAYS_BEFORE'"
          [placeholder]="'INFORM_DAYS_BEFORE_PLACEHOLDER'"
          [name]="'inform_days'"
          [(value)]="service.inform_days"
          [(error)]="errors.inform_days"
          (modelChangeEvent)="validate('inform_days')"
          [isDisabled]="isDisabled"
          *ngIf="isAdminOrSuperUser"
        >
        </app-glc-input>
        <app-glc-input
          isRequired
          [title]="'SERVICE_CARRIED'"
          [placeholder]="'SERVICE_CARRIED_PLACEHOLDER'"
          [name]="'carried_out'"
          [(value)]="service.carried_out"
          [(error)]="errors.carried_out"
          (modelChangeEvent)="validate('carried_out')"
          [isDisabled]="isDisabled"
        >
        </app-glc-input>
        <app-glc-input
          isRequired
          [title]="'FINDINGS'"
          [placeholder]="'FINDINGS_PLACEHOLDER'"
          [name]="'finding'"
          [(value)]="service.finding"
          [(error)]="errors.finding"
          (modelChangeEvent)="validate('finding')"
          [isDisabled]="isDisabled"
        >
        </app-glc-input>
        <app-glc-input
          isRequired
          [title]="'DESCRIPTION_OF_FINDING'"
          [placeholder]="'DESCRIPTION_OF_FINDING_PLACEHOLDER'"
          [name]="'description_finding'"
          [(value)]="service.description_finding"
          [(error)]="errors.description_finding"
          (modelChangeEvent)="validate('description_finding')"
          [isDisabled]="isDisabled"
        >
        </app-glc-input>
        <app-glc-input
          [title]="'SERVICE_TEAM'"
          [name]="'name'"
          [placeholder]="'SERVICE_TEAM_PLACEHOLDER'"
          [(value)]="service.service_team"
          [isDisabled]="isDisabled"
        >
        </app-glc-input>
        <h4 *ngIf="!isDisabled">{{ 'DOCUMENT_UPLOAD' | translate}}</h4>
        <div class="row" *ngIf="!isDisabled">
          <div class="col-lg-7">
            <div class="hide-label">
              <app-glc-file
                (fileSelect)="handleFileInput($event)"
                [isMultiple]="true"
                [maxSize]="10"
                [wrongFormatMess]="'DEVICE_DOCUMENT_INVALID'"
                [oversizeMess]="'DEVICE_MAXIMUM_SIZE_ALLOW'"
                [fileTypes]="fileTypes"
                [(files)]="documents"
              >
              </app-glc-file>
            </div>
          </div>
          <div class="col-lg-5">
            <app-glc-select-searchable
              [title]="'SELECT_DOCUMENT_CATEGORY'"
              [textKey]="'name'"
              [placeholder]="'PLEASE_SELECT'"
              [dataService]="privateDocumentCategoryService"
              [(value)]="service.document_category"
              [(error)]="errors.document_category"
              (modelChangeEvent)="validate('document_category')"
              [isServerSide]="false"
              [addTag]="true">
            </app-glc-select-searchable>
          </div>
        </div>
        <app-glc-input isRequired
          [title]="'PROCEDURE'"
          [placeholder]="'PROCEDURE_PLACEHOLDER'"
          [name]="'procedure'"
          [(value)]="service.procedure"
          [(error)]="errors.procedure"
          (modelChangeEvent)="validate('procedure')"
          [isDisabled]="isDisabled"
        >
        </app-glc-input>
        <app-glc-radio-button
          [title]="'FOLLOW_UP'"
          [name]="'follow_up'"
          [options]="follow_up_options"
          [(value)]="service.follow_up"
          [isDisabled]="isDisabled"
        >
        </app-glc-radio-button>
        <app-glc-datepicker
          [title]="'DATE_END_OF_SERVICE'"
          [placeholder]="'PLEASE_SELECT'"
          [name]="'end_date'"
          [(value)]="service.end_date"
          [isDisabled]="isDisabled"
        >
        </app-glc-datepicker>
        <app-glc-input
          [title]="'CHECKED_BY'"
          [name]="'checked_by'"
          [placeholder]="'CHECKED_BY_PLACEHOLDER'"
          [(value)]="service.checked_by"
          [isDisabled]="isDisabled"
        >
        </app-glc-input>
        <button 
          mat-button
          class="btn btn-primary pull-right"
          (click)="save()"
          [disabled]="isDisabledBtn()"
        >
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-glc-page-details>
