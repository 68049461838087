import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-glc-glsearch',
  templateUrl: './glsearch.component.html',
  styleUrls: ['./glsearch.component.scss']
})
export class GLSearchComponent implements OnInit {
  private searchText = '';

  @Input() buttonText = '';
  // inputSearch (2-ways binding)
  @Output() inputSearchChange = new EventEmitter();
  @Input()
  get inputSearch() {
    return this.searchText;
  }
  set inputSearch(val) {
    this.searchText = val;
    this.inputSearchChange.emit(this.searchText);
  }

  @Output() search = new EventEmitter();

  searchTimer = null;

  constructor() { }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    const instance = this;
    this.searchTimer = setTimeout(() => {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      if (!filterValue || filterValue.length >= 3) {
        instance.search.emit(filterValue);
      }
    }, 1000);
  }

  searchEvent() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.search.emit(this.inputSearch);
  }
}
