import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Configuration } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  apiUrl = Configuration.API_CONFIG.API_URL;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * update
   * @param {any} params
   * @return  Observable
   */
  public update(params: any) {
    return this.apiService.restPOST(this.apiUrl.USERS_CHANGE_SETTING, params);
  }
}
