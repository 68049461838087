import { Component, OnInit } from '@angular/core';
import { Common, DeviceServiceService } from 'src/app/services';
import { Configuration } from 'src/app/constants';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { Service } from 'src/app/models';

@Component({
  selector: 'app-glc-service-tab',
  templateUrl: './service-tab.component.html',
  styleUrls: ['./service-tab.component.scss']
})
export class ServiceTabComponent implements OnInit {
  routes = Configuration.ROUTE_PATH;
  isAdmin = false;
  deviceID: number;
  nextService = new Service();

  // Declare table settings
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  constructor(
    private common: Common,
    private activatedRoute: ActivatedRoute,
    private deviceServiceService: DeviceServiceService
  ) { }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    try {
      this.deviceID = Number(this.activatedRoute.parent.snapshot.paramMap.get('id'));
    } catch (e) {
      this.backToDevicesPage();
    }
    this.initTable();
    this.initData();
  }

  initData() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue,
    });
    // tslint:disable-next-line:no-string-literal
    options['status'] = 1;
    this.common.showLoader();
    try {
      forkJoin([
        this.deviceServiceService.getByDeviceID(options, this.deviceID),
        this.deviceServiceService.getNext(this.deviceID)
      ]).subscribe((responses: any) => {
        this.common.hideLoader();
        const results = responses[0].results.map(item => {
          const service = item;
          service.date = moment.utc(service.service_date).format(Configuration.DATE_FORMAT);
          service.time = moment.utc(service.service_date).format(Configuration.TIME_FORMAT_WITHOUT_SECOND);
          service.action = [
            {
              text: 'VIEW',
              icon: 'eye',
              url: `${item.id}`
            }
          ];
          return service;
        });

        this.tableData = {
          ...this.tableData,
          pageIndex: this.pageOffset,
          totalRecords: responses[0].count,
          dataSource: results
        };

        this.nextService = new Service(responses[1]);
        if (responses[1].event_time) {
          this.nextService.event_time = moment(this.nextService.event_time, Configuration.DATE_TIME_FORMAT_FOR_CALENDAR_VALUE).format(Configuration.DATE_FORMAT);
        } else {
          this.nextService.event_time = '';
        }
        this.nextService.inform_days = this.nextService.inform_days || 0;
      });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  initTable() {
    this.tableData = {
      displayedColumns: ['date', 'time', 'checked_by', 'action'],
      field: [
        {
          name: 'date',
          title: 'Date'
        },
        {
          name: 'time',
          title: 'TIME'
        },
        {
          name: 'checked_by',
          title: 'DONE_BY'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.initData();
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'date':
        this.sortValue.active = 'service_date';
        break;
      case 'time':
        this.sortValue.active = 'service_date';
        break;
    }
    this.initData();
  }

  doService() {
    this.common.navigate(`${this.routes.DEVICES}/${this.deviceID}/${this.routes.DEVICE_SERVICE_TAB}/create`);
  }

  viewCalendar() {
    this.common.navigate(`${this.routes.DEVICES}/${this.deviceID}/service/${this.routes.EVENT_CALENDAR}`);
  }

  backToDevicesPage() {
    this.common.navigate(this.routes.DEVICES);
  }
}
