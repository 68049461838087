import { Injectable } from '@angular/core';
import { Configuration } from '../constants';
import { ApiService } from './api.service';
import { FunctionModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  apiUrl = Configuration.API_CONFIG.API_URL.FUNCTIONS;

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * getList
   * @param  options
   * @param {boolean} showLoader
   * @return  Observable
   */
  public getList(options: any, showLoader: boolean = true) {
    const params = options || null;
    return this.apiService.restGET(this.apiUrl, params, showLoader);
  }

  /**
   * create
   * @param {FunctionModel} model
   * @return  Observable
   */
  public create(model: FunctionModel) {
    const params = {
      name: model.name
    };
    return this.apiService.restPOST(this.apiUrl, params);
  }

  /**
   * update
   * @param {FunctionModel} model
   * @return  Observable
   */
  public update(model: FunctionModel) {
    const params = {
      name: model.name
    };
    return this.apiService.restPATCH(`${this.apiUrl}/${model.id}`, params);
  }

  /**
   * details
   * @param  id
   * @return  Observable
   */
  public details(id: number) {
    return this.apiService.restGET(`${this.apiUrl}/${id}`);
  }

  /**
   * delete
   * @param  id
   * @return  Observable
   */
  public delete(id: number) {
    return this.apiService.restDELETE(`${this.apiUrl}/${id}`);
  }
}
