import { Component, OnInit } from '@angular/core';
import { Common, DocumentService, Validator, DocumentCategoryService } from 'src/app/services';
import { CONFIRM_MODAL_OPTIONS, TOASTER_TYPE, Configuration, FILE_TYPE } from 'src/app/constants';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { FieldError, Document } from 'src/app/models';

@Component({
  selector: 'app-glc-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss']
})
export class DocumentsTabComponent implements OnInit {
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  isAdminOrSuperUser = false;
  id: number;
  document = new Document();
  documentBK = new Document();
  isChanged: boolean;

  // Declare table settings
  tableData: any = null;
  isShowMultipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  // Declare validation
  errors: any = {
    category: new FieldError(),
  };

  // Declare document variables
  documents = [];
  fileTypes = FILE_TYPE.DEFAULT;
  validDocument = true;

  // Declare services
  privateDocumentCategoryService: any;

  constructor(
    private common: Common,
    private validator: Validator,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private documentCategoryService: DocumentCategoryService
  ) { }

  ngOnInit() {
    this.privateDocumentCategoryService = this.documentCategoryService;
    this.isAdminOrSuperUser = this.common.isAdminOrSuperUser();
    try {
      this.id = Number(this.activatedRoute.parent.snapshot.paramMap.get('id'));
    } catch (e) {
      this.backToDevicesPage();
    }
    this.initTable();
    this.getDocumentList();
  }

  getDocumentList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });
    try {
      this.documentService.getByDeviceID(options, this.id)
        .subscribe(
          (response: any) => {
            try {
              const results = response.results.map(item => {
                const document = _.cloneDeep(item);
                document.action = [];
                if (this.isAdminOrSuperUser) {
                  document.action.push(
                    {
                      text: 'EDIT',
                      icon: 'pen',
                      url: `${document.id}`,
                    },
                  );
                }
                document.action.push(
                  {
                    text: 'VIEW',
                    icon: 'eye',
                    url: item.filename,
                    isFile: true
                  }
                );

                document.selectedId = item.id;
                document.selected = false;
                return document;
              });

              this.tableData = {
                ...this.tableData,
                pageIndex: this.pageOffset,
                totalRecords: response.count,
                dataSource: results
              };

              this.common.hideLoader();
            } catch (error) {
              this.common.hideLoader();
              this.common.showError([error.message]);
            }
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  initTable() {
    this.tableData = {
      displayedColumns: ['selectedId', 'file_display_name', 'category', 'action'],
      field: [
        {
          name: 'selectedId',
          title: 'ID'
        },
        {
          name: 'file_display_name',
          title: 'FILE_NAME'
        },
        {
          name: 'category',
          title: 'CATEGORY'
        },
        {
          name: 'action',
          title: 'ACTIONS',
          link: true
        }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.getDocumentList();
  }

  sortChange(event) {
    this.sortValue = event;
    this.getDocumentList();
  }

  deleteDocuments(listIds) {
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_DOCUMENT'),
      () => {
        try {
          this.documentService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.isShowMultipleSelect = false;
                this.getDocumentList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }

  save() {
    if (!this.isAdminOrSuperUser || this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_UPLOAD_QUESTION'),
      () => {
        this.create();
      }
    );
  }

  create() {
    const formData = new FormData();
    if (this.documents.length > 0) {
      formData.append('belong_to_obj', `${this.id}`);
      formData.append('document_type', '0');
      _.forEach(this.documents, (document) => {
        formData.append('filename', document);
      });
      // tslint:disable-next-line:no-string-literal
      const category = this.document.category['name'] ? this.document.category['name'] : this.document.category;
      formData.append('category', category);
    }

    if (this.document.validate() === '') {
      try {
        this.documentService.bulkUpload(formData)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('UPLOAD'),
                this.common.translate('UPLOAD_SUCCESSFUL')
              );
              this.documents = [];
              this.getDocumentList();
            });
      } catch (error) {
        this.common.hideLoader();
        this.common.showError([JSON.stringify(error)]);
      }
    }
  }

  validate(field: string = null) {
    this.validator.validate(field, this.document, this.errors);
  }

  validateDocument() {
    if (this.documents.length > 0 && !this.document.category) {
      return false;
    }
    return this.validDocument;
  }

  public isDisabledBtn() {
    if (!_.isEqual(this.document, this.documentBK) || !_.isEqual(this.documents, [])) {
      this.isChanged = true;
    }
    const result = this.document.validate();
    return !this.validateDocument() || result !== '';
  }

  handleFileInput(error: boolean) {
    this.validDocument = !error;
  }

  backToDevicesPage() {
    this.common.navigate(this.deviceRoute);
  }
}
