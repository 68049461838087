import { Component, OnInit } from '@angular/core';
import { Device, FieldError, Building } from 'src/app/models';
import { Common, Validator, DeviceService, BuildingService, FunctionService, InternalTeamService, ExternalTeamService } from 'src/app/services';
import { TOASTER_TYPE, Configuration } from 'src/app/constants';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-glc-info-tab',
  templateUrl: './info-tab.component.html',
  styleUrls: ['./info-tab.component.scss']
})
export class InfoTabComponent implements OnInit {
  device: Device = new Device();
  deviceBK: Device = new Device();
  id: any;
  listFunctions = [];

  errors: any = {
    name: new FieldError(),
    device_hard_id: new FieldError(),
    building_id: new FieldError(),
    function_id: new FieldError(),
    external_team_id: new FieldError(),
  };

  // Declare services
  privateBuildingService: any;
  privateFunctionService: any;
  privateInternalTeamService: any;
  privateExternalTeamService: any;

  isChanged = false;
  isAdminOrSuperUser = false;

  constructor(
    private common: Common,
    private validator: Validator,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    private buildingService: BuildingService,
    private functionService: FunctionService,
    private internalTeamService: InternalTeamService,
    private externalTeamService: ExternalTeamService,
  ) { }

  ngOnInit() {
    this.isAdminOrSuperUser = this.common.isAdminOrSuperUser();
    // Prepare service for select searchable
    this.privateBuildingService = this.buildingService;
    this.privateFunctionService = this.functionService;
    this.privateInternalTeamService = this.internalTeamService;
    this.privateExternalTeamService = this.externalTeamService;
    try {
      this.id = Number(this.activatedRoute.parent.snapshot.paramMap.get('id'));
    } catch (e) {
      this.backToDevicesPage();
    }
    this.initData();
  }

  initData() {
    try {
      this.deviceService.getByID(this.id).subscribe((response: any) => {
        this.common.hideLoader();
        this.device = new Device(response);

        // For binding value to select searchable, value must be an object and must remove valueKey in .html
        this.device.building_id = response.building ? response.building : response.building_id;
        this.device.function_id = `${response.function_id}`;
        this.device.internal_team_id = response.internal_team ? response.internal_team : response.internal_team_id;
        this.device.external_team_id = response.external_team ? response.external_team : response.external_team_id;
        this.deviceBK = _.cloneDeep(this.device);
        this.getListFunctions(false);
      });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  save() {
    if (!this.isAdminOrSuperUser || this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        this.update();
      }
    );
  }

  update() {
    try {
      if (this.device.validate(null, true) === '') {
        this.deviceService.update(this.device)
          .subscribe(
            (response: any) => {
              this.isChanged = false;
              this.common.hideLoader();
              this.common.showToaster(
                TOASTER_TYPE.success,
                this.common.translate('UPDATE'),
                this.common.translate('UPDATE_SUCCESSFUL')
              );
            });
      }
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([JSON.stringify(error)]);
    }
  }

  validate(field: string = null) {
    this.validator.validate(field, this.device, this.errors);
    if (field === 'building_id') {
      this.getListFunctions(true);
    }
  }

  getListFunctions(showLoader: boolean = true) {
    if (this.device.building_id) {
      try {
        this.buildingService.getByID(this.device.building_id.id, showLoader).subscribe((response: any) => {
          this.common.hideLoader();
          const building = new Building(response);
          this.listFunctions = building.function;
        });
      } catch (error) {
        this.common.hideLoader();
        this.common.showError([JSON.stringify(error)]);
      }
    } else {
      this.listFunctions = [];
      this.device.function_id = null;
    }
  }

  public isDisabledBtn() {
    if (!_.isEqual(this.deviceBK, this.device)) {
      this.isChanged = true;
    }
    const result = this.device.validate(null, true);
    return !this.isChanged || result !== '';
  }

  backToDevicesPage() {
    this.common.navigate(Configuration.ROUTE_PATH.DEVICES);
  }
}
