<app-glc-page-details
  [pageTitle]="'DOCUMENT_DETAILS'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_DEVICE'"
  (backPress)="backToDevicePage()"
>
    <div class="row">
        <div class="col-md-4">
        <div class="box-body">
            <app-glc-input
            isRequired
            [title]="'FILE_NAME'"
            [placeholder]="'FILE_NAME_PLACEHOLDER'"
            [name]="'file_display_name'"
            [(value)]="document.file_display_name"
            [(error)]="errors.file_display_name"
            (modelChangeEvent)="validate('file_display_name', true)"
            ></app-glc-input>
            <app-glc-input
            isRequired
            [title]="'SELECT_DOCUMENT_CATEGORY'"
            [placeholder]="'PLEASE_SELECT'"
            [name]="'category'"
            [(value)]="document.category"
            [(error)]="errors.category"
            (modelChangeEvent)="validate('category')"
            ></app-glc-input>
            <button
            mat-button
            class="btn btn-primary pull-right"
            (click)="save()"
            [disabled]="isDisabledBtn()"
            >
                {{ 'SAVE' | translate }}
            </button>
        </div>
        </div>
    </div>
</app-glc-page-details>