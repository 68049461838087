import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Common, TaskService, Validator } from '../../../services';
import { Configuration, TOASTER_TYPE } from '../../../constants';
import { Task, FieldError } from '../../../models';

@Component({
  selector: 'app-glc-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {
  taskRoute = Configuration.ROUTE_PATH.TO_DO_LIST;
  pageDescription = 'CREATE_TASK';
  isDisabled = true;

  public isChanged = false;
  public task: Task = new Task();
  public taskBK: Task = new Task();

  errors: any = {
    comment: new FieldError()
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: Common,
    private validator: Validator,
    private taskService: TaskService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const state = window.history.state;
      const taskId = parseInt(params.id, 10);
      if (taskId) {
        if (state.id !== taskId) {
          this.backToToDoListPage();
        }
        this.pageDescription = 'TASK_DETAILS';
        this.task = new Task(state);
        this.taskBK = this.common.cloneDeep(this.task);
      } else {
        this.pageDescription = 'CREATE_TASK';
        this.isDisabled = false;
      }
    });
  }

  backToToDoListPage() {
    this.common.navigate(`/${this.taskRoute}`);
  }

  save() {
    if (this.isDisabledBtn() || !this.isChanged) {
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_SAVE_QUESTION'),
      () => {
        if (this.task.id) {
          this.update();
        } else {
          this.create();
        }
      },
      () => { }
    );

  }

  public completeTask() {
    if (!this.task.id || !this.isDisabled) {
      return;
    }

    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('COMPLETE_TASK_QUESTION'),
      this.complete.bind(this),
      () => { }
    );
  }

  validate(field: string = null) {
    this.validator.validate(field, this.task, this.errors);
  }

  public isDisabledBtn() {
    this.isChanged = this.common.isDifferentObject(this.taskBK, this.task);
    if (!this.isChanged) {
      return true;
    }

    const result = this.task.validate();
    return result !== '';
  }

  public enableEdit() {
    if (!this.task.id) {
      return;
    }
    this.isDisabled = false;
  }

  public cancelEdit() {
    if (!this.task.id) {
      return;
    }

    if (!this.isChanged) {
      this.revertChange();
      return;
    }

    this.common.showConfirm(
      this.common.translate('WARNING'),
      this.common.translate('DISCARD_CHANGES_QUESTION'),
      this.revertChange.bind(this),
      () => { }
    );
  }

  private revertChange() {
    this.isChanged = false;
    this.task = this.common.cloneDeep(this.taskBK);
    this.isDisabled = true;
  }

  private create() {
    try {
      this.taskService.create(this.task)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('CREATE'),
              this.common.translate('CREATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToToDoListPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  private update() {
    try {
      this.taskService.update(this.task)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToToDoListPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }

  private complete() {
    try {
      this.taskService.complete(this.task)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.common.showToaster(
              TOASTER_TYPE.success,
              this.common.translate('UPDATE'),
              this.common.translate('UPDATE_SUCCESSFUL')
            );
            this.isChanged = false;
            this.backToToDoListPage();
          });
    } catch (error) {
      this.common.hideLoader();
      this.common.showError([error.message]);
    }
  }
}
