<app-glc-page-details
  [pageTitle]="'DEVICE_DETAILS'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_DEVICES'"
  (backPress)="backToDevicesPage()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="box-body">
        <app-glc-input
          isRequired
          [title]="'NAME'"
          [placeholder]="'DEVICE_NAME_PLACEHOLDER'"
          [name]="'name'"
          [(value)]="device.name"
          [(error)]="errors.name"
          (modelChangeEvent)="validate('name')"
          >
        </app-glc-input>
        <app-glc-input
          isRequired
          [title]="'DEVICE_ID'"
          [placeholder]="'DEVICE_ID_PLACEHOLDER'"
          [name]="'device_hard_id'"
          [(value)]="device.device_hard_id"
          [(error)]="errors.device_hard_id"
          (modelChangeEvent)="validate('device_hard_id')"
          >
        </app-glc-input>
        <app-glc-input
          [title]="'INSTAGUARD_ID'"
          [placeholder]="'INSTAGUARD_ID_PLACEHOLDER'"
          [name]="'instaguard_id'"
          [(value)]="device.instaguard_id"
        >
        </app-glc-input>
        <app-glc-select-searchable
          [isRequired]="true"
          [title]="'BUILDING'"
          [valueKey]="'id'"
          [textKey]="'name'"
          [placeholder]="'PLEASE_SELECT'"
          [dataService]="privateBuildingService"
          [(value)]="device.building_id"
          [(error)]="errors.building_id"
          (modelChangeEvent)="validate('building_id')"
        >
        </app-glc-select-searchable>
        <app-glc-input
          [title]="'LOCATION'"
          [placeholder]="'LOCATION_PLACEHOLDER'"
          [name]="'location'"
          [(value)]="device.location"
        >
        </app-glc-input>
        <app-glc-select
          isRequired
          [title]="'FUNCTION'"
          [placeholder]="'PLEASE_SELECT'"
          [(selectedItem)]="device.function_id"
          [valueKey]="'id'"
          [textKey]="'name'"
          [(dataSource)]="listFunctions"
          [(error)]="errors.function_id"
          (modelChangeEvent)="validate('function_id')"
        >
        </app-glc-select>
        <app-glc-select-searchable
          [title]="'INTERNAL_TEAM'"
          [valueKey]="'id'"
          [textKey]="'name'"
          [placeholder]="'PLEASE_SELECT'"
          [dataService]="privateInternalTeamService"
          [(value)]="device.internal_team_id"
        >
        </app-glc-select-searchable>
        <app-glc-select-searchable
          [isRequired]="true"
          [title]="'EXTERNAL_TEAM'"
          [valueKey]="'id'"
          [textKey]="'name'"
          [placeholder]="'PLEASE_SELECT'"
          [dataService]="privateExternalTeamService"
          [(value)]="device.external_team_id"
          [(error)]="errors.external_team_id"
          (modelChangeEvent)="validate('external_team_id')"
        >
        </app-glc-select-searchable>
        <h4>{{ 'DOCUMENT_UPLOAD' | translate}}</h4>
        <div class="row">
          <div class="col-lg-7">
            <div class="hide-label">
              <app-glc-file 
                (fileSelect)="handleFileInput($event)" 
                [isMultiple]="true" 
                [maxSize]="10"
                [wrongFormatMess]="'DEVICE_DOCUMENT_INVALID'"
                [oversizeMess]="'DEVICE_MAXIMUM_SIZE_ALLOW'"
                [fileTypes]="fileTypes"
                [(files)]="documents"
               >
              </app-glc-file>
            </div>
          </div>
          <div class="col-lg-5">
            <app-glc-select-searchable
              [title]="'SELECT_DOCUMENT_CATEGORY'"
              [textKey]="'name'"
              [placeholder]="'PLEASE_SELECT'"
              [dataService]="privateDocumentCategoryService"
              [(value)]="device.device_document_category"
              [(error)]="errors.device_document_category"
              (modelChangeEvent)="validate('device_document_category')"
              [isServerSide]="false"
              [addTag]="true"
              >
            </app-glc-select-searchable>
          </div>
        </div>
        <h4>{{ 'SERVICE_DETAILS' | translate}}</h4>
        <app-glc-datepicker
          [isRequired]="true"
          [title]="'SERVICE'"
          [placeholder]="'PLEASE_SELECT'"
          [name]="'service'"
          [(value)]="device.service_date_time"
          [(error)]="errors.service_date_time"
          (modelChangeEvent)="validate('service_date_time')"
        >
        </app-glc-datepicker>
        <app-glc-input
          [title]="'INFORM_DAYS_BEFORE'"
          [placeholder]="'INFORM_DAYS_BEFORE_PLACEHOLDER'"
          [name]="'service_inform_days'"
          [(value)]="device.service_inform_days"
          [(error)]="errors.service_inform_days"
          (modelChangeEvent)="validate('service_inform_days')"
        >
        </app-glc-input>
        <h4>{{ 'CONTROL_DETAILS' | translate}}</h4>
        <app-glc-datepicker
          [isRequired]="true"
          [title]="'CONTROL'"
          [placeholder]="'PLEASE_SELECT'"
          [name]="'control'"
          [(value)]="device.control_date_time"
          [(error)]="errors.control_date_time"
          (modelChangeEvent)="validate('control_date_time')"
        >
        </app-glc-datepicker>
        <app-glc-input
          [title]="'INFORM_DAYS_BEFORE'"
          [placeholder]="'INFORM_DAYS_BEFORE_PLACEHOLDER'"
          [name]="'control_inform_days'"
          [(value)]="device.control_inform_days"
          [(error)]="errors.control_inform_days"
          (modelChangeEvent)="validate('control_inform_days')"
        >
        </app-glc-input>
        <button
          mat-button
          class="btn btn-primary pull-right"
          (click)="save()"
          [disabled]="isDisabledBtn()"
        >
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-glc-page-details>
      