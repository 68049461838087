import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-glc-table',
  templateUrl: './gltable.component.html',
  styleUrls: ['./gltable.component.scss']
})
export class GLTableComponent implements OnInit, AfterViewInit, OnChanges {
  dataSource: MatTableDataSource<any>;
  pageEvent: any;
  totalSelectedItem = { value: 0 };
  private inputData: any;
  private isShowMultipleSelect = false;
  private isHasDelete = false;
  private isShowHeader = true;

  @Input() cssClass: string;
  @Output() page = new EventEmitter();
  @Output() sort = new EventEmitter();

  // showHeader (2-ways binding)
  @Output() showHeaderChange = new EventEmitter();
  @Input()
  get showHeader() {
    return this.isShowHeader;
  }
  set showHeader(val) {
    this.isShowHeader = val;
    this.showHeaderChange.emit(this.isShowHeader);
  }

  // data (2-ways binding)
  @Output() dataChange = new EventEmitter();
  @Input()
  get data() {
    return this.inputData;
  }
  set data(val) {
    this.inputData = val;
    this.dataChange.emit(this.inputData);
  }

  // showMultipleSelect (2-ways binding)
  @Output() showMultipleSelectChange = new EventEmitter();
  @Input()
  get showMultipleSelect() {
    return this.isShowMultipleSelect;
  }
  set showMultipleSelect(val) {
    this.isShowMultipleSelect = val;
    this.showMultipleSelectChange.emit(this.isShowMultipleSelect);
  }

  // hasDelete (2-ways binding)
  @Output() hasDeleteChange = new EventEmitter();
  @Input()
  get hasDelete() {
    return this.isHasDelete;
  }
  set hasDelete(val) {
    this.isHasDelete = val;
    this.hasDeleteChange.emit(this.isHasDelete);
  }

  // handleDeleteItem (2-ways binding)
  @Output() handleDeleteItem = new EventEmitter();

  multipleSelect = false;

  constructor() {
    this.dataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.data.dataSource);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (changes && changes.data) {
      const { firstChange } = changes.data;
      if (!firstChange) {
        this.totalSelectedItem = {
          value: 0
        };
        this.dataSource = new MatTableDataSource(changes.data.currentValue.dataSource);
      }
    }
  }

  ngOnInit() {
    // this._service.success('nat','dndnnd',this.options);
  }

  pageChangeEmit(event) {
    this.page.emit(event);
  }

  sortData(event) {
    this.sort.emit(event);
  }

  toggleAll() {
    if (this.multipleSelect) {
      _.forEach(this.data.dataSource, item => {
        if (!item.disabled) {
          item.selected = true;
          if (item.disabled) {
            item.selected = false;
          }
        }
      });
      const selectedItems = _.filter(this.data.dataSource, item => item.selected);
      this.totalSelectedItem = {
        value: selectedItems.length
      };
    } else {
      _.forEach(this.data.dataSource, item => {
        item.selected = false;
      });
      this.totalSelectedItem = {
        value: 0
      };
    }
  }

  checkStateAllSelect() {
    const selectedItems = _.filter(this.data.dataSource, item => item.selected);
    this.totalSelectedItem = {
      value: selectedItems.length
    };
    if (selectedItems.length === this.data.dataSource.length) {
      this.multipleSelect = true;
    } else {
      this.multipleSelect = false;
    }
  }

  cancelDelete() {
    this.showMultipleSelect = false;
    this.multipleSelect = false;
    _.forEach(this.data.dataSource, item => {
      item.selected = false;
    });
    this.totalSelectedItem = {
      value: 0
    };
  }

  deleteMultipleItems() {
    const selectedItems = _.filter(this.data.dataSource, item => item.selected);
    const listId = _.map(selectedItems, 'id');
    if (listId.length > 0) {
      this.handleDeleteItem.emit(listId);
    }
  }

}
