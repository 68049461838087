import * as Validator from 'validatorjs';
import { VALIDATOR, TASK_CATEGORY, TO_DO_TYPE } from '../constants';

export class Task {
  /**
   * ID - auto increment
   */
  public id: number;
  /**
   * Task name
   */
  public name: string;
  /**
   * Task comment
   */
  public comment: string;
  /**
   * Task category
   */
  public category: number;
  /**
   * Task displayCategory
   */
  public displayCategory: string;
  /**
   * Task updated_at
   */
  public updated_at: string;
  /**
   * Task status
   */
  public status: number;
  /**
   * Task device_id
   */
  public device_id: string;
  /**
   * Task control_service_id
   */
  public control_service_id: string;

  public constructor(data: any = {}) {
    data = data === null ? {} : data;
    this.id = data.id || null;
    this.name = data.name || '';
    this.comment = data.comment || '';
    this.category = data.category || TASK_CATEGORY.GENERAL;
    this.displayCategory =
      this.category === TASK_CATEGORY.GENERAL ? 'GENERAL_TASK'
        : this.category === TASK_CATEGORY.CONTROL ? 'CONTROL_TASK' : 'SERVICE_TASK';
    this.updated_at = data.updated_at || null;
    this.status = data.status || TO_DO_TYPE.OPEN;
    this.device_id = data.device_id || null;
    this.control_service_id = data.control_service_id || null;
  }

  /**
   * Validate model when create or update
   */
  validate(field = null, editing = false): any {
    const rules = {
      comment: `required|min:${VALIDATOR.COMMENT.MIN}`
    };

    const validation = new Validator(this, rules, {
      'required.comment': 'TASK_DESCRIPTION_IS_REQUIRED',
    });

    if (!field && !validation.passes()) {
      return validation.errors;
    } else if (field && !validation.passes()) {
      return validation.errors.first(field);
    }
    return '';
  }
}
