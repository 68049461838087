<app-glc-page-details
  [pageTitle]="'EVENT_CALENDAR'"
  [(pageDescription)]="pageDescription"
  [backBtnText]="'BACK_TO_DEVICE'"
  (backPress)="back()"
>
  <div class="row text-center">
    <div class="col-md-4">
      <div class="btn-group">
        <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
          {{ 'PREVIOUS' | translate }}
        </div>
        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
          {{ 'TODAY' | translate }}
        </div>
        <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
          {{ 'NEXT' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
          {{ 'MONTH' | translate }}
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
          {{ 'WEEK' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div [ngSwitch]="view" class="mt-20">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" (dayClicked)="dayClicked($event.day)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
  </div>

  <div class="btn-group mt-20">
    <div class="btn btn-primary" (click)="export()">
      {{ 'EXPORT' | translate }}
    </div>
  </div>

  <ng-template #modalContent let-close="close">
    <app-glc-event-detail [modalData]="modalData" (closeModal)="closeModal()" (saveEvent)="save()"
      (deleteEvent)="delete()"></app-glc-event-detail>
  </ng-template>
</app-glc-page-details>